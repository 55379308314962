import { XRUtils } from "wle-pp/cauldron/utils/xr_utils.js";
import { PluginUtils } from "../../../utils/plugin_utils.js";
import { PlayerLocomotionTeleport } from "wle-pp/gameplay/experimental/locomotion/legacy/locomotion/teleport/player_locomotion_teleport.js";
import { Globals } from "wle-pp/pp/globals.js";
import { MouseButtonID } from "wle-pp/input/cauldron/mouse.js";
import { GamepadAxesID, GamepadButtonID } from "wle-pp/input/gamepad/gamepad_buttons.js";
import { Handedness } from "wle-pp/input/cauldron/input_types.js";

export function initPlayerLocomotionTeleportMod() {
    _initPlayerLocomotionTeleportModPrototype();
}

function _initPlayerLocomotionTeleportModPrototype() {
    const _playerLocomotionTeleportMod = {
        start() {
            XRUtils.getSession(this._myTeleportParams.myEngine).addEventListener('selectstart', (e) => {
                if(e && e.inputSource && e.inputSource.hand && e.inputSource.handedness === Handedness.LEFT) {
                    this.isPressStart = true;
                }
            });

            XRUtils.getSession(this._myTeleportParams.myEngine).addEventListener('selectend', (e) => {
                if(e && e.inputSource && e.inputSource.hand && e.inputSource.handedness === Handedness.LEFT) {
                    this.isPressEnd = true;
                }
            });
        },

        _startDetecting() {
            let startDetecting = false;

            if (!XRUtils.isSessionActive(this._myTeleportParams.myEngine)) {
                startDetecting = Globals.getMouse(this._myTeleportParams.myEngine).isButtonPressStart(MouseButtonID.MIDDLE) &&
                    Globals.getMouse(this._myTeleportParams.myEngine).isTargetingRenderCanvas();
            } else {
                if (this.isPressEnd) {
                    this._myStickIdleCharge = true;
                }
        
                if (this._myStickIdleCharge && this.isPressStart) {
                    this._myStickIdleCharge = false;
                    startDetecting = true;
                }
            }

            this.isPressStart = false;
            this.isPressEnd = false;

            return startDetecting;
        }

    };
    PluginUtils.injectOwnProperties(_playerLocomotionTeleportMod, PlayerLocomotionTeleport.prototype, false, true, true);
};
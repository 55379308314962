import { vec4 } from "gl-matrix";

export const boardMaterialIndexToConfigMaterialKey = {
    0: "primaryMaterial",
    1: "secondaryMaterial",
    2: "tertiaryMaterial",
    3: "emissive"
};

export const enum Board {
    RedWood = "redwood",
    LightWood = "lightwood",
    Carbon = "carbon",
}

export const BOARD_TEXTURE_DATA = [
    {
        name: "Oak",
        diffuseTextureKey: "reddishwoodDiffuseTexture",
        normalTextureKey: "reddishwoodNormalTexture",
        metalDiffuseColor: vec4.fromValues(0, 0, 0, 1),
        jetstreamColor: vec4.fromValues(0, 0.149, 0.902, 1),
    },
    {
        name: "Spruce",
        diffuseTextureKey: "lightwoodDiffuseTexture",
        normalTextureKey: "lightwoodNormalTexture",
        metalDiffuseColor: vec4.fromValues(1, 1, 1, 1),
        jetstreamColor: vec4.fromValues(0.447, 0.901, 0, 1),
    },
    {
        name: "Carbon",
        diffuseTextureKey: "carbonDiffuseTexture",
        normalTextureKey: "carbonNormalTexture",
        metalDiffuseColor: vec4.fromValues(0, 0, 0, 1),
        jetstreamColor: vec4.fromValues(0.56, 0, 0.851, 1),
    }
] as const;

export const BOARD_COUNT = 3;
export function getRandomBoard(): string {
    return String(Math.trunc(Math.random() * BOARD_COUNT));
}
import { Component, TextComponent } from "@wonderlandengine/api";
import { currentPlayerData } from "../../data/player-data.js";

export class SetPlayerNameComponent extends Component {
    static TypeName = "set-player-name";

    init() {
        this.nameListener = this.updateText.bind(this);
    }

    onActivate() {
        currentPlayerData.listen(this.nameListener);
    }

    onDeactivate() {
        currentPlayerData.unlisten(this.nameListener);
    }

    start() {
        this.textComponents = this.object.pp_getComponents(TextComponent);
        this.updateText();
    }

    updateText() {
        for (let i = 0; i < this.textComponents.length; i++) {
            this.textComponents[i].text = currentPlayerData.name;
        }
    }
}
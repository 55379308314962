import { Alignment, ArtificialConstraint, AxisCoupling, Column, Container, FlexAlignment, IconButton, Label, Row, ScrollableViewportWidget, ScrollbarMode, Spacing, WrapMode, type ScrollableViewportWidgetProperties, type Widget, type WidgetAutoXML } from "lazy-widgets";
import common from "../../../common.js";
import { DecoratedButtonLikePane } from "../../lazy-widgets/widgets/decorated-button-like-pane.js";

interface PlayerRowData {
    widget: Widget,
    muteImage: HTMLImageElement,
}

export class PlayerList extends ScrollableViewportWidget {
    static override autoXML: WidgetAutoXML = {
        name: "player-list",
        inputConfig: []
    };

    private playerRows = new Map<string, PlayerRowData>();

    constructor(properties?: ScrollableViewportWidgetProperties) {
        const column = new Column();

        super(
            column,
            {
                minHeight: 140,
                scrollbarMode: ScrollbarMode.Overlay,
                widthCoupling: AxisCoupling.Bi,
                heightCoupling: AxisCoupling.None,
                ...properties,
            },
        );

        common.MAIN_CHANNEL.on("room-player-join", ([key, player]: [key: string, player: { name: string }]) => {
            const muteImage = new Image();

            const widget = new Container(
                new Row(
                    [
                        new ArtificialConstraint(
                            new Label(player.name, {
                                bodyTextFont: "1.5em sui-generis",
                                wrapMode: WrapMode.Ellipsis,
                            }),
                            [100, 100, 0, Infinity],
                        ),
                        new DecoratedButtonLikePane(
                            new Row(
                                [
                                    new Spacing(),
                                    new IconButton(muteImage, {
                                        width: 16,
                                        height: 16,
                                    }).on("click", () => {
                                        common.hoverboardNetworking.togglePeerMute(key);
                                    }),
                                    new Spacing(),
                                ],
                                { flex: 1 },
                            ),
                            {
                                flex: 1,
                                multiContainerAlignment: {
                                    main: FlexAlignment.Center,
                                    cross: Alignment.Stretch,
                                },
                            },
                        ),
                    ],
                    { multiContainerSpacing: 16 },
                ),
                { containerPadding: { top: 0, right: 16, bottom: 0, left: 0 } },
            );

            this.playerRows.set(key, { widget, muteImage });
            column.add(widget);
        });

        common.MAIN_CHANNEL.on("room-player-leave", (key: string) => {
            const rowData = this.playerRows.get(key);
            if (rowData) {
                this.playerRows.delete(key);
                column.remove(rowData.widget);
            }
        });

        common.MAIN_CHANNEL.on("room-leave", (key: string) => {
            for (const rowData of this.playerRows.values()) {
                column.remove(rowData.widget);
            }

            this.playerRows.clear();
        });
    }

    override preLayoutUpdate(): void {
        for (const [key, playerRowData] of this.playerRows) {
            const muted = common.hoverboardNetworking.isPeerMuted(key);
            playerRowData.muteImage.src = `assets/textures/ui/icons/kiosk/${muted ? "mute" : "volume-max"}.svg`;
        }

        super.preLayoutUpdate();
    }
}
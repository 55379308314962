import { Alignment, ArtificialConstraint, Background, Button, ClickState, FlexAlignment, Icon, IconFit, Label, LayeredContainer, LeaveEvent, PointerMoveEvent, PointerPressEvent, PointerReleaseEvent, Root, Row, TextAlignMode, Viewport, safeRoundRect, type Rect, type Variable, type Widget, type WidgetAutoXML, type WidgetEvent, type WidgetProperties } from "lazy-widgets";
import { type GameLocation } from "../../../../shared-netcode/src/config/game-configuration-types.js";
import { VALID_CONFIGURATIONS } from "../../../../shared-netcode/src/config/networking-constants.js";
import { AudioID } from "../../../audio/audio-manager/audio-id.js";
import { Audio } from "../../../audio/audio-manager/audio.js";
import common from "../../../common.js";
import { downHapticFeedback, hoverHapticFeedback, upHapticFeedback } from "../../../misc/haptic-feedback.js";
import { getCursorFromLZCID } from "../../lazy-widgets/get-cursor-from-lzcid.js";
import { BackPane } from "../../lazy-widgets/widgets/back-pane.js";
import { Carousel } from "../../lazy-widgets/widgets/carousel.js";
import { FilterContainer } from "../../lazy-widgets/widgets/filter-container.js";

export interface LocationButtonProperties extends WidgetProperties {
    releasedFilter?: string,
    hoverFilter?: string,
    holdFilter?: string,
}

export class LocationButton extends Button<FilterContainer> {
    static override autoXML: WidgetAutoXML = {
        name: "location-button",
        inputConfig: [
            {
                mode: "value",
                name: "location-id",
                validator: "string",
            },
            {
                mode: "value",
                name: "location-variable",
                validator: "variable",
            }
        ]
    };

    private _releasedFilter: string;
    private _hoverFilter: string;
    private _holdFilter: string;
    private _curState = ClickState.Released;
    private _prevState = ClickState.Released;
    private _filterChangedExternally = false;
    private clickSound: Audio | null;
    private hoverSound: Audio | null;
    private inBounds = false;
    private forced: boolean;
    private ascendantCarousel: Carousel | null = null;

    constructor(readonly locationID: GameLocation, readonly locationVariable: Variable<GameLocation | null>, properties?: Readonly<LocationButtonProperties>) {
        const releasedFilter = properties?.releasedFilter ?? "";
        const locConfig = VALID_CONFIGURATIONS.get(locationID)!;

        super(
            new FilterContainer(
                new ArtificialConstraint(
                    new Background(
                        new LayeredContainer<Widget>([
                            {
                                child: new Icon(
                                    `assets/textures/ui/thumbnails/maps/thumbnail-${locationID}.png`,
                                    { fit: IconFit.Cover },
                                ),
                            },
                            {
                                child: new ArtificialConstraint(
                                    new Row(
                                        [
                                            new BackPane(
                                                new Label(
                                                    locConfig.name.toUpperCase(),
                                                    {
                                                        bodyTextFill: "black",
                                                        bodyTextFont: "0.9em sui-generis",
                                                        bodyTextAlign: TextAlignMode.Center,
                                                        bodyTextSpacing: 0,
                                                    },
                                                ),
                                                { alpha: 0.6 }
                                            ),
                                        ],
                                        {
                                            multiContainerAlignment: {
                                                main: FlexAlignment.Center,
                                                cross: Alignment.Center,
                                            }
                                        },
                                    ),
                                    [150, 150, 40, 40],
                                ),
                            }
                        ]),
                        {
                            containerPadding: { left: 0, right: 0, top: 0, bottom: 0 },
                            canvasFill: `rgba(0,0,0,0.2)`,
                        },
                    ),
                    [150, 150, 40, 40],
                ),
                {
                    filter: releasedFilter,
                },
            ),
            {
                containerPadding: { left: 0, right: 0, top: 0, bottom: 0 },
                ...properties,
            },
        );

        this._releasedFilter = releasedFilter;
        // XXX these can either be css filters, or an rgba array containing the
        // tint color that will be overlayed, with RGB components in the range
        // 0-255, and A component in the range 0-1
        this._hoverFilter = properties?.hoverFilter ?? "brightness(75%)";// [127, 131, 255, 0.5];
        this._holdFilter = properties?.holdFilter ?? "brightness(50%)";// [127, 131, 255, 0.5];

        this.clickSound = common.audioManager.getAudio(AudioID.BUTTON_CLICK);
        this.hoverSound = common.audioManager.getAudio(AudioID.BUTTON_HOVER);

        this.forced = this.locationVariable.value === this.locationID;
        this.locationVariable.watch(() => {
            const newHasBorder = this.locationVariable.value === this.locationID;
            if (this.forced === newHasBorder) return;
            this.forced = newHasBorder;
            this.markWholeAsDirty();
        });

        this.on("click", () => {
            this.locationVariable.value = this.locationID;
        });
    }

    get releasedFilter() {
        return this._releasedFilter;
    }

    set releasedFilter(releasedFilter) {
        if (this._releasedFilter !== releasedFilter) {
            this._releasedFilter = releasedFilter;
            this._filterChangedExternally = true;
        }
    }

    get hoverFilter() {
        return this._hoverFilter;
    }

    set hoverFilter(hoverFilter) {
        if (this._hoverFilter !== hoverFilter) {
            this._hoverFilter = hoverFilter;
            this._filterChangedExternally = true;
        }
    }

    get holdFilter() {
        return this._holdFilter;
    }

    set holdFilter(holdFilter) {
        if (this._holdFilter !== holdFilter) {
            this._holdFilter = holdFilter;
            this._filterChangedExternally = true;
        }
    }

    private _updatePickedFilter() {
        switch (this._curState) {
            case ClickState.Hover:
                this.child.filter = this._hoverFilter;
                break;
            case ClickState.Hold:
                this.child.filter = this._holdFilter;
                break;
            default:
                this.child.filter = this._releasedFilter;
                break;
        }
    }

    override click() {
        if (this.clickSound) {
            this.clickSound.play();
        }

        super.click();
    }

    override handleEvent(event: WidgetEvent) {
        const captured = super.handleEvent(event);

        if (this.clickHelper.clickStateChanged) {
            this._curState = this.clickHelper.clickState;
        }

        if (this.active && this.clickable) {
            if (event.isa(PointerMoveEvent)) {
                if (!this.inBounds) {
                    this.inBounds = true;
                    this.hoverSound!.play();
                    const cursor = getCursorFromLZCID(event);
                    if (cursor) hoverHapticFeedback(cursor);
                }
            } else if (event.isa(LeaveEvent)) {
                if (this.inBounds) this.inBounds = false;
            } else if (event.isa(PointerPressEvent)) {
                const cursor = getCursorFromLZCID(event);
                if (cursor) downHapticFeedback(cursor);
            } else if (event.isa(PointerReleaseEvent)) {
                const cursor = getCursorFromLZCID(event);
                if (cursor) upHapticFeedback(cursor);
            }
        }

        return captured;
    }

    override set clickable(clickable) {
        if (!clickable) {
            this.inBounds = false;
        }

        super.clickable = clickable;
    }

    override get clickable() {
        return super.clickable;
    }

    override deactivate() {
        super.deactivate();
        this.inBounds = false;
    }

    override handlePostLayoutUpdate() {
        super.handlePostLayoutUpdate();

        if (this._curState !== this._prevState) {
            this._prevState = this._curState;
            this._updatePickedFilter();
        } else if (this._filterChangedExternally) {
            this._updatePickedFilter();
        }

        this._filterChangedExternally = false;

        if (this.ascendantCarousel && this.forced) {
            this.ascendantCarousel.slideToDescendant(this);
        }
    }

    protected override handlePainting(dirtyRects: Rect[]): void {
        const ctx = this.viewport.context;
        ctx.save();
        ctx.beginPath();
        safeRoundRect(ctx, this.x, this.y, this.width, this.height, this.roundedCornersRadii);
        ctx.clip();

        super.handlePainting(dirtyRects);

        // TODO remove?
        if (this.forced) {
            ctx.beginPath();
            safeRoundRect(ctx, this.x, this.y, this.width, this.height, this.roundedCornersRadii);
            ctx.strokeStyle = `rgba(0,192,255,0.8)`;
            ctx.lineWidth = 6;
            ctx.stroke();
        }

        ctx.restore();
    }

    override attach(root: Root, viewport: Viewport, parent: Widget | null): void {
        super.attach(root, viewport, parent);

        let focus = this.parent;
        while (focus) {
            if (focus instanceof Carousel) {
                this.ascendantCarousel = focus;
                break;
            }
            focus = focus.parent;
        }
    }

    override detach(): void {
        super.detach();
        this.ascendantCarousel = null;
    }
}
import { Material, MeshComponent, SceneAppendResult, Texture, WonderlandEngine } from "@wonderlandengine/api";
import { vec4 } from "gl-matrix";
import { vec4_create, Vec4Utils } from "wle-pp";
import { AssetMaterial, AssetMaterialList, MaterialInterface } from "../ui/kiosk/components/asset-manifest-types.js";

const WHITE = vec4_create(1.0, 1.0, 1.0, 1.0);
const CONFIG_DIFFUSE = vec4_create(0.6, 0.6, 0.6, 1.0);
const TEMP_COLOR = vec4_create(0.4, 0.4, 0.4, 1.0);

export function loadExternalObject(engine: WonderlandEngine, url: string): Promise<SceneAppendResult> {
    return engine.scene.append(url);
}

export function loadExternalTexture(engine: WonderlandEngine, url: string): Promise<Texture> {
    return engine.textures.load(url, 'anonymous');
}

export function loadTexturesForMaterialList(engine: WonderlandEngine, materialKeys: Array<keyof AssetMaterialList>, configObject: any) {
    for (const material of materialKeys) {
        const materialObject = configObject.materials[material];
        if (materialObject.pipeline === "Phong Normalmapped") {
            const diffuseTextureUrl = materialObject.diffuseTexture;
            const normalTextureUrl = materialObject.normalTexture;

            if (diffuseTextureUrl && !materialObject.diffuseTexturePromise) {
                materialObject.diffuseTexturePromise = loadExternalTexture(engine, diffuseTextureUrl);
            }

            if (normalTextureUrl && !materialObject.normalTexturePromise) {
                materialObject.normalTexturePromise = loadExternalTexture(engine, normalTextureUrl);
            }
        } else if (materialObject.pipeline === "Phong Opaque Textured Emissive") {
            const diffuseTextureUrl = materialObject.diffuseTexture;
            const emissiveTextureUrl = materialObject.emissiveTexture;
            if (diffuseTextureUrl && !materialObject.diffuseTexturePromise) {
                materialObject.diffuseTexturePromise = loadExternalTexture(engine, diffuseTextureUrl);
            }

            if (emissiveTextureUrl && !materialObject.emissiveTexturePromise) {
                materialObject.emissiveTexturePromise = loadExternalTexture(engine, emissiveTextureUrl);
            }
        }
    }
}

export function setMaterialFromMaterialConfig(meshComponent: MeshComponent, configObject: MaterialInterface, isConfigBoard: boolean, engine: WonderlandEngine) {
    const newMaterial = new Material(engine, { pipeline: configObject.pipeline }) as AssetMaterial;
    meshComponent.material = newMaterial;
    if (configObject.pipeline === "Phong Normalmapped") {
        if (configObject.diffuseTexturePromise) {
            configObject.diffuseTexturePromise.then((diffuseTexture: Texture) => {
                newMaterial.diffuseTexture = diffuseTexture;
            });
        }
        if (configObject.normalTexturePromise) {
            configObject.normalTexturePromise.then((normalTexture: Texture) => {
                newMaterial.normalTexture = normalTexture;
            });
        }
        if (isConfigBoard) {
            setMaterialLightEmissiveDiffuseColor(newMaterial, null);
        } else {
            newMaterial.diffuseColor = WHITE as number[];
        }
    } else if (configObject.pipeline === "Phong Opaque Textured Emissive") {
        if (configObject.diffuseTexturePromise) {
            configObject.diffuseTexturePromise.then((diffuseTexture: Texture) => {
                newMaterial.diffuseTexture = diffuseTexture;
            });
        }
        if (configObject.emissiveTexturePromise) {
            configObject.emissiveTexturePromise.then((emissiveTexture: Texture) => {
                newMaterial.emissiveTexture = emissiveTexture;
            });
        }
        if (isConfigBoard) {
            setMaterialLightEmissiveDiffuseColor(newMaterial, null);
        } else {
            newMaterial.diffuseColor = WHITE as number[];
        }
    } else if (configObject.pipeline === "Phong Opaque") {
        if (isConfigBoard) {
            setMaterialLightEmissiveDiffuseColor(newMaterial, configObject.diffuseColor as number[]);
        } else {
            newMaterial.diffuseColor = configObject.diffuseColor;
        }
    } else if (configObject.pipeline === "Flat Opaque") {
        newMaterial.color = configObject.color;
    }
}

export function setMaterialLightEmissiveDiffuseColor(material: AssetMaterial, colorOverride: number[] | null) {
    let color;
    if (colorOverride) {
        Vec4Utils.copy(colorOverride, TEMP_COLOR);
        vec4.scale(TEMP_COLOR as vec4, TEMP_COLOR as vec4, 0.85);
        color = TEMP_COLOR as number[];
    } else {
        color = CONFIG_DIFFUSE as number[];
    }

    material.diffuseColor = color;
    material.ambientColor = WHITE as number[];
}

const STANDARD_DIFFUSE = vec4_create(0.75, 0.75, 0.75, 1.0) as number[];
const STANDARD_AMBIENT = vec4_create(0.25, 0.25, 0.25, 1.0) as number[];

export function setMaterialStandardEmissiveDiffuseColor(material: AssetMaterial, colorOverride: number[]) {
    material.diffuseColor = STANDARD_DIFFUSE;
    material.ambientColor = STANDARD_AMBIENT;
}
import { Component, MeshComponent, Object3D } from '@wonderlandengine/api';
import { property } from '@wonderlandengine/api/decorators.js';
import { EasyTuneNumber, FlatMaterial, Globals, PhongMaterial, Timer, Vec3Utils, vec3_create } from 'wle-pp';
import { AudioID } from '../../../audio/audio-manager/audio-id.js';
import common from '../../../common.js';
import { EasyTuneExtraParamsSet } from '../../components/hoverboard-debugs-component.js';
import { GAME_STATES } from '../../game-states.js';
import { HoverboardComponent } from './hoverboard-component.js';

let _speedBoost: number = 50.0;
let _pickupDistance: number = 5.0;
let _verticalSpeedBoost: number = 50.0;
let _respawnDuration: number = 1.0;

enum PickupType {
    SpeedBoost = "Speed Boost",
    SpringBoost = "Spring Boost",
    Fitabux = "Fitabux"
}

const _pickupTypeFunctionalityMap: Map<PickupType, (hoverboard: HoverboardComponent) => void> = new Map([
    [
        PickupType.SpeedBoost,
        (hoverboard: HoverboardComponent) => {
            hoverboard.applyBoost(_speedBoost);
            const rampBoostAudio = common.audioManager.getAudio(AudioID.PICKUP_ROCKET);
            rampBoostAudio!.play();
        }
    ],
    [
        PickupType.SpringBoost,
        (hoverboard: HoverboardComponent) => {
            hoverboard.applyVerticalBoost(_verticalSpeedBoost);
            const rampBoostAudio = common.audioManager.getAudio(AudioID.PICKUP_SPRING);
            rampBoostAudio!.play();
        }
    ],
    [
        PickupType.Fitabux,
        (hoverboard: HoverboardComponent) => {
            // TODO: Increment fitabucks
        }
    ]
]);

const pickupPosition = vec3_create();
const objectPickingUpPosition = vec3_create();

export class PickupComponent extends Component {
    static TypeName = 'pickup';

    @property.enum(Object.values(PickupType), Object.values(PickupType)[0])
    pickupType!: number;

    private adjustedPickupType: PickupType = PickupType.SpeedBoost;

    private _objectsToDeactivate: Object3D[] = [];

    private _isRespawning: boolean = false;
    private _respawnTimer: Timer = new Timer(_respawnDuration);

    start(): void {
        this.adjustedPickupType = Object.values(PickupType)[this.pickupType];

        this._objectsToDeactivate = this.object.pp_getObjectsByNameDescendants("^(?!Base)", true)!;

        const hologramObjects = this.object.pp_getObjectsByName("Hologram", true)!;
        const hologramColor = this.adjustedPickupType == PickupType.SpeedBoost ?
            common.hoverfitSceneResources.pickupSpeedHologramConeColor :
            common.hoverfitSceneResources.pickupSpringHologramConeColor;
        for (const hologramObject of hologramObjects) {
            const meshComponent = hologramObject.pp_getComponent(MeshComponent)!;
            meshComponent.material = meshComponent.material!.clone();

            const flatMaterial = meshComponent.material as FlatMaterial;
            if (flatMaterial.color != null) {
                flatMaterial.color = hologramColor;
            }

            const phongMaterial = flatMaterial as unknown as PhongMaterial;
            if (phongMaterial.diffuseColor != null) {
                phongMaterial.diffuseColor = hologramColor;
            }

            if (phongMaterial.ambientColor != null) {
                phongMaterial.ambientColor = hologramColor;
            }
        }

        this._setupEasyTuneVariables();
    }

    update(dt: number) {
        if (common.CURRENT_STATE !== GAME_STATES.GAME) return;

        if (this._isRespawning) {
            this._respawnTimer.update(dt);
            if (this._respawnTimer.isDone()) {
                this._onRespawn();
            }
        } else {
            this._checkIsPickedUp(common.hoverboard.object);
        }
    }

    private _checkIsPickedUp(objectPickingUp: Object3D) {
        this.object.getPositionWorld(pickupPosition);
        objectPickingUp.getPositionWorld(objectPickingUpPosition);
        const distance = Vec3Utils.distanceSquared(pickupPosition, objectPickingUpPosition);

        if (distance < _pickupDistance * _pickupDistance) {
            this._onPickup(objectPickingUp);
        }
    }

    private _onPickup(objectPickingUp: Object3D) {
        // Call the corresponding functionality to pickup type
        _pickupTypeFunctionalityMap.get(this.adjustedPickupType)!(common.hoverboard);
        for (const objectToDeactivate of this._objectsToDeactivate) {
            objectToDeactivate.pp_setActive(false);
        }

        Globals.getLeftGamepad()!.pulse(0.35, 0.5);
        Globals.getRightGamepad()!.pulse(0.35, 0.5);

        this._isRespawning = true;
        this._respawnTimer.start();
    }

    private _onRespawn() {
        this._isRespawning = false;
        for (const objectToDeactivate of this._objectsToDeactivate) {
            objectToDeactivate.pp_setActive(true);
        }
    }

    private _setupEasyTuneVariables() {
        const easyTuneVariables = Globals.getEasyTuneVariables(this.engine);

        easyTuneVariables!.add(new EasyTuneNumber("Pickup Speed Boost", _speedBoost, (newValue: number) => _speedBoost = newValue, false, 0, 100, 0).setEasyTuneVariableExtraParams(EasyTuneExtraParamsSet.importExportEnabled));
        easyTuneVariables!.add(new EasyTuneNumber("Pickup Vertical Speed Boost", _verticalSpeedBoost, (newValue: number) => _verticalSpeedBoost = newValue, false, 0, 100, 0).setEasyTuneVariableExtraParams(EasyTuneExtraParamsSet.importExportEnabled));
        easyTuneVariables!.add(new EasyTuneNumber("Pickup Pick Distance", _pickupDistance, (newValue: number) => _pickupDistance = newValue, false, 0, 10, 0).setEasyTuneVariableExtraParams(EasyTuneExtraParamsSet.importExportEnabled));
        easyTuneVariables!.add(new EasyTuneNumber("Pickup Respawn Duration", _respawnDuration, (newValue: number) => _respawnDuration = newValue, false, 0, 10, 0).setEasyTuneVariableExtraParams(EasyTuneExtraParamsSet.importExportEnabled));
    }
}

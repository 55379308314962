import { vec4 } from "gl-matrix";

export const enum SuitColor {
    Red,
    Blue,
    Black,
    Green,
    White,
    Pink,
}

export const SUIT_COLORS = [
    {
        diffuseColor: vec4.fromValues(0.6143, 0, 0, 1),
        emissiveColor: vec4.fromValues(1, 0.6726456880569458, 0, 1),
    },
    {
        diffuseColor: vec4.fromValues(0.0772, 0.3534, 0.5739, 1),
        emissiveColor: vec4.fromValues(0, 1, 0.6636, 1),
    },
    {
        diffuseColor: vec4.fromValues(0.05829, 0.05829, 0.05829, 1),
        emissiveColor: vec4.fromValues(0, 0.1485581, 0.9019607, 1),
    },
    {
        diffuseColor: vec4.fromValues(0.0020, 0.4663, 0.1103, 1),
        emissiveColor: vec4.fromValues(0.6529, 0.8565, 0, 1),
    },
    {
        diffuseColor: vec4.fromValues(0.6892, 0.6892, 0.6892, 1),
        emissiveColor: vec4.fromValues(1, 0.8927, 0.2286, 1),
    },
    {
        diffuseColor: vec4.fromValues(0.8116, 0.1237, 0.4013, 1),
        emissiveColor: vec4.fromValues(0.7264, 0.7264, 0.7264, 1),
    },
] as const;

export const SUIT_COLOR_COUNT = SUIT_COLORS.length;
export function getRandomSuitColor(): string {
    return String(Math.trunc(Math.random() * SUIT_COLOR_COUNT));
}
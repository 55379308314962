import { Component, Property } from "@wonderlandengine/api";
import common from "src/hoverfit/common.js";
import { Timer } from "wle-pp";
import { InstructorAudioType } from "./hoverboard-instructor-component.js";

const MUSCLE_ANIMATION_TYPE = {
    ALL: 0,
    PRIMARY: 1,
    SECONDARY: 2,
    NONE: 3
};

const PLAY_TOP_INDICES = [MUSCLE_ANIMATION_TYPE.ALL, MUSCLE_ANIMATION_TYPE.SECONDARY];
const PLAY_BOTTOM_INDICES = [MUSCLE_ANIMATION_TYPE.ALL, MUSCLE_ANIMATION_TYPE.PRIMARY];

const HowToHoverboardMuscleAnimationSequence = [
    {
        playUntil: 8.5,
        animationType: MUSCLE_ANIMATION_TYPE.ALL
    },
    {
        playUntil: 15.1,
        animationType: MUSCLE_ANIMATION_TYPE.PRIMARY
    },
    {
        playUntil: 27.79166603088379, // Animation duration - delay
        animationType: MUSCLE_ANIMATION_TYPE.SECONDARY
    }
];

const FitnessBenefitsMuscleAnimationSequence = [
    {
        playUntil: 6.0,
        animationType: MUSCLE_ANIMATION_TYPE.NONE
    },
    {
        playUntil: 12.4,
        animationType: MUSCLE_ANIMATION_TYPE.ALL
    },
    {
        playUntil: 27,
        animationType: MUSCLE_ANIMATION_TYPE.PRIMARY
    },
    {
        playUntil: 40, // Animation duration - delay
        animationType: MUSCLE_ANIMATION_TYPE.SECONDARY
    }
];

export class TrainerMusclesComponent extends Component {
    static TypeName = "trainer-muscles";
    static Properties = {
        sequenceType: Property.enum(["HowToHoverboard", "FitnessBenefits", "Cyclic"], "HowToHoverboard"),
        muscleMaterial: Property.material(),
        cycleSpeed: Property.float(1.5),
        startDelay: Property.float(0.5),
    };

    init() {
        if (!this.muscleMaterial) return console.error("trainer-muscles: Missing muscles material");
        this.setSequence(this.sequenceType);
        this.time = 0;
        if (this.sequenceType === 0 || this.sequenceType === 1) {
            this.animationSequenceIndex = 0;
            this.timer = new Timer(this.startDelay);
        } else if (this.sequenceType === 2) {
            this.timer = new Timer(this.startDelay);
        }
        this.showUpper = true;

        common.MAIN_CHANNEL.on("play-tutorial", (type) => {
            this.time = 0;
            if (InstructorAudioType.Instructions === type) {
                this.setSequence(0);
            } else if (InstructorAudioType.HealthBenefits === type) {
                this.setSequence(1);
            }
        });
    }

    setSequence(type) {
        this.sequenceType = type;
        switch (type) {
            case 0:
                this.currentSequence = HowToHoverboardMuscleAnimationSequence;
                break;
            case 1:
                this.currentSequence = FitnessBenefitsMuscleAnimationSequence;
                break;
        }
    }

    resetPulse() {
        this.time = 0;
        this.timer.start();
        this.showUpper = true;
        this.animationSequenceIndex = 0;
        this.muscleMaterial.topMuscleDisplayAmount = 0;
        this.muscleMaterial.bottomMuscleDisplayAmount = 0;
    }

    setCurrentAnimationSequenceIndex(currentTime) {
        for (let i = 0; i < this.currentSequence.length; i++) {
            if (currentTime <= this.currentSequence[i].playUntil) return this.animationSequenceIndex = i;
        }
        return -1;
    }

    shouldPlayTopAnimation(sequenceIndex) {
        return PLAY_TOP_INDICES.includes(this.currentSequence[sequenceIndex].animationType);
    }

    shouldPlayBottomAnimation(sequenceIndex) {
        return PLAY_BOTTOM_INDICES.includes(this.currentSequence[sequenceIndex].animationType);
    }


    updateSequence(dt) {
        if (this.timer.isDone()) {
            this.time += dt;
            console.log(this.time);
            this.setCurrentAnimationSequenceIndex(this.time);
            const value = Math.abs(Math.sin(this.time * this.cycleSpeed));
            if (this.muscleMaterial) {
                this.muscleMaterial.topMuscleDisplayAmount = this.shouldPlayTopAnimation(this.animationSequenceIndex) ? value : 0;
                this.muscleMaterial.bottomMuscleDisplayAmount = this.shouldPlayBottomAnimation(this.animationSequenceIndex) ? value : 0;
            }

            if (this.time >= this.currentSequence[this.currentSequence.length - 1].playUntil) {
                this.time = 0;
            }
        }
    }

    updateCyclic(dt) {
        if (this.timer.isDone()) {
            this.time += dt;
            const value = Math.abs(Math.sin(this.time * this.cycleSpeed));
            if (this.muscleMaterial) {
                this.muscleMaterial.topMuscleDisplayAmount = this.showUpper ? value : 0;
                this.muscleMaterial.bottomMuscleDisplayAmount = !this.showUpper ? value : 0;
            }

            if (this.time >= (Math.PI * 2.0) / this.cycleSpeed) {
                this.time -= (Math.PI * 2.0) / this.cycleSpeed;
                this.showUpper = !this.showUpper;
            }
        }
    }

    update(dt) {
        this.timer.update(dt);

        if (this.sequenceType === 0 || this.sequenceType === 1) {
            this.updateSequence(dt);
        } else if (this.sequenceType === 2) {
            this.updateCyclic(dt);
        }
    }
}

import { Component, Property } from "@wonderlandengine/api";
import * as Colyseus from "colyseus.js";
import { GameMode, PROTOCOL_VERSION, VALID_CONFIGURATIONS } from "hoverfit-shared-netcode";
import { TagResultBoardData } from "src/hoverfit/game/track/results/tag-results-board.js";
import { AnalyticsUtils, GamepadButtonEvent, GamepadButtonID, Globals, MathUtils, Timer } from "wle-pp";
import * as backends from "../../../../build-data/backends.json";
import { AudioChannelName } from "../../audio/audio-manager/audio-channel.js";
import { AudioID } from "../../audio/audio-manager/audio-id.js";
import { AudioSinkComponent } from "../../audio/voip/components/audio-sink-component.js";
import { VoIPHelper } from "../../audio/voip/voip-helper.js";
import { AvatarComponent } from "../../avatar/components/avatar-component.js";
import common from "../../common.js";
import { HoverboardGameConfig, currentGameConfig } from "../../data/game-configuration.js";
import { currentPlayerData } from "../../data/player-data.js";
import { HoverboardDebugs } from "../../game/components/hoverboard-debugs-component.js";
import { GAME_STATES } from "../../game/game-states.js";
import { HoverboardComponent } from "../../game/hoverboard/components/hoverboard-component.js";
import { setNPCCountdownEndTimestamp } from "../../game/hoverboard/components/npc-controller-component.js";
import { PriorityLevel } from "../../misc/data-structs/expiring-priority-queue.js";
import { isAnotherSceneLoading } from "../../misc/load-scene/load-scene.js";
import { MS_PREF_DEFAULT, MS_PREF_KEY, P2P_PREF_DEFAULT, P2P_PREF_KEY, PERMISSIONS_DEFAULT, PERMISSIONS_KEY } from "../../misc/preferences/pref-keys.js";
import { GLOBAL_PREFS } from "../../misc/preferences/preference-manager.js";
import { WarningEmitter } from "../../misc/warning-emitter.js";
import { MessagePopupParams } from "../../ui/popup/implementations/message-popup.js";
import { PopupIconImage } from "../../ui/popup/popup.js";
import { replaceSearchParams } from "../../utils/url-utils.js";
import { NetworkPlayerComponent } from "./network-player-component.js";
import { KEYS, KEY_TO_INDEX, synchedObjects } from "./network-sync-component.js";

const MAX_CONN_RETRIES = 1;

export class RoomData {
    /**
     * TS type inference helper
     * 
     * @param {any} dataToCopy 
     */
    constructor(dataToCopy = null) {
        this.roomNumber = null;
        this.privateRoom = false;

        if (dataToCopy != null) {
            this.roomNumber = dataToCopy.roomNumber;
            this.privateRoom = dataToCopy.privateRoom;
        }
    }
}

// XXX persistent, don't put in the common object
export const currentRoomData = new RoomData();

export const HOVERBOARD_PLAYER_MULTIPLAYER_STATES = {
    RACE_MENU: 0,
    RACE_RACETRACK_UNREADY: 1,
    RACE_RACETRACK_READY: 2,
    RACE_STARTED: 3,
    RACE_ENDED: 4,
    RACE_ENDED_CONTINUE: 5
};

export const HOVERBOARD_MULTIPLAYER_STATES = {
    RACE_PREPARATION: 0,
    RACE_STARTED: 1,
    RACE_ENDED: 2
};

export class HoverboardNetworkingComponent extends Component {
    static TypeName = "hoverboard-networking";
    static Properties = {
        spatialAudio: Property.bool(false),
    };

    static onRegister(engine) {
        engine.registerComponent(AudioSinkComponent);
    }

    onActivate() {
        currentPlayerData.listen(this.nameListener);
    }

    onDeactivate() {
        currentPlayerData.unlisten(this.nameListener);
    }

    init() {
        this.nameListener = this.setOwnName.bind(this);
        common.hoverboardNetworking = this;

        this.sinkComponentObject = this.object.pp_addObject();
        const audioSinkComponent = this.sinkComponentObject.addComponent(AudioSinkComponent, {
            rotateForward: true, // XXX wle-pp points the wrong way
        });
        this.sink = audioSinkComponent.sink;

        // XXX let the user override the backend
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const preferredBackend = urlParams.get("backend");

        if (preferredBackend !== null) {
            const wantedURL = backends[preferredBackend];
            if (wantedURL) {
                console.warn(`Backend URL overwritten with ID "${preferredBackend}", which resolves to URL "${wantedURL}"`);
                this.url = wantedURL;
            } else {
                console.warn(`Invalid backend ID "${preferredBackend}"`);
            }
        }

        if (!this.url) {
            console.debug(`Using default backend, which resolves to URL "${DEFAULT_COLYSEUS_BACKEND_URL}"`);
            this.url = DEFAULT_COLYSEUS_BACKEND_URL;
        }

        this.client = new Colyseus.Client(this.url);

        // XXX VoIPHelper keeps track of peers in their own way. it's set up to
        // use sessionIds as peer IDs
        /** @type {VoIPHelper<string>} */
        this.voip = new VoIPHelper({
            iceServers: [
                {
                    "urls": VOIP_STUN_URLS
                }
            ],
            spatialAudio: this.spatialAudio,
            audioSink: this.sink,
        });

        this.voip.addListener("peer-added", function (voipPeer) {
            const audioID = AudioID.VOIP_ + voipPeer.id;
            common.audioManager.addSourceAudioToChannel(audioID, voipPeer.audioSource, AudioChannelName.VOIP);
        });

        this.voip.addListener("peer-removed", function (voipPeer) {
            const audioID = AudioID.VOIP_ + voipPeer.id;
            common.audioManager.removeAudio(audioID);
        });

        this.otherPlayers = new Map();
        this.otherPlayerObjects = new Map();

        this.npcSeed = MathUtils.randomInt(0, 65534);

        common.MAIN_CHANNEL.on("try-disconnect", () => {
            if (this.room) {
                this.room.leave(true);
                this.room = null;

                currentRoomData.roomNumber = null;
                currentRoomData.privateRoom = false;
            }
        });

        this.p2pBadWarning = new WarningEmitter("show-p2p-bad-warning", "hide-p2p-bad-warning", 5000);
        this.p2pUnavailableWarning = new WarningEmitter("show-p2p-unavailable-warning", "hide-p2p-unavailable-warning", 2000);
        this.mediasoupUnavailableWarning = new WarningEmitter("show-mediasoup-unavailable-warning", "hide-mediasoup-unavailable-warning", 2000);

        this.prefSetListener = (key, value) => {
            if (this.room) {
                if (key === P2P_PREF_KEY) {
                    this.voip.toggleP2P(value);
                } else if (key === MS_PREF_KEY) {
                    this.voip.toggleMediasoup(value);
                } else if (key === PERMISSIONS_KEY) {
                    if (!value) this.room.leave(true);
                } else {
                    return;
                }

                this.updateVoIPWarnings();
            }
        };
        GLOBAL_PREFS.on("pref-set", this.prefSetListener);

        this.enablePlayerJoinedTimer = new Timer(3);
    }

    start() {
        this.sinkComponentObject.pp_setParent(Globals.getPlayerObjects(this.engine).myHead);
        this.sinkComponentObject.pp_resetTransformLocal();

        this.initNetworkTimer = new Timer(0.5);

        this.hoverboard = Globals.getRootObject(this.engine).pp_getComponent(HoverboardComponent);

        if (Globals.isDebugEnabled() && HoverboardDebugs.toggleVOIPTypeShortcutEnabled) {
            Globals.getRightGamepad(this.engine).registerButtonEventListener(GamepadButtonID.TOP_BUTTON, GamepadButtonEvent.PRESS_END, 0, () => {
                if (this.voip) {
                    console.debug("toggling p2p and mediasoup off");
                    const hadP2P = this.voip.p2pEnabled;
                    this.voip.toggleP2P(false);
                    const hadMS = this.voip.mediasoupEnabled;
                    this.voip.toggleMediasoup(false);
                    setTimeout(() => {
                        if (hadP2P) {
                            console.debug("toggling p2p back on");
                            this.voip.toggleP2P(true);
                        }
                        if (hadMS) {
                            console.debug("toggling mediasoup back on");
                            this.voip.toggleMediasoup(true);
                        }
                    }, 500);
                }
            });
        }
    }

    _start() {
    }

    resetPlayer() {
        const menu = common.menu;
        if (menu) {
            menu.returnToBalcony();
        }
    }

    setOwnName() {
        if (this.room) {
            this.room.send("set-name", { name: currentPlayerData.name });
        }
    }

    makeRoomOptions(roomNumber) {
        return {
            protocolVersion: PROTOCOL_VERSION,
            // TODO i don't like that we have to coerce room number into a
            //      string. we should force consistent types on the codebase
            //      when we port everything to typescript
            desiredRoomNumber: roomNumber === null ? null : `${roomNumber}`,
            gameConfiguration: {
                location: currentGameConfig.location,
                mode: currentGameConfig.mode,
                track: currentGameConfig.track,
            },
            npcsAmount: currentGameConfig.npcsAmount.value,
            lapsAmount: currentGameConfig.lapsAmount.value,
            tagDuration: currentGameConfig.tagDuration.value,
            npcsDifficulty: currentGameConfig.npcsDifficulty.value,
            maxClients: currentGameConfig.maxClients,
            desiredName: currentPlayerData.name,
            desiredAvatarType: currentPlayerData.avatarType,
            desiredAvatarSkinColor: currentPlayerData.skinColor,
            desiredAvatarSuitVariant: currentPlayerData.suitVariant,
            desiredAvatarHeadwearVariant: currentPlayerData.headwearVariant,
            desiredHoverboardVariant: currentPlayerData.hoverboardVariant,
        };
    }

    async join(roomNumber) {
        if (!GLOBAL_PREFS.getPref(PERMISSIONS_KEY, PERMISSIONS_DEFAULT) && !await common.pauseMenu.getPermissionsConsent()) {
            return;
        }

        const MAIN_CHANNEL = common.MAIN_CHANNEL;

        try {
            // exit room if already joined
            MAIN_CHANNEL.emit("try-disconnect");

            // join existing room by id
            MAIN_CHANNEL.emit("room-join", roomNumber);

            const roomOptions = this.makeRoomOptions(roomNumber);
            this.room = await this.client.joinById(roomNumber, roomOptions);

            const url = new URL(window.location);
            const searchParams = url.searchParams;
            searchParams.set("room", this.room.id);

            // update url with room id
            replaceSearchParams(url, searchParams);

            if (this.room != null) {
                // initialize room
                this.initializeRoom(false, false);
            }
        } catch (e) {
            console.error("join error", e);
            MAIN_CHANNEL.emit("room-join-error", e);
        }
    }

    async create(roomNumber, privateRoom) {
        if (!GLOBAL_PREFS.getPref(PERMISSIONS_KEY, PERMISSIONS_DEFAULT) && !await common.pauseMenu.getPermissionsConsent()) {
            return;
        }
        const MAIN_CHANNEL = common.MAIN_CHANNEL;

        try {
            const url = new URL(window.location);
            const searchParams = url.searchParams;

            // exit room if already joined
            MAIN_CHANNEL.emit("try-disconnect");

            // if join failed/not joined, create new room
            MAIN_CHANNEL.emit("room-create", roomNumber, privateRoom);

            const roomOptions = this.makeRoomOptions(roomNumber);
            roomOptions.privateRoom = privateRoom;
            this.room = await this.client.create(currentGameConfig.mode, roomOptions);
            searchParams.set("room", this.room.id);

            // update url with room id
            replaceSearchParams(url, searchParams);

            if (this.room != null) {
                // initialize room
                this.initializeRoom(true, privateRoom);
            }
        } catch (e) {
            console.error("join error", e);
            MAIN_CHANNEL.emit("room-create-error", e);
        }
    }

    async createOrJoin(roomNumber, privateRoom, isCreate = false) {
        if (!GLOBAL_PREFS.getPref(PERMISSIONS_KEY, PERMISSIONS_DEFAULT) && !await common.pauseMenu.getPermissionsConsent()) {
            return;
        }
        const MAIN_CHANNEL = common.MAIN_CHANNEL;

        try {
            const url = new URL(window.location);
            const searchParams = url.searchParams;

            // exit room if already joined
            MAIN_CHANNEL.emit("try-disconnect");

            MAIN_CHANNEL.emit("room-join", roomNumber);

            // get random existing room to join if none were specified
            if (roomNumber === null) {
                const availableRooms = await this.client.getAvailableRooms();
                let filteredRooms = availableRooms.filter(function (availableRoom) {
                    // get a romm that:
                    // 1. is not full (already filtered by getAvailableRooms)
                    // 2. has players
                    // 3. has empty spots on the track (trackConfig.maxPlayers not exceeded)
                    const meta = availableRoom.metadata;
                    if (meta && (typeof meta === "object") && availableRoom.clients > 0) {
                        const locationConfig = VALID_CONFIGURATIONS.get(meta.location);
                        if (!locationConfig) return false;
                        const modeConfig = locationConfig.modes.get(meta.mode);
                        if (!modeConfig) return false;
                        const trackConfig = modeConfig.tracks[meta.track];
                        if (!trackConfig) return false;
                        return trackConfig.maxPlayers === null || availableRoom.clients <= trackConfig.maxPlayers - 1;
                    }

                    return false;
                });

                if (filteredRooms.length > 0) {
                    const pickedRoom = filteredRooms[Math.trunc(filteredRooms.length * Math.random())];
                    roomNumber = pickedRoom.roomId;
                }
            }

            // join existing room by id
            let joined = false;
            const roomOptions = this.makeRoomOptions(roomNumber);
            roomOptions.privateRoom = privateRoom;

            let tryJoinButRoomNotExists = false;

            let retries = 0;
            if (roomNumber !== null) {
                while (!joined) {
                    try {
                        this.room = await this.client.joinById(roomNumber, roomOptions);
                        searchParams.set("room", this.room.id);
                        joined = true;
                    } catch (error) {
                        if (error.code == 4989) {
                            searchParams.set("room", roomNumber);

                            let newRoomData = new RoomData();
                            newRoomData.roomNumber = roomNumber;
                            newRoomData.privateRoom = privateRoom;

                            const newGameConfig = HoverboardGameConfig.fromServerJSONString(error.message);
                            if (!await common.menu.changeGameConfig(newRoomData, newGameConfig, false)) {
                                return;
                            }

                            roomOptions.gameConfiguration = newGameConfig;
                        } else {
                            console.warn("Failed to join room by id", error);
                            tryJoinButRoomNotExists = error.code == 4212; // room not found error
                            break;
                        }
                    }

                    if (!joined && ++retries > MAX_CONN_RETRIES) {
                        throw new Error("Maximum connection retries exceeded");
                    }
                }
            }

            // if join failed/not joined, create new room
            if (!joined) {
                let created = false;
                try {
                    if (tryJoinButRoomNotExists) {
                        roomOptions.failIfIdNotAvailable = true;
                    }

                    this.room = await this.client.create(currentGameConfig.mode, roomOptions);
                    searchParams.set("room", this.room.id);
                    created = true;
                } catch (error) {
                    if (error.code == 4989) {
                        searchParams.set("room", roomNumber);
                        let currentRoomConfig = JSON.parse(error.message);
                        if (!currentGameConfig.matches(currentRoomConfig)) {
                            let newRoomData = new RoomData();
                            newRoomData.roomNumber = roomNumber;
                            newRoomData.privateRoom = privateRoom;

                            const newGameConfig = HoverboardGameConfig.fromServerJSONString(error.message);
                            if (!await common.menu.changeGameConfig(newRoomData, newGameConfig, false)) {
                                return;
                            }

                            roomOptions.gameConfiguration = newGameConfig;
                        }
                    } else {
                        console.warn("Failed to create room with id", roomNumber);
                    }
                }

                if (!created && !joined) {

                    console.warn("Try to wait for room creation and join that");
                    // We tried to join but couldn't because the room did not exist
                    // but then we fail to create it, very likely someone was creating it at the same time
                    // Try to join to that room a few times, maybe in between it completes the creation
                    // otherwise just create one
                    let joinAttempts = 10;
                    while (joinAttempts > 0) {
                        joinAttempts--;
                        try {
                            await (new Promise(resolve => setTimeout(resolve, 100)));
                            this.room = await this.client.joinById(roomNumber, roomOptions);
                            searchParams.set("room", this.room.id);
                            joined = true;
                            break;
                        } catch (error) {
                            if (error.code == 4989) {
                                searchParams.set("room", roomNumber);

                                let newRoomData = new RoomData();
                                newRoomData.roomNumber = roomNumber;
                                newRoomData.privateRoom = privateRoom;

                                const newGameConfig = HoverboardGameConfig.fromServerJSONString(error.message);
                                if (!await common.menu.changeGameConfig(newRoomData, newGameConfig, false)) {
                                    return;
                                }

                                roomOptions.gameConfiguration = newGameConfig;
                                break;
                            }
                        }
                    }

                    if (!joined) {
                        roomOptions.failIfIdNotAvailable = false;

                        this.room = await this.client.create(currentGameConfig.mode, roomOptions);
                        searchParams.set("room", this.room.id);
                    }
                }
            }

            // update url with room id
            replaceSearchParams(url, searchParams);

            if (this.room != null) {
                // initialize room
                this.initializeRoom(isCreate, privateRoom);
            }
        } catch (e) {
            console.error("join error", e);

            if (isCreate) {
                MAIN_CHANNEL.emit("room-create-error", e);
            } else {
                MAIN_CHANNEL.emit("room-join-error", e);
            }
        }
    }

    initializeRoom(isCreate, privateRoom) {
        const MAIN_CHANNEL = common.MAIN_CHANNEL;
        MAIN_CHANNEL.emit("room-init-start", this.room);

        currentRoomData.roomNumber = this.room.id;

        this.room.state.listen("privateRoom", (value) => {
            currentRoomData.privateRoom = value;
        });

        this.room.state.listen("lapsAmount", (value) => {
            currentGameConfig.lapsAmount.setValue(value, false);
        });

        this.room.state.listen("tagDuration", (value) => {
            currentGameConfig.tagDuration.setValue(value, false);
        });

        this.room.state.listen("npcsAmount", (value) => {
            currentGameConfig.npcsAmount.setValue(value, false);
        });

        this.room.state.listen("npcsDifficulty", (value) => {
            currentGameConfig.npcsDifficulty.setValue(value, false);
        });

        this.room.state.listen("track", (value) => {
            if (currentGameConfig.track === value) return;
            const newGameConfig = new HoverboardGameConfig();
            newGameConfig.copyFromIdentifier(currentGameConfig);
            newGameConfig.track = value;
            common.menu.changeGameConfig(currentRoomData, newGameConfig, false);
        });

        this.room.state.listen("npcsSeed", (value) => {
            this.npcSeed = value;
        });

        this.room.state.listen("roundState", (value) => {
            common.arePlayersRacing =
                value === HOVERBOARD_MULTIPLAYER_STATES.RACE_STARTED ||
                value === HOVERBOARD_MULTIPLAYER_STATES.RACE_ENDED;
        });

        const readinessIndicator = common.readinessIndicator;
        readinessIndicator.onJoinRoom();

        this.enablePlayerJoinedTimer.start();

        common.networkSync.resetSync();

        try {
            this.room.state.players.onAdd((player, key) => {
                if (key !== this.room.sessionId) {
                    const playerObject = common.networkPlayerPool.getEntity();
                    this.otherPlayers.set(key, player);
                    this.otherPlayerObjects.set(key, playerObject);
                    const networkPlayerComponent = playerObject.getComponent(NetworkPlayerComponent);

                    networkPlayerComponent.setSessionId(key);
                    networkPlayerComponent.setEnabled(true);
                    this.syncNetworkPlayerState(player, networkPlayerComponent);

                    // add player to VoIP helper
                    this.voip.addOtherPeer(key);

                    const hoverboardObject = networkPlayerComponent.hoverboardComponent.getHoverboardMeshObject();
                    player.listen("hoverboardVariant", (board) => {
                        common.hoverboardSelector.setHoverboard(board, hoverboardObject, false, false);
                    });
                    common.hoverboardSelector.setHoverboard(player.hoverboardVariant, hoverboardObject, false, false);

                    const avatar = playerObject.pp_getComponent(AvatarComponent);
                    const avatarFaceComponent = avatar.addFaceComponent();
                    networkPlayerComponent.setFaceComponent(avatarFaceComponent);

                    player.listen("avatarType", (type) => {
                        common.avatarSelector.setAvatarType(type, avatar);
                    });
                    common.avatarSelector.setAvatarType(player.avatarType, avatar);
                    player.listen("skinColor", (color) => {
                        common.avatarSelector.setAvatarSkinColor(color, avatar);
                    });
                    common.avatarSelector.setAvatarSkinColor(player.skinColor, avatar);
                    player.listen("suitVariant", (suit) => {
                        common.avatarSelector.setAvatarSuit(suit, avatar);
                    });
                    common.avatarSelector.setAvatarSuit(player.suitVariant, avatar);
                    player.listen("headwearVariant", (headwear) => {
                        common.avatarSelector.setAvatarHeadwear(headwear, avatar);
                    });
                    common.avatarSelector.setAvatarHeadwear(player.headwearVariant, avatar);

                    player.listen("hairColorIndex", (hairColorIndex) => {
                        common.avatarSelector.setAvatarHeadwear(hairColorIndex, avatar);
                    });
                    common.avatarSelector.setAvatarHeadwear(player.hairColorIndex, avatar);

                    player.listen("name", (name) => {
                        networkPlayerComponent.setName(name);
                    });
                    networkPlayerComponent.setName(player.name != null ? player.name : "Unnamed");

                    player.listen("isRacing", (isRacing) => {
                        networkPlayerComponent.setRacing(isRacing);
                        networkPlayerComponent.hoverboardComponent.setRacing(isRacing);
                    });
                    networkPlayerComponent.setRacing(player.isRacing);
                    networkPlayerComponent.hoverboardComponent.setRacing(player.isRacing);

                    player.listen("onTrack", (onTrack) => {
                        networkPlayerComponent.setOnTrack(onTrack);
                    });
                    networkPlayerComponent.setOnTrack(player.onTrack);

                    player.listen("lapsAmount", (lapsAmount) => {
                        networkPlayerComponent.setLapsAmount(lapsAmount);

                        if (common.balcony.isPlayerOnBalcony && common.arePlayersRacing) {
                            if (lapsAmount == currentGameConfig.lapsAmount.value - 1) {
                                common.tracksManager.getRaceManager().prepareFinishLine(false);
                            }
                        }
                    });

                    for (const synchedObject of synchedObjects) {
                        const playerSynchedObject = player[synchedObject];
                        for (const key of KEYS) {
                            const idx = KEY_TO_INDEX[key];
                            playerSynchedObject.listen(key, (newValue, _oldValue) => {
                                networkPlayerComponent.setTransformIndex(synchedObject, idx, newValue);
                            });
                        }
                    }

                    for (const propertyName of Object.keys(player.hoverboardData)) {
                        player.hoverboardData.listen(propertyName, (newValue, _oldValue) => {
                            networkPlayerComponent.setHoverboardDataPropertyValue(propertyName, newValue);
                        });
                    }

                    // TODO networking should not depend on UI, UI should depend
                    //      on networking; do an event system
                    if (this.enablePlayerJoinedTimer.isDone()) {
                        common.popupManager.showQuickMessagePopup(player.name + " joined the room", PopupIconImage.Info);
                    }

                    common.MAIN_CHANNEL.emit("room-player-join", [key, player]);
                } else {
                    // Own player
                    this.localPlayer = player;
                    this.localPlayerKey = key;

                    player.listen("name", (name) => {
                        // XXX only set if different so that name doesn't stop
                        // being a default name if it's one
                        if (currentPlayerData.name !== name) {
                            currentPlayerData.name = name;
                        }
                    });

                    // We need a different event from the tagged listen, because that can also change during TAG setup and not
                    // just when the race is going
                    this.room.onMessage("event-player-tagged", () => {
                        this.hoverboard.playerTagged();
                    });
                }

                // All players

                player.listen("onTrack", (onTrack) => {
                    if (!common.arePlayersRacing) {
                        let playersOnTrack = this.getPlayersOnTrack().length;

                        common.menu.returnAllNPCs();

                        if (playersOnTrack > 0) {
                            common.menu.setupNPCs(true);

                            if (currentGameConfig.mode == GameMode.Race) {
                                const countdown = common.countdown;
                                if (!countdown.visible) {
                                    if (countdown.isUsingFixedPosition()) {
                                        countdown.resetToFixedPosition();
                                        countdown.setVisible(true);
                                        countdown.resetCountdown();
                                    }
                                }
                            }
                        } else {
                            const countdown = common.countdown;
                            countdown.setVisible(false);
                            countdown.resetCountdown();
                        }
                    }
                });

                player.listen("startingPosition", () => {
                    if (!common.arePlayersRacing) {
                        let playersOnTrack = this.getPlayersOnTrack().length;

                        common.menu.returnAllNPCs();

                        if (playersOnTrack > 0) {
                            common.menu.setupNPCs(true);
                        }
                    }
                });

                player.listen("tagged", (tagged) => {
                    const circularMap = common.circularMap;
                    circularMap.setIndicatorTagged(key, tagged);

                    if (key === this.localPlayerKey) {
                        circularMap.setMapTagged(tagged);
                    } else {
                        this.otherPlayerObjects.get(key).getComponent(NetworkPlayerComponent).setTagged(tagged);
                    }
                });

                player.listen("state", (value) => {
                    if (value === HOVERBOARD_PLAYER_MULTIPLAYER_STATES.RACE_RACETRACK_READY || value === HOVERBOARD_PLAYER_MULTIPLAYER_STATES.RACE_RACETRACK_UNREADY) {
                        readinessIndicator.onPlayerJoinRace(player.startingPosition);
                        readinessIndicator.setIndexReadiness(player.startingPosition, value === HOVERBOARD_PLAYER_MULTIPLAYER_STATES.RACE_RACETRACK_READY);

                        if (key === this.localPlayerKey) {
                            common.menu.setStartButtonReady(value !== HOVERBOARD_PLAYER_MULTIPLAYER_STATES.RACE_RACETRACK_READY);
                        }
                    }
                });
            });

            this.room.state.players.onRemove((player, key) => {
                if (this.room != null && key !== this.room.sessionId) {
                    // player disconnected. remove corresponding peer from voip
                    // helper
                    this.voip.removeOtherPeer(key);

                    const circularMap = common.circularMap;
                    if (circularMap) {
                        circularMap.hidePlayerOnMap(key);
                    }

                    const playerObject = this.otherPlayerObjects.get(key);
                    if (player.onTrack) {
                        readinessIndicator.onPlayerLeaveRace(player.startingPosition);
                    }

                    common.networkPlayerPool.returnEntity(playerObject);

                    this.otherPlayers.delete(key);
                    this.otherPlayerObjects.delete(key);

                    if (!common.arePlayersRacing) {
                        common.menu.returnAllNPCs();

                        let playersOnTrack = this.getPlayersOnTrack().length;
                        if (playersOnTrack > 0) {
                            common.menu.setupNPCs(true);
                        } else {
                            const countdown = common.countdown;
                            countdown.setVisible(false);
                            countdown.resetCountdown();
                        }
                    }

                    common.MAIN_CHANNEL.emit("room-player-leave", key);
                }
            });

            // TODO: Segregate by game mode
            // Gameplay messaging
            this.room.onMessage("move-to-track", ({ player }) => {
                common.menu.moveToTrack(false, player.startingPosition);

                if (currentGameConfig.mode != GameMode.Roam) {
                    readinessIndicator.onPlayerJoinRace(player.startingPosition);
                    readinessIndicator.setIndexReadiness(player.startingPosition, player.state === HOVERBOARD_PLAYER_MULTIPLAYER_STATES.RACE_RACETRACK_READY);
                }
            });

            this.room.onMessage("return-to-balcony", ({ player: _unusedPlayer, previousStartingPosition }) => {
                common.menu.returnToBalcony(false);

                if (currentGameConfig.mode != GameMode.Roam) {
                    readinessIndicator.onPlayerLeaveRace(previousStartingPosition);
                }

                if (common.arePlayersRacing) {
                    if (currentGameConfig.mode == GameMode.Race) {
                        common.tracksManager.getRaceManager().showFinishGoal();
                    }
                }
            });

            this.room.onMessage("start-round", ({ roundDuration, players, countdownEndServerTimeStamp }) => {
                setNPCCountdownEndTimestamp(countdownEndServerTimeStamp);
                this.roundStart(players);

                common.leaderboard.clearOnNextAddEntry();

                let numberOfRacers = 0;
                for (let playerID in players) {
                    let player = players[playerID];

                    if (player.state === HOVERBOARD_PLAYER_MULTIPLAYER_STATES.RACE_STARTED) {
                        numberOfRacers++;
                    }
                }

                common.menu.startRace(false, numberOfRacers, roundDuration);

                common.arePlayersRacing = true;
            });

            this.room.onMessage("round-cancelled", ({ message }) => {
                common.popupManager.showQuickMessagePopup(message, PopupIconImage.Info);
            });

            this.room.onMessage("restart-round", () => {
                common.arePlayersRacing = false;

                common.menu.finishNPCsRace();
                common.menu.returnAllNPCs();

                const balconyMusicAudio = common.audioManager.getAudio(AudioID.BALCONY_MUSIC);
                balconyMusicAudio.fade(0, balconyMusicAudio.getDefaultVolume(), 0.8);

                const raceMusicAudio = common.audioManager.getAudio(AudioID.TRACK_MUSIC);
                // #TODO Fade yet not implemented
                // raceMusicAudio.fade(raceMusicAudio.getDefaultVolume(), 0.0, 0.8);
                raceMusicAudio.stop();
                common.motivationalAudio.stopMotivational();

                const countdown = common.countdown;
                countdown.setVisible(false);
                countdown.resetCountdown();

                common.tracksManager.getRaceManager().setRaceElementsEnabled(currentGameConfig.mode == GameMode.Race);

                common.timer.stopTimer();
            });


            this.room.onMessage("confirm-race-finished", () => {
                if (!common.balcony.isPlayerOnBalcony) {
                    common.CURRENT_STATE = GAME_STATES.POST_ENDGAME;
                    MAIN_CHANNEL.emit("room-race-completed");
                }
            });

            this.room.onMessage("event-finish-tag", ({ chasersWin, chasersCatches, evadersSurvived }) => {
                if (!common.balcony.isPlayerOnBalcony) {
                    common.timer.stopTimer();

                    const tagResultsBoardData = new TagResultBoardData();

                    tagResultsBoardData.chasersWin = chasersWin;

                    tagResultsBoardData.chasersCatches = chasersCatches;
                    tagResultsBoardData.evadersSurvived = evadersSurvived;

                    if (!chasersWin) {
                        tagResultsBoardData.totalSeconds = common.timer.duration;
                    } else {
                        tagResultsBoardData.totalSeconds = common.timer.duration - common.timer.time;
                    }

                    tagResultsBoardData.fitPoints = common.hoverboard.fitPoints;

                    const trackStatistics = common.tracksManager.getTrackStatistics();
                    tagResultsBoardData.maxSpeed = trackStatistics.maxSpeed;
                    tagResultsBoardData.squatsAmount = trackStatistics.squatsAmount;

                    const tagResultsBoard = common.tracksManager.getTagResultsBoard();
                    tagResultsBoard.updateBoardData(tagResultsBoardData);
                    tagResultsBoard.setVisible(true);

                    common.CURRENT_STATE = GAME_STATES.POST_ENDGAME;

                    AnalyticsUtils.sendEvent("tag_completed");

                    MAIN_CHANNEL.emit("room-tag-completed");
                }
            });

            this.room.onMessage("race-finished-confirmed", () => {
                this.resetPlayer();
            });

            this.room.onMessage("player-join-race", ({ message, player }) => {
                common.popupManager.showQuickMessagePopup(message, PopupIconImage.Info);

                if (currentGameConfig.mode != GameMode.Roam) {
                    readinessIndicator.onPlayerJoinRace(player.startingPosition);
                    readinessIndicator.setIndexReadiness(player.startingPosition, player.state === HOVERBOARD_PLAYER_MULTIPLAYER_STATES.RACE_RACETRACK_READY);
                }
            });

            this.room.onMessage("player-leave-race", ({ message, player: _unusedPlayer, previousStartingPosition }) => {
                common.popupManager.showQuickMessagePopup(message, PopupIconImage.Info);

                if (currentGameConfig.mode != GameMode.Roam) {
                    readinessIndicator.onPlayerLeaveRace(previousStartingPosition);
                }
            });

            this.room.onMessage("event-finish-race", ({ message, raceOver, sessionId, player, finishTime, bestLapTime }) => {
                if (sessionId != this.localPlayerKey) {
                    common.popupManager.showQuickMessagePopup(message + (raceOver ? "\n The race has ended" : ""), PopupIconImage.Info);
                } else {
                    if (raceOver) {
                        common.popupManager.showQuickMessagePopup("\n The race has ended", PopupIconImage.Info);
                    }

                    common.leaderboard.submitToHeyVR(finishTime, bestLapTime);
                }

                common.leaderboard.addExternalData({ name: player.name, finishTime: finishTime }); // Manually setting the data to achieve room local leaderboard
                common.leaderboard.displayLeaderboard();
            });

            this.room.onMessage("event-quit-race", ({ message }) => {
                common.popupManager.showQuickMessagePopup(message, PopupIconImage.Info);
            });

            this.room.onMessage("event-player-leave", ({ message }) => {
                common.popupManager.showQuickMessagePopup(message, PopupIconImage.Info);
            });

            this.room.onMessage("display-tag-message", ({ message }) => {
                common.popupManager.showMessagePopup(message, PopupIconImage.Info);
            });

            this.room.onMessage("display-info", ({ message }) => {
                common.popupManager.showQuickMessagePopup(message, PopupIconImage.Info);
            });

            this.room.onMessage("display-warn", ({ message }) => {
                common.popupManager.showQuickMessagePopup(message, PopupIconImage.Warn);
            });

            this.room.onMessage("display-error", ({ message }) => {
                common.popupManager.showQuickMessagePopup(message, PopupIconImage.Error);
            });

            // Voip messaging
            this.room.onMessage("p2p-toggled", ({ nonce, p2pPriority }) => {
                this.voip.p2pToggled(nonce, p2pPriority);
            });
            this.room.onMessage("p2p-toggle-denied", ({ nonce }) => {
                this.voip.p2pToggleDenied(nonce);
            });
            this.room.onMessage("other-p2p-toggled", ({ sessionId, p2pPriority }) => {
                this.voip.setP2PPriorityOf(sessionId, p2pPriority);
            });
            this.room.onMessage("ice-candidate", ({ sessionId, data }) => {
                this.voip.receiveIceCandidate(sessionId, data);
            });
            this.room.onMessage("session-description", ({ sessionId, data }) => {
                this.voip.receiveSessionDescription(sessionId, data);
            });
            this.room.onMessage("mediasoup-ticket", ({ ticket, nonce }) => {
                this.voip.receiveMediasoupTicket(nonce, ticket);
            });
            this.room.onMessage("mediasoup-ticket-denied", ({ nonce }) => {
                this.voip.mediasoupTicketDenied(nonce);
            });

            this.room.onMessage("change-game-config", (value) => {
                let newRoomData = new RoomData(currentRoomData);
                const newGameConfig = new HoverboardGameConfig();
                newGameConfig.copyFromIdentifier(value.newGameConfig);
                common.menu.changeGameConfig(newRoomData, newGameConfig, false);
            });

            // VoIP command handling/handshake relaying
            // XXX onLeave overrides `this` despite using a lambda, so store it
            const disposeCallback = this.disposeRoom.bind(this);
            this.room.onLeave((code) => {
                disposeCallback();
                MAIN_CHANNEL.emit("room-leave", code);
            });

            this.room.onError((error) => {
                disposeCallback();
                MAIN_CHANNEL.emit("room-error", error);
            });

            this.voip.on("toggle-p2p", (nonce, enabled) => {
                this.updateVoIPWarnings();
                this.room.send("toggle-p2p", { enabled, nonce });
            });
            this.voip.on("ice-candidate", (sessionId, iceCandidate) => {
                this.room.send("ice-candidate", { sessionId, iceCandidate });
            });
            this.voip.on("session-description", (sessionId, sessionDescription) => {
                this.room.send("session-description", { sessionId, sessionDescription });
            });
            this.voip.on("p2p-enabled", () => this.updateVoIPWarnings());
            this.voip.on("p2p-disabled", () => this.updateVoIPWarnings());
            this.voip.p2pSupported = true;

            this.voip.on("get-mediasoup-ticket", (nonce) => {
                this.updateVoIPWarnings();
                this.room.send("get-mediasoup-ticket", { nonce });
            });
            this.voip.on("mediasoup-enabled", () => this.updateVoIPWarnings());
            this.voip.on("mediasoup-disabled", () => this.updateVoIPWarnings());
            this.voip.mediasoupSupported = true;

            this.voip.on("peer-added", (otherPeer) => {
                otherPeer.on("consumption-mode-changed", () => this.updateVoIPWarnings());

                this.updateVoIPWarnings();
            });

            // TODO in the future, when there are public rooms, don't
            // auto-toggle P2P when a public room is joined to prevent IP
            // leakage
            let hadVoIP = false;
            if (GLOBAL_PREFS.getPref(P2P_PREF_KEY, P2P_PREF_DEFAULT)) {
                hadVoIP = true;
                this.voip.toggleP2P(true);
            }
            if (GLOBAL_PREFS.getPref(MS_PREF_KEY, MS_PREF_DEFAULT)) {
                hadVoIP = true;
                this.voip.toggleMediasoup(true);
            }

            if (hadVoIP) {
                this.voip.requestMic();
            }

            this.resetPlayer();
            this.updateVoIPWarnings();

            MAIN_CHANNEL.emit("room-init-done", isCreate, privateRoom);
        } catch (e) {
            console.error("Initialization Error: ", e);
            this.disposeRoom();
            MAIN_CHANNEL.emit("room-init-error", e);
        }
    }

    updateVoIPWarnings() {
        let p2pBad = false;
        let p2pUnavailable = false;
        let mediasoupUnavailable = false;

        if (this.room) {
            const p2pWanted = GLOBAL_PREFS.getPref(P2P_PREF_KEY, P2P_PREF_DEFAULT);
            if (p2pWanted !== this.voip.p2pEnabled) {
                p2pUnavailable = true;
            }
            if (GLOBAL_PREFS.getPref(MS_PREF_KEY, MS_PREF_DEFAULT) !== this.voip.mediasoupEnabled) {
                mediasoupUnavailable = true;
            }

            if (!p2pUnavailable && p2pWanted) {
                for (const peer of this.voip.otherPeers.values()) {
                    if (peer.hasP2P && !peer.consumingP2P) {
                        p2pBad = true;
                        break;
                    }
                }
            }
        }

        this.p2pBadWarning.setShow(p2pBad);
        this.p2pUnavailableWarning.setShow(p2pUnavailable);
        this.mediasoupUnavailableWarning.setShow(mediasoupUnavailable);
    }

    syncNetworkPlayerState(player, networkPlayerComponent) {
        for (const synchedObject of synchedObjects) {
            for (const key of KEYS) {
                const value = player[synchedObject][key];
                if (value) networkPlayerComponent.setTransform(synchedObject, key, value);
            }
        }

        for (const propertyName of Object.keys(player.hoverboardData)) {
            networkPlayerComponent.setHoverboardDataPropertyValue(propertyName, player.hoverboardData[propertyName]);
        }
    }

    disposeRoom() {
        // remove room id from url (unless it's a scene load)
        if (!isAnotherSceneLoading) {
            const url = new URL(window.location);
            const searchParams = url.searchParams;
            searchParams.delete("room");
            replaceSearchParams(url, searchParams);

            currentRoomData.roomNumber = null;
            currentRoomData.privateRoom = false;

            if (currentPlayerData.isGuest) {
                currentPlayerData.name = null;
            }
        }

        this.voip.reset(true);

        // Cleanup other players
        for (const playerObject of this.otherPlayerObjects.values()) {
            common.networkPlayerPool?.returnEntity(playerObject);
        }

        common.circularMap?.onLeaveRoom();
        common.readinessIndicator?.onLeaveRoom();

        this.otherPlayers.clear();
        this.otherPlayerObjects.clear();

        this.resetPlayer();

        const countdown = common.countdown;
        countdown.setVisible(false);
        countdown.resetCountdown();

        if (this.room) {
            this.room.leave();
            this.room.connection.close();
            this.room.removeAllListeners();
            this.room = null;
        }

        this.updateVoIPWarnings();

        // #TODO maybe we should also find a way to remove all this.room listener manually, since a callback could be called
        // in the meantime, but the data will not be valid anymore (actually happened when disconnecting while scene loading)
    }

    setRoundReady(value) {
        if (this.room)
            this.room.send("set-round-ready", { value });
    }

    moveToTrack() {
        if (this.room) {
            this.room.send("move-to-track");
        }
    }

    returnToBalcony() {
        if (this.room) {
            this.room.send("return-to-balcony");
        }
    }

    raceFinished() {
        if (this.room) {
            this.room.send("set-race-finished", { finishTime: common.menu.finishTime, bestLapTime: common.menu.bestLapTime });
        }
    }

    incrementLaps() {
        if (this.room) {
            this.room.send("increment-laps");
        }
    }

    changeGameConfig(newGameConfig) {
        if (this.room) {
            this.room.send("change-game-config", {
                location: newGameConfig.location,
                mode: newGameConfig.mode,
                track: newGameConfig.track,
            });
        }
    }

    updateLapsAmount(newLapsAmount) {
        if (this.room) {
            this.room.send("update-laps-amount", { newLapsAmount });
        }
    }

    updateTagDuration(newTagDuration) {
        if (this.room) {
            this.room.send("update-tag-duration", { newTagDuration });
        }
    }

    updateNPCsAmount(newNPCsAmount) {
        if (this.room) {
            this.room.send("update-npcs-amount", { newNPCsAmount });
        }
    }

    updateNPCsDifficulty(newNPCsDifficulty) {
        if (this.room) {
            this.room.send("update-npcs-difficulty", { newNPCsDifficulty });
        }
    }

    setTrack(track) {
        if (this.room) {
            this.room.send("change-game-config", {
                location: currentGameConfig.location,
                mode: currentGameConfig.mode,
                track,
            });
        }
    }

    update(dt) {
        if (this.initNetworkTimer.isRunning()) {
            this.initNetworkTimer.update(dt);
            if (this.initNetworkTimer.isDone()) {
                this._start();
            }
        }

        if (this.room) {
            if (this.voip.spatialAudio) {
                this.voip.updateSpatialPos();
            }
        }

        if (this.room) {
            this.enablePlayerJoinedTimer.update(dt);
        }

        if (Globals.isDebugEnabled()) {
            if (Globals.getRightGamepad().getButtonInfo(GamepadButtonID.SQUEEZE).isPressEnd(2)) {
                if (currentGameConfig.mode == GameMode.Tag) {
                    if (this.room) {
                        if (this.localPlayer.state == HOVERBOARD_PLAYER_MULTIPLAYER_STATES.RACE_STARTED && !this.localPlayer.tagged) {
                            let randomChaser = null;
                            let otherPlayerObjects = Array.from(this.room.state.players.entries());
                            while (randomChaser == null) {
                                let randomOtherPlayer = Math.pp_randomPick(otherPlayerObjects);
                                if (randomOtherPlayer[1].state == HOVERBOARD_PLAYER_MULTIPLAYER_STATES.RACE_STARTED && randomOtherPlayer[1].tagged) {
                                    randomChaser = randomOtherPlayer;
                                }
                            }

                            this.room.send("player-tagged", { chaserSessionId: randomChaser[0], evaderSessionId: this.localPlayerKey });
                        }
                    }
                }
            }
        }
    }

    roundStart(players) {
        let localPlayer = null;

        for (let playerID in players) {
            if (playerID == this.localPlayerKey) {
                localPlayer = players[playerID];
            }
        }

        if (currentGameConfig.mode == GameMode.Tag) {
            if (localPlayer.state === HOVERBOARD_PLAYER_MULTIPLAYER_STATES.RACE_STARTED) {
                const instructionPopupParams = new MessagePopupParams();
                instructionPopupParams.durationSeconds = 6;
                instructionPopupParams.showSeconds = 1;
                instructionPopupParams.hideSeconds = 1;
                instructionPopupParams.priorityParams.expireSeconds = Infinity;
                instructionPopupParams.priorityParams.priorityLevel = PriorityLevel.VeryHigh;
                instructionPopupParams.popupWindowParams.popupIconImage = PopupIconImage.Rocket;

                if (localPlayer.tagged) {
                    common.audioManager.getAudio(AudioID.CHASER_READY).play();
                    instructionPopupParams.popupWindowParams.message = "Catch the Evaders!";
                } else {
                    common.audioManager.getAudio(AudioID.EVADER_READY).play();
                    instructionPopupParams.popupWindowParams.message = "Avoid the Chasers!";
                }

                common.popupManager.showPopup(instructionPopupParams);
            }

            for (let playerID in players) {
                let player = players[playerID];

                if (player.state === HOVERBOARD_PLAYER_MULTIPLAYER_STATES.RACE_STARTED) {
                    const circularMap = common.circularMap;
                    circularMap.setIndicatorTagged(playerID, player.tagged);

                    if (player == localPlayer) {
                        circularMap.setMapTagged(player.tagged);
                    } else {
                        let networkPlayerComponent = this.otherPlayerObjects.get(playerID).getComponent(NetworkPlayerComponent);
                        networkPlayerComponent.setTagged(player.tagged);
                    }
                }
            }
        }
    }

    tagPlayer(evaderSessionId) {
        common.hoverboardNetworking.room.send("player-tagged", { chaserSessionId: this.localPlayerKey, evaderSessionId: evaderSessionId });
    }

    getNetworkPlayersObjects() {
        return this.otherPlayerObjects;
    }

    onDestroy() {
        GLOBAL_PREFS.off("pref-set", this.prefSetListener);
    }

    clearNPCReferences() {
        let npcsKeys = [];
        for (let otherPlayerObjectPair of this.otherPlayerObjects.entries()) {
            if (otherPlayerObjectPair[1].pp_getComponent(NetworkPlayerComponent).isNPC) {
                npcsKeys.push(otherPlayerObjectPair[0]);
            }
        }

        for (let npcKey of npcsKeys) {
            this.otherPlayerObjects.delete(npcKey);
        }
    }

    setupNPCReferences(index, object) {
        this.otherPlayerObjects.set(index, object);
    }

    getPlayers(includeCurrentPlayer = true) {
        let players = [];

        for (let playerPair of this.room.state.players.entries()) {
            if (includeCurrentPlayer || playerPair[0] != this.localPlayerKey) {
                players.push(playerPair[1]);
            }
        }

        return players;
    }

    getPlayersOnTrack(includeCurrentPlayer = true) {
        let playersOnTrack = [];

        for (let playerPair of this.room.state.players.entries()) {
            if (playerPair[1].onTrack && (includeCurrentPlayer || playerPair[0] != this.localPlayerKey)) {
                playersOnTrack.push(playerPair[1]);
            }
        }

        return playersOnTrack;
    }

    togglePeerMute(key) {
        const peer = this.voip.getOtherPeer(key);
        if (!peer) return;
        peer.audioSource.muted = !peer.audioSource.muted;
    }

    isPeerMuted(key) {
        const peer = this.voip.getOtherPeer(key);
        if (!peer) return true;
        return peer.audioSource.muted;
    }
}
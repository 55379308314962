import { Object3D } from "@wonderlandengine/api";
import { AnalyticsUtils, Globals, ObjectUtils } from "wle-pp";
import { AvatarComponent } from "../avatar/components/avatar-component.js";
import common from "../common.js";
import { HoverboardComponent } from "../game/hoverboard/components/hoverboard-component.js";
import { getRandomName } from "../misc/get-random-name.js";
import { getRandomBoard } from "./values/board.js";
import { Gender, getRandomGender } from "./values/gender.js";
import { getRandomHairColor, HairColor } from "./values/headwear.js";
import { getRandomSkinColor, SkinColor } from "./values/skin-color.js";
import { getRandomSuitColor } from "./values/suit-color.js";

export class PlayerData {
    private _initialized: boolean = false;

    private _player: Object3D | null = null;
    private _avatar: AvatarComponent | null = null;
    private _hoverboard: HoverboardComponent | null = null;
    private _name: string | null = null;
    private _defaultName: string | null = null;
    private _avatarType: Gender;
    skinColor: SkinColor;
    hoverboardVariant: string;
    suitVariant: string;
    hairColor: HairColor;
    headwearVariant: string;
    private _isGuest = true;
    private listeners = new Set<() => void>();
    totalFitPoints = 0;

    constructor(randomize = false) {
        this._avatarType = randomize ? getRandomGender() : 0;
        this.skinColor = randomize ? getRandomSkinColor() : 0;
        this.hoverboardVariant = randomize ? getRandomBoard() : "0";
        this.suitVariant = randomize ? getRandomSuitColor() : "0";
        this.headwearVariant = randomize ? getRandomHairColor() : "0";
        this.hairColor = randomize ? Number(getRandomHairColor()) : 0;
    }

    init() {
        if (this._initialized) return;

        this.loadPlayerName().then((loadSucceeded) => {
            if (!loadSucceeded) {
                const searchParams = new URLSearchParams(window.location.search);
                const desiredName = searchParams.get("name");
                if (desiredName != null && desiredName.length > 0) {
                    currentPlayerData.name = desiredName;
                    currentPlayerData.isGuest = false;
                }
            }
        });

        this.loadPlayerData().then((loadSucceeded) => {
            if (loadSucceeded) common.menu?.updateAvatarConfig();
        });

        this._initialized = true;
    }

    invalidateSceneData() {
        this._player = null;
        this._avatar = null;
        this._hoverboard = null;
    }

    get player(): Object3D {
        if (!this._player) this._player = Globals.getPlayerObjects()!.myPlayer!;
        return this._player;
    }

    get avatar() {
        if (!this._avatar) this._avatar = ObjectUtils.getComponent(this.player, AvatarComponent);
        return this._avatar;
    }

    get hoverboard() {
        if (!this._hoverboard) this._hoverboard = ObjectUtils.getComponent(this.player, HoverboardComponent);
        return this._hoverboard;
    }

    isUsingDefaultName() {
        return this._name == null;
    }

    get avatarType() {
        return this._avatarType;
    }

    set avatarType(avatarType) {
        if (this._avatarType !== avatarType) {
            this._avatarType = avatarType;
            this._defaultName = getRandomName(this);
            if (this.isUsingDefaultName()) {
                this.notify();
            }
        }
    }

    get defaultName() {
        if (!this._defaultName) {
            this._defaultName = getRandomName(this) as string;
        }

        return this._defaultName;
    }

    get name() {
        return this._name != null ? this._name : this.defaultName;
    }

    // TODO i don't like that name can be null, but the getter always returns a
    //      string. this is going to cause a bug eventually
    set name(name: string | null) {
        if (this._name !== name) {
            this._name = name;
            this.notify();
        }
    }

    get isGuest() {
        return this._isGuest;
    }

    set isGuest(isGuest) {
        if (this._isGuest !== isGuest) {
            this._isGuest = isGuest;
            this.notify();
        }
    }

    notify() {
        for (const listener of this.listeners) {
            listener();
        }
    }

    listen(callback: () => void) {
        this.listeners.add(callback);
    }

    unlisten(callback: () => void) {
        this.listeners.delete(callback);
    }

    async savePlayerData(slot = 1) {
        const dataToSave = {
            avatarType: currentPlayerData.avatarType,
            hoverboardVariant: currentPlayerData.hoverboardVariant,
            /* add height when implemented */
            //   height: currentPlayerData.height,
        };

        try {
            const result = await window.heyVR?.saveGame.write(dataToSave, true, slot, `Player Save ${slot}`);
            if (!result) return;
            console.log(`Saved to slot ${slot}`);
            this.loadPlayerData();

        } catch (error) {
            console.error("Error saving data:", error);
        }
    }

    async loadPlayerName() {
        if (window.heyVR == null) return false;

        let loadSucceeded = false;

        try {
            if (await window.heyVR.user.isLoggedIn()) {
                const username = await window.heyVR.user.getName();

                currentPlayerData.name = username;
                currentPlayerData.isGuest = false;

                loadSucceeded = true;

                AnalyticsUtils.sendEventOnce("logged_in");
            }
        } catch (error) {
            console.error("Error loading name:", error);
        }

        return loadSucceeded;
    }

    async loadPlayerData(slot = 1) {
        if (window.heyVR == null) return false;

        let loadSucceeded = false;
        try {
            const loadedData = await window.heyVR.saveGame.load(slot);

            if (loadedData && loadedData.save_data) {
                if (loadedData.save_data.avatarType != null) {
                    currentPlayerData.avatarType = loadedData.save_data.avatarType;
                }

                if (loadedData.save_data.hoverboardVariant != null) {
                    currentPlayerData.hoverboardVariant = loadedData.save_data.hoverboardVariant;
                }

                loadSucceeded = true;
            } else {
                console.error(`No data in slot ${slot}`);
            }
        } catch (error) {
            console.error("Error loading data:", error);
        }

        return loadSucceeded;
    }
}

// XXX persistent, don't put in the common object
export const currentPlayerData = new PlayerData(true);
import { ArtificialConstraint, Background, Container, Label, RoundedCorners, ScrollableViewportWidget, TextAlignMode, type WidgetAutoXML, type WidgetProperties } from "lazy-widgets";

export class KioskShopPopup extends ScrollableViewportWidget<ArtificialConstraint> {
    static override autoXML: WidgetAutoXML = {
        name: "kiosk-shop-popup",
        inputConfig: []
    };

    constructor(properties?: Readonly<WidgetProperties>) {
        super(
            new ArtificialConstraint(
                new RoundedCorners(
                    new Background(
                        new Container(
                            new Label("Test",
                                {
                                    bodyTextFill: "black",
                                    bodyTextFont: "0.9em sui-generis",
                                    bodyTextAlign: TextAlignMode.Center,
                                    bodyTextSpacing: 0,
                                })
                        ),
                        {
                            canvasFill: "rgb(148, 197, 255)",
                        },
                    ),
                    {
                        roundedCornersRadii: 3,
                    },
                ),
                [250, 250, 150, 150],
            )
        );
    }
}
import { Component, MeshComponent, Property } from "@wonderlandengine/api";
import { Vec4Utils, vec3_create, vec4_create } from "wle-pp";
import { AudioID } from "../../../audio/audio-manager/audio-id.js";
import common from "../../../common.js";
import { GameGlobals } from "../../../misc/game-globals.js";


export class HoverboardWindEffectComponent extends Component {
    static TypeName = "hoverboard-wind-effect";
    /* Properties that are configurable in the editor */
    static Properties = {
        runningWindEffectSpeed: Property.float(1.0),
        effectActivationThreshold: Property.float(0.25)
    };

    start() {
        this.originalScale = vec3_create();
        this.object.pp_getScaleLocal(this.originalScale);
        this.shrinkScale = vec3_create(this.originalScale[0] * 0.5, this.originalScale[1] * 0.5, 1.0);

        this.originalWindColor = vec4_create();
        this.goldWindColor = vec4_create(0.9237668514251709, 1.0, 0.0, 1.0);

        this.windMesh = this.object.pp_getComponent(MeshComponent);
        this.windMesh.material = this.object.pp_getComponent(MeshComponent).material.clone();
        Vec4Utils.copy(this.windMesh.material.color, this.originalWindColor);

        this.prevDisplayWind = 0.0;
        this.runningWindTextureScroll = 0.0;

        this.windMesh.object.pp_setActive(false);
    }

    update(dt) {
        if (common.hoverboard.hoverboardStarted) {
            let amountDisplayWind = common.hoverboard.speedPercentage;
            let speed = this.runningWindEffectSpeed;

            const isRampAudioPlaying =
                common.audioManager.getAudio(AudioID.RACE_RAMP_BOOST).isPlaying() ||
                common.audioManager.getAudio(AudioID.PICKUP_ROCKET).isPlaying();

            if (isRampAudioPlaying) {
                amountDisplayWind = 2.0;
                speed *= 1.5;
                this.object.pp_setScaleLocal(this.shrinkScale);
            } else {
                this.object.pp_setScaleLocal(this.originalScale);
            }

            // Deactivate if below threshhold
            if (amountDisplayWind < this.effectActivationThreshold && this.prevDisplayWind >= this.effectActivationThreshold) {
                this.windMesh.object.pp_setActive(false);
            } else if (amountDisplayWind >= this.effectActivationThreshold && this.prevDisplayWind < this.effectActivationThreshold) {
                this.windMesh.object.pp_setActive(true);
                this.object.pp_resetRotationLocal();
                this.object.pp_rotateAxisObject(Math.pp_random(-180, 180), GameGlobals.forward);
            }

            const alpha = (amountDisplayWind - this.effectActivationThreshold) / (1 - this.effectActivationThreshold);

            let color = isRampAudioPlaying ? this.goldWindColor : this.originalWindColor;

            color[3] = alpha;
            this.windMesh.material.color = color;

            this.runningWindTextureScroll += speed * dt * amountDisplayWind;
            if (this.runningWindTextureScroll >= 1.0) {
                this.runningWindTextureScroll -= 1.0;
            }
            this.windMesh.material.yScroll = this.runningWindTextureScroll;

            this.prevDisplayWind = amountDisplayWind;
        } else {
            this.prevDisplayWind = 0.0;
            this.runningWindTextureScroll = 0.0;
            this.windMesh.object.pp_setActive(false);
        }
    }
}

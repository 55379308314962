import { Component, Property } from "@wonderlandengine/api";
import anime from "animejs";
import { quat } from "gl-matrix";
import { GameMode } from "hoverfit-shared-netcode";
import { EasingFunction, GamepadButtonID, Globals, MathUtils, Quat2Utils, Timer, quat_create, vec3_create } from "wle-pp";
import { AVATAR_FORWARD_OFFSET_FROM_HEAD } from "../../../avatar/components/avatar-component.js";
import common from "../../../common.js";
import { currentGameConfig } from "../../../data/game-configuration.js";
import { GameGlobals } from "../../../misc/game-globals.js";
import { NetworkedHoverboardComponent } from "../../../network/components/networked-hoverboard-component.js";
import { PopupIconImage } from "../../../ui/popup/popup.js";
import { seededRandom } from "../../../utils/math-utils.js";
import { getTime } from "../../../utils/time-utils.js";
import { HoverboardDebugs } from "../../components/hoverboard-debugs-component.js";
import { RaceManager } from "../../track/race-manager.js";

export const NPCDifficulty = {
    VeryEasy: "Very Easy",
    Easy: "Easy",
    Medium: "Medium",
    Hard: "Hard",
    VeryHard: "Very Hard"
};

let _countdownEndTimestamp = 0;
let _countdownEnded = false;
export function setNPCCountdownEndTimestamp(countdownEndTimestamp) {
    _countdownEndTimestamp = countdownEndTimestamp;
    _countdownEnded = false;
}

export class NPCControllerComponent extends Component {
    static TypeName = "npc-controller";

    static Properties = {
        movementSpeed: Property.float(30.0),
        movementSpeedDifficultyLevelEffect: Property.float(4.0),
        gravity: Property.float(6.0),
        animRate: Property.float(5.0)
    };

    start() {
        this.localOffset = vec3_create();
        this.startPosition = vec3_create();

        this.networkedHoverboard = this.object.pp_getComponent(NetworkedHoverboardComponent);

        this.name = "";
        this.animSeed = 0;
        this.lapsAmount = -1;
        this.rampHeightOffset = 0;
        this.jumpVerticalAngle = 0;
        this.jumpVerticalSpeed = 0;

        this.currentSpeed = 0;
        this.splineSpeed = 0;

        this.onRamp = false;
        this.jumping = false;

        this.timeToFullSpeed = new Timer(2);
        this.slowDownTime = 3;

        this.jumpCheckStartDelay = new Timer(0.5);
        this.prevPosition = vec3_create();
        this.currPosition = vec3_create();
        this.prevVelocity = vec3_create();

        this.prevForward = vec3_create();
        this.currForward = vec3_create();

        this.initialLocalOffset = vec3_create();

        this.initialPosition = vec3_create();
        this.initialRotationQuat = quat_create();

        this.riseVerticalOffsetFromGround = 0;

        this.index = -1;

        this.animTargetObjects = null;
        this.animTargetInitialTransformQuat = null;

        this.debugSpawnPositionTransformQuat = Quat2Utils.create();

        this._prepAnim();
    }

    getCurrentSplineTime() {
        return this.currentSplineTime;
    }

    setName(name) {
        this.name = name;
        this._generateAnimSeed(name);
    }

    // Speed 0..1
    setSpeedFromSeed(seed) {
        this.extraSpeedMultiplier = seededRandom(seed);
        this.timeToFullSpeed.start(MathUtils.mapToRange(seededRandom(seed), 0, 1, 1.5, 3));
    }

    finishRace() {
        if (this.isRacing) {
            this._raceFinished(true);
        }
    }

    update(dt) {
        // Implemented outside class definition
    }

    onActivate() {
        if (currentGameConfig.mode !== GameMode.Race) {
            this.active = false;
        }
    }

    startRound() {
        this.performRiseAnimation = true;

        this.riseVerticalOffsetFromGround = 0;

        this.riseAnim = anime({
            targets: this,
            easing: "easeOutQuad",
            riseVerticalOffsetFromGround: common.hoverboard.verticalOffsetFromGround - common.hoverboard.minVerticalOffsetFromGround,
            delay: 0,
            duration: 1000,
            autoplay: false,
            update: (anim) => {
                if (this.riseAnim != null) {
                    const spline = common.tracksManager.getCurrentTrack().getSpline();
                    const risedStartPosition = this.startPosition.vec3_add(vec3_create(0, this.riseVerticalOffsetFromGround, 0));
                    risedStartPosition.vec3_convertPositionToLocalQuat(spline.getTransformQuat(this.currentSplineTime), this.localOffset);
                }
            },
            complete: (anim) => {
                const spline = common.tracksManager.getCurrentTrack().getSpline();
                const risedStartPosition = this.startPosition.vec3_add(vec3_create(0, common.hoverboard.verticalOffsetFromGround - common.hoverboard.minVerticalOffsetFromGround, 0));
                risedStartPosition.vec3_convertPositionToLocalQuat(spline.getTransformQuat(this.currentSplineTime), this.localOffset);

                this.riseAnim = null;
                this.performRiseAnimation = false;
            }
        });
    }

    startRacing() {
        this.slowDown = false;
        this.isRacing = true;

        this.lapsAmount = -1;
        this._currentCheckPoint = RaceManager.LAP_CHECK_POINTS - 1;

        this.rampHeightOffset = 0;
        this.jumpVerticalSpeed = 0;
        this.rampSpeedBoost = 0;
        this.onRamp = false;
        this.jumping = false;

        this.animMode = 1;

        this.timeToFullSpeed.start();
    }

    toTrack(spawnPositionIndex) {
        this.index = spawnPositionIndex;

        this.isRacing = false;
        this.slowDown = false;
        this.animMode = 0;

        const spline = common.tracksManager.getCurrentTrack().getSpline();
        const spawnPositionProvider = common.tracksManager.getCurrentTrack().getSpawnPositionProvider();
        this.currentSplineTime = spawnPositionProvider.getSpawnPositionSplineTime();

        let startTransformQuat = null;
        if (common.hoverboardNetworking.room) {
            let busyStartingPositions = [];
            let players = common.hoverboardNetworking.getPlayers();
            for (let player of players) {
                if (player.startingPosition != -1) {
                    busyStartingPositions.pp_pushUnique(player.startingPosition);
                }
            }

            busyStartingPositions.pp_pushUnique(common.hoverboard.spawnPosition);

            busyStartingPositions.sort((a, b) => a - b);

            let freePositionsToSkip = this.index;
            let firstFreePosition = 0;
            let found = false;
            while (!found) {
                if (busyStartingPositions.pp_hasEqual(firstFreePosition)) {
                    firstFreePosition++;
                } else {
                    if (freePositionsToSkip == 0) {
                        found = true;
                    } else {
                        firstFreePosition++;
                        freePositionsToSkip--;
                    }
                }
            }

            startTransformQuat = spawnPositionProvider.getSpawnPosition(firstFreePosition).pp_clone();
        } else {
            startTransformQuat = spawnPositionProvider.getSpawnPosition(this.index + 1).pp_clone();
        }

        this.debugSpawnPositionTransformQuat.quat2_copy(startTransformQuat);
        let debugSpawnPosition = this.debugSpawnPositionTransformQuat.quat2_getPosition().vec3_add(vec3_create(0, common.hoverboard.verticalOffsetFromGround, 0));
        this.debugSpawnPositionTransformQuat.quat2_setPosition(debugSpawnPosition);

        this.startPosition = startTransformQuat.quat2_getPosition().vec3_add(vec3_create(0, common.hoverboard.minVerticalOffsetFromGround, 0));
        startTransformQuat.quat2_setPosition(this.startPosition);

        this.object.pp_setPosition(this.startPosition);
        this.object.pp_setUp(GameGlobals.up, startTransformQuat.quat2_getForward());

        this.object.pp_getPosition(this.initialPosition);
        this.object.pp_getRotationQuat(this.initialRotationQuat);

        this.prevPosition.vec3_copy(this.startPosition);
        this.prevVelocity.vec3_zero();
        this.prevForward.vec3_copy(startTransformQuat.quat2_getForward());
        this.jumpCheckStartDelay.start();

        this.startPosition.vec3_convertPositionToLocalQuat(spline.getTransformQuat(this.currentSplineTime), this.localOffset);
        this.initialLocalOffset.vec3_copy(this.localOffset);

        if (Globals.isDebugEnabled() && HoverboardDebugs.saveSpawnPositionShortcutEnabled && spawnPositionProvider.getDebugSpawnPositionNPC() != null) {
            let debugSpawnPositionQuat = spawnPositionProvider.getDebugSpawnPositionNPC();
            this.currentSplineTime = spline.getClosestTime(debugSpawnPositionQuat.quat2_getPosition());

            this.localOffset.vec3_convertPositionToWorldQuat(spline.getTransformQuat(this.currentSplineTime), this.startPosition);
            this.object.pp_setPosition(this.startPosition);

            this.object.pp_setUp(GameGlobals.up, spline.getForward(this.currentSplineTime));
        }

        this._prepAnim();
        this._resetStance();

        this.networkedHoverboard.hoverboardData.currentSpeed = 0;
        this.networkedHoverboard.hoverboardData.currentYSpeed = 0;
        this.networkedHoverboard.hoverboardData.currentTurnSpeed = 0;

        for (const callback of this.player.onHeadTransformUpdate) callback(this.object);
    }

    _prepAnim() {
        this.animTime = 0;
        this.animMode = 0;

        this.animTargetObjects = new Map();
        this.animTargetInitialTransformQuat = new Map();

        {
            let head = this.object.pp_getObjectByNameChildren("Head");
            this.animTargetObjects.set("head", head);

            head.translateLocal(vec3_create(0, 1.65, 0));
            this.animTargetInitialTransformQuat.set("head", head.pp_getTransformLocalQuat());
        }

        {
            let handLeft = this.object.pp_getObjectByNameChildren("Hand Left");
            this.animTargetObjects.set("handLeft", handLeft);

            handLeft.translateLocal(vec3_create(0.25, 0.85, -0.15));
            this.animTargetInitialTransformQuat.set("handLeft", handLeft.pp_getTransformLocalQuat());
        }

        {
            let handRight = this.object.pp_getObjectByNameChildren("Hand Right");
            this.animTargetObjects.set("handRight", handRight);

            handRight.translateLocal(vec3_create(-0.25, 0.85, -0.15));
            this.animTargetInitialTransformQuat.set("handRight", handRight.pp_getTransformLocalQuat());
        }

        {
            let feet = this.object.pp_getObjectByNameChildren("Feet");
            this.animTargetObjects.set("feet", feet);

            feet.translateLocal(vec3_create(0, 0, AVATAR_FORWARD_OFFSET_FROM_HEAD));
            this.animTargetInitialTransformQuat.set("feet", feet.pp_getTransformLocalQuat());
        }
    }

    _updateJump(dt) {
        // Implemented outside class definition
    }

    _animUpdate(dt) {
        // Implemented outside class definition
    }

    _resetStance() {
        let head = this.animTargetObjects.get("head");
        head.setTransformLocal(this.animTargetInitialTransformQuat.get("head"));

        let handLeft = this.animTargetObjects.get("handLeft");
        handLeft.setTransformLocal(this.animTargetInitialTransformQuat.get("handLeft"));
        handLeft.pp_setRotationLocal(vec3_create(30, 0, 0));

        let handRight = this.animTargetObjects.get("handRight");
        handRight.setTransformLocal(this.animTargetInitialTransformQuat.get("handRight"));
        handRight.pp_setRotationLocal(vec3_create(30, 0, 0));
    }

    _checkCrossLap() {
        const currentCheckPoint = Math.floor(this.currentSplineTime * RaceManager.LAP_CHECK_POINTS);

        // If you are suddenly more than 1 checkpoint ahead, it's not valid, you might have skipped some part
        if (currentCheckPoint == (this._currentCheckPoint + 1) % RaceManager.LAP_CHECK_POINTS) {
            this._currentCheckPoint = currentCheckPoint;
            if (this._currentCheckPoint == 0) {
                this._crossLap();
            }
        }
    }

    _crossLap() {
        this.lapsAmount++;
        const lapsAmount = currentGameConfig.lapsAmount.value;
        if (this.lapsAmount === lapsAmount) {
            this._raceFinished();
        } else if (this.lapsAmount === lapsAmount - 1) {
            if (common.hoverboardNetworking.room) {
                if (common.balcony.isPlayerOnBalcony) {
                    if (this.lapsAmount == lapsAmount - 1) {
                        common.tracksManager.getRaceManager().prepareFinishLine(true);
                    }
                }
            }
        }
    }

    _raceFinished(manualFinish = false) {
        this.isRacing = false;
        this.slowDown = true;
        this.slowDownCurrentTime = this.slowDownTime;
        this.animMode = 0;

        // TODO: Consider adding the finishing position to message

        common.leaderboard.addExternalData({ name: this.name, finishTime: this._computeFinishTime() });

        if (!manualFinish) {
            common.popupManager.showQuickMessagePopup(`${this.name} has finished the race!`, PopupIconImage.Info);
        }

        common.leaderboard.displayLeaderboard();
    }

    _computeFinishTime() {
        let timeLeftForCurrentLap = 0;
        const lapsAmount = currentGameConfig.lapsAmount.value;
        if (lapsAmount > this.lapsAmount) {
            timeLeftForCurrentLap = (1 - this.currentSplineTime) / this.splineSpeed;
        }

        const timeTillCurrentLapEnd = common.timer.time + timeLeftForCurrentLap;
        const timeForLeftLaps = ((1 / this.splineSpeed) * Math.max(lapsAmount - this.lapsAmount - 1, 0));
        return timeTillCurrentLapEnd + timeForLeftLaps;
    }

    _generateAnimSeed(str) {
        let hash = 0;

        for (let i = 0; i < str.length; i++) {
            hash += str.charCodeAt(i);
        }

        this.animSeed = hash % 51;
    }
}



// IMPLEMENTATION

NPCControllerComponent.prototype.update = function () {
    let diffPosition = vec3_create();

    let splineForward = vec3_create();
    let splinePosition = vec3_create();
    return function update(dt) {
        if (this.player && !this.player.isNPC) {
            this.active = false;
            return;
        }

        const spline = common.tracksManager.getCurrentTrack().getSpline();

        if ((this.isRacing || this.slowDown) && (_countdownEnded || _countdownEndTimestamp <= Date.now())) {
            _countdownEnded = true; // To prevent checking date now as soon as it is not needed anymore

            if (this.riseAnim) {
                const spline = common.tracksManager.getCurrentTrack().getSpline();
                const risedStartPosition = this.startPosition.vec3_add(vec3_create(0, common.hoverboard.verticalOffsetFromGround - common.hoverboard.minVerticalOffsetFromGround, 0));
                risedStartPosition.vec3_convertPositionToLocalQuat(spline.getTransformQuat(this.currentSplineTime), this.localOffset);

                this.riseAnim = null;
                this.performRiseAnimation = false;
            } else {
                this.jumpCheckStartDelay.update(dt);

                if (Globals.isDebugEnabled() && HoverboardDebugs.saveSpawnPositionShortcutEnabled) {
                    if (Globals.getLeftGamepad().getButtonInfo(GamepadButtonID.BOTTOM_BUTTON).isPressEnd(2)) {
                        let debugSpawnPosition = common.tracksManager.getCurrentTrack().getSpawnPositionProvider().getDebugSpawnPositionNPC();
                        if (debugSpawnPosition == null) {
                            debugSpawnPosition = this.debugSpawnPositionTransformQuat;
                        }

                        this.object.pp_setTransformQuat(debugSpawnPosition);

                        this.currentSplineTime = spline.getClosestTime(debugSpawnPosition.quat2_getPosition());

                        this.jumping = false;
                        this.jumpCheckStartDelay.start();
                        this.prevVelocity.vec3_zero();
                        this.onRamp = false;
                        this.rampHeightOffset = 0;
                    }
                }
            }

            this.currentSpeed = (this.rampSpeedBoost + (this.movementSpeed + this.movementSpeedDifficultyLevelEffect * currentGameConfig.npcsDifficulty.value));
            this.splineSpeed = this.currentSpeed / spline.getLength();
            this.splineSpeed *= Math.max((0.25 * this.extraSpeedMultiplier) + 0.9, 0);

            if (this.rampSpeedBoost > 0) {
                this.rampSpeedBoost = Math.max(0, this.rampSpeedBoost - Math.min(this.currentSpeed, common.hoverboard.maxSpeed) * common.hoverboard.speedBraking * dt);
            }

            if (this.slowDown) {
                this.slowDownCurrentTime -= dt;
                if (this.slowDownCurrentTime <= 0) {
                    this.slowDown = false;
                } else {
                    this.currentNormalizedSpeed = Math.pp_interpolate(0, this.splineSpeed, this.slowDownCurrentTime / this.slowDownTime, EasingFunction.easeIn);
                }
            } else if (this.isRacing) {
                this.timeToFullSpeed.update(dt);
                this.currentNormalizedSpeed = MathUtils.interpolate(0, this.splineSpeed, this.timeToFullSpeed.getPercentage(), EasingFunction.easeInVeryWeak);
            }

            this.currentSplineTime += this.currentNormalizedSpeed * dt;
            this.currentSplineTime = spline.clampTime(this.currentSplineTime);

            this._checkCrossLap();

            splineForward.vec3_copy(spline.getForward(this.currentSplineTime));
            this.object.pp_setUp(GameGlobals.up, splineForward);
            this.object.pp_getForward(this.currForward);

            if (Globals.isDebugEnabled() && HoverboardDebugs.boardMovementDisabled) {
                this.object.pp_setRotationQuat(this.initialRotationQuat);
            }

            this.localOffset.vec3_convertPositionToWorldQuat(spline.getTransformQuat(this.currentSplineTime), splinePosition);
            this.object.pp_setPosition(splinePosition);
            this.object.pp_getPosition(this.currPosition);

            this._updateJump(dt);

            if (this.jumping) {
                this.currPosition[1] = this.jumpY;
            } else {
                this.currPosition[1] += this.rampHeightOffset;
            }

            this.object.pp_setPosition(this.currPosition);

            if (Globals.isDebugEnabled() && HoverboardDebugs.boardMovementDisabled) {
                this.object.pp_setPosition(this.initialPosition);
            }

            diffPosition = this.currPosition.vec3_sub(this.prevPosition, diffPosition);
            let distancePerformed = diffPosition.vec3_valueAlongPlane(GameGlobals.up);
            let _distanceYPerformed = diffPosition.vec3_valueAlongAxis(GameGlobals.up);
            let anglePerformed = this.prevForward.vec3_angleSigned(this.currForward, GameGlobals.up);

            // #WARN Y Speed animation right now is based on vertical movement, which might happen just because
            // the ground goes uphill, and not because an actual fly
            // It will be disabled for now until we better understand how NPC will actually decide to fly
            this.networkedHoverboard.hoverboardData.currentSpeed = Math.pp_lerp(this.networkedHoverboard.hoverboardData.currentSpeed, distancePerformed / dt, 1 * dt);
            //this.networkedHoverboard.hoverboardData.currentYSpeed = Math.pp_lerp(this.networkedHoverboard.hoverboardData.currentYSpeed, distanceYPerformed / dt, 10 * dt);
            this.networkedHoverboard.hoverboardData.currentTurnSpeed = Math.pp_lerp(this.networkedHoverboard.hoverboardData.currentTurnSpeed, anglePerformed / dt, 1 * dt);

            this.prevForward.vec3_copy(this.currForward);
            this.prevPosition.vec3_copy(this.currPosition);
            diffPosition.vec3_scale(1 / dt, this.prevVelocity);

            // Get t based on distance and lerp between positions, current solution has speedups when points are not uniformally distributed

            for (const callback of this.player.onHeadTransformUpdate) callback(this.object);
        } else if (this.performRiseAnimation) {
            if (this.riseAnim) {
                this.riseAnim.tick(getTime());
            }

            this.localOffset.vec3_convertPositionToWorldQuat(spline.getTransformQuat(this.currentSplineTime), splinePosition);
            this.object.pp_setPosition(splinePosition);
            this.object.pp_getPosition(this.currPosition);

            if (Globals.isDebugEnabled() && HoverboardDebugs.boardMovementDisabled) {
                this.object.pp_setPosition(this.initialPosition);
            }
            this.prevPosition.vec3_copy(this.currPosition);

            this.networkedHoverboard.hoverboardData.currentSpeed = 0;
            this.networkedHoverboard.hoverboardData.currentYSpeed = 0;
            this.networkedHoverboard.hoverboardData.currentTurnSpeed = 0;

            for (const callback of this.player.onHeadTransformUpdate) callback(this.object);
        } else {
            this.networkedHoverboard.hoverboardData.currentSpeed = 0;
            this.networkedHoverboard.hoverboardData.currentYSpeed = 0;
            this.networkedHoverboard.hoverboardData.currentTurnSpeed = 0;
        }

        this._animUpdate(dt);
    };
}();

NPCControllerComponent.prototype._updateJump = function () {
    const raycastLocalOffsetLeft = vec3_create(0.5, 5.0, 0.0);
    const raycastLocalOffsetRight = vec3_create(-0.5, 5.0, 0.0);

    let raycastPosition = vec3_create();
    return function _updateJump(dt) {
        if (!this.jumpCheckStartDelay.isDone()) return;

        if (this.jumping) {
            this.jumpVerticalSpeed -= dt * common.hoverboard.gravityAcceleration;

            this.jumpY += this.jumpVerticalSpeed * dt;

            if (this.jumpY < this.currPosition[1]) {
                this.jumpY = this.currPosition[1];

                if (this.jumpVerticalSpeed <= 0) {
                    this.jumping = false;
                    this.rampHeightOffset = 0;
                    this.jumpVerticalSpeed = 0;
                }
            }
        } else {
            this.object.pp_convertPositionObjectToWorld(raycastLocalOffsetLeft, raycastPosition);
            let rayHit = this.engine.physics.rayCast(raycastPosition, GameGlobals.down, 64, 10);
            if (rayHit.hitCount == 0) {
                this.object.pp_convertPositionObjectToWorld(raycastLocalOffsetRight, raycastPosition);
                rayHit = this.engine.physics.rayCast(raycastPosition, GameGlobals.down, 64, 10);
            }

            if (rayHit.hitCount > 0) {
                const rampY = rayHit.locations[0][1];
                this.rampHeightOffset = (rampY - this.currPosition[1]) + common.hoverboard.verticalOffsetFromGround;
                this.jumpVerticalAngle = rayHit.normals[0].vec3_angle(GameGlobals.up);

                this.onRamp = true;
            } else if (this.onRamp) {
                this.onRamp = false;
                this.jumping = true;

                this.jumpY = this.rampHeightOffset + this.currPosition[1];
                this.rampHeightOffset = 0;

                const angleFactor = MathUtils.mapToRange(this.jumpVerticalAngle, 0, common.hoverboard.verticalSpeedJumpMaxAngle, 0, 1);
                const speedFactor = MathUtils.mapToRange(this.currentSpeed, 0, common.hoverboard.maxSpeed, 0, 1);

                this.jumpVerticalSpeed = common.hoverboard.verticalSpeedJumpMaxVerticalSpeedJumpToAdd * angleFactor * speedFactor;

                const minBoost = 1;
                this.jumpVerticalSpeed = Math.max(this.jumpVerticalSpeed * common.hoverboard.verticalSpeedRampBoostMultiplier, minBoost);

                this.rampSpeedBoost = common.hoverboard.horizontalSpeedRampBoost;
            } else {
                const minVerticalSpeedToJump = 7;

                let verticalSpeed = this.prevVelocity[1];
                if (verticalSpeed > minVerticalSpeedToJump) {
                    this.jumping = true;

                    this.jumpY = this.currPosition[1];

                    const minBoost = 1;
                    this.jumpVerticalSpeed = Math.max(verticalSpeed * common.hoverboard.verticalSpeedEnviromentalBoostMultiplier, minBoost);
                }

                if (this.maxValue == null) {
                    this.maxValue = verticalSpeed;
                }

                this.maxValue = Math.max(this.maxValue, verticalSpeed);
            }
        }
    };
}();

NPCControllerComponent.prototype._animUpdate = function () {
    let tempVector = vec3_create();
    let tempQuat = quat_create();
    return function _animUpdate(dt) {
        this.animTime += dt;

        let phase = this.animSeed + this.animTime * this.animRate;
        if (this.animMode == 1) {
            phase += 0.2 * Math.cos(phase + Math.PI);
            let head = this.animTargetObjects.get("head");
            head.setTransformLocal(this.animTargetInitialTransformQuat.get("head"));
            let cosval = Math.cos(phase);
            //let sinval = Math.sin(phase);
            let headdy = -0.2 * (1 - cosval);
            head.translateLocal(vec3_create(0, headdy, 0));

            let swingreach = 0.6;
            let shoulderout = 0.3;
            let shoulderdown = 0.25;
            let shoulderback = 0.1;

            head.getPositionLocal(tempVector);

            // shoulder point
            tempVector[0] += shoulderout;
            tempVector[1] -= shoulderdown;
            tempVector[2] -= shoulderback;

            // hand point
            let swingang = Math.PI * (0.75 - 0.6 * cosval);
            let cosswing = Math.cos(swingang);
            let sinswing = Math.sin(swingang);
            tempVector[1] += swingreach * cosswing;
            tempVector[2] += swingreach * sinswing;
            quat.fromEuler(tempQuat, 5 - 110 * cosval, 0, 0);

            let handLeft = this.animTargetObjects.get("handLeft");
            handLeft.setPositionLocal(tempVector);
            handLeft.setRotationLocal(tempQuat);

            head.getPositionLocal(tempVector);
            tempVector[0] -= shoulderout;
            tempVector[1] -= shoulderdown;
            tempVector[2] -= shoulderback;

            // hand point
            tempVector[1] += swingreach * cosswing;
            tempVector[2] += swingreach * sinswing;

            let handRight = this.animTargetObjects.get("handRight");
            handRight.setPositionLocal(tempVector);
            handRight.setRotationLocal(tempQuat);

            //let hip = this.animTargetObjects.get("hipsBone"); // for direct transform
            //hip.setTransformLocal(this.animTargetObjects.get("hipsBoneTRS"));
        } else {
            // idling
            this._resetStance();

            let head = this.animTargetObjects.get("head");
            let headRotationAngle = Math.pp_interpolatePeriodic(-25, 25, this.animTime * 0.3 + this.animSeed, EasingFunction.easeInOutWeak);
            head.rotateAxisAngleDegObject(GameGlobals.up, headRotationAngle);
        }
    };
}();

<?xml version="1.0" encoding="UTF-8"?>
<ui-tree name="default" xmlns="lazy-widgets" xmlns:option="lazy-widgets:options" xmlns:on="lazy-widgets:on">
    <rounded-corners>
        <background>
            <margin option:container-padding='@{"left": 8, "right": 8, "top": 8, "bottom": 8}'>
                <book option:id="menu-book">
                    <column>
                        <!-- PAUSE state -->
                        <label option:body-text-align="@0.5">Paused</label>
                        <spacing/>
                        <clicky-button on:click="$resumeCallback">Resume</clicky-button>
                        <clicky-button option:enabled="$isNotMobile" option:id="toTrack" on:click="$toTrackCallback">To Track</clicky-button>
                        <clicky-button option:enabled="@false" option:id="toTrackDebug" on:click="$toTrackDebugCallback">To Track Debug</clicky-button>
                        <row>
                            <clicky-button option:flex="@1" option:clickable="@false" option:id="join" on:click="$joinRoomCallback">Join Room</clicky-button>
                            <clicky-button option:flex="@1" option:id="share" on:click="$shareRoomCallback">Share Room</clicky-button>
                        </row>
                        <clicky-button on:click="$settingsCallback">Settings</clicky-button>
                        <row>
                            <label>Quiet Mode</label>
                            <spacing/>
                            <clicky-checkbox variable="$quietModeVar"/>
                        </row>
                    </column>
                    <column>
                        <!-- ROOM_PICK state -->
                        <label>Enter room ID:</label>
                        <text-input variable="$roomIDVar" option:input-filter="$numpadInputFilter"></text-input>
                        <numpad variable="$roomIDVar"/>
                        <spacing/>
                        <row>
                            <clicky-button option:flex="@1" on:click="$backCallback">Back</clicky-button>
                            <clicky-button option:flex="@1" on:click="$joinRoomByIDCallback" option:id="join-by-id" option:clickable="@false">Join</clicky-button>
                        </row>
                    </column>
                    <column>
                        <!-- WAIT_MSG state -->
                        <live-label option:body-text-align="@0.5" text-source="$message"/>
                        <label option:body-text-font="1em sui-generis">Please wait...</label>
                    </column>
                    <column>
                        <!-- OK_MSG state -->
                        <live-label option:body-text-align="@0.5" text-source="$message"/>
                        <row option:multi-container-alignment='@{"main": 0.5, "cross": "stretch"}'>
                            <clicky-button on:click="$maybeCloseCallback">Ok</clicky-button>
                        </row>
                    </column>
                    <column>
                        <!-- GAME_SETTINGS state -->
                        <label option:body-text-align="@0.5">Settings</label>
                        <spacing/>
                        <scrollable-viewport-widget option:width-coupling="@1" option:min-height="@150" option:scrollbar-mode="@1">
                            <artificial-constraint constraints="$viewportConstraints">
                                <margin option:container-padding='@{"left": 0, "right": 4, "top": 0, "bottom": 0}'>
                                    <column>
                                        <label option:body-text-align="@0.5">Audio</label>
                                        <row>
                                            <clicky-button option:flex="@1" on:click="$prevMusicVolumeCallback"> &#60; </clicky-button>
                                            <spacing/>
                                            <label>  Music Volume  </label>
                                            <spacing/>
                                            <clicky-button option:flex="@1" on:click="$nextMusicVolumeCallback"> &#62; </clicky-button>
                                        </row>
                                        <row>
                                            <clicky-button option:flex="@1" on:click="$prevVOVolumeCallback"> &#60; </clicky-button>
                                            <spacing/>
                                            <label>  Voice Volume  </label>
                                            <spacing/>
                                            <clicky-button option:flex="@1" on:click="$nextVOVolumeCallback"> &#62; </clicky-button>
                                        </row>
                                        <separator/>
                                        <label option:body-text-align="@0.5">Voice chat</label>
                                        <row>
                                            <label>P2P VoIP</label>
                                            <spacing/>
                                            <clicky-checkbox variable="$p2pVar"/>
                                        </row>
                                        <warn-label option:id="p2p-bad-warning" option:enabled="@false">Could not connect to some players via P2P; you may be behind a restrictive firewall</warn-label>
                                        <warn-label option:id="p2p-unavailable-warning" option:enabled="@false">P2P VoIP is currently not available</warn-label>
                                        <row>
                                            <label>Mediasoup VoIP</label>
                                            <spacing/>
                                            <clicky-checkbox variable="$mediasoupVar"/>
                                        </row>
                                        <warn-label option:id="mediasoup-unavailable-warning" option:enabled="@false">Mediasoup VoIP is currently not available</warn-label>
                                        <warn-label option:id="voip-disabled-warning" option:enabled="@false"></warn-label>
                                        <clicky-button on:click="$requestMicrophoneCallback">Request microphone</clicky-button>
                                        <separator/>
                                        <label option:body-text-align="@0.5">Consent</label>
                                        <row>
                                            <label>Terms and conditions:</label>
                                            <clicky-button on:click="$askPermissions">Ask</clicky-button>
                                        </row>
                                        <separator/>
                                        <label option:body-text-align="@0.5">About HoverFit</label>
                                        <row>
                                            <label>Build version:</label>
                                            <label text="$buildVersion"/>
                                        </row>
                                        <row>
                                            <label>Build time:</label>
                                            <label text="$buildTime"/>
                                        </row>
                                        <column option:enabled="$devMode">
                                            <separator/>
                                            <label option:body-text-align="@0.5">Developer tools</label>
                                            <clicky-button on:click="$toggleDebugMenuCallback">Toggle VoIP debug menu</clicky-button>
                                            <clicky-button on:click="$pickRandomName">Random name</clicky-button>
                                        </column>
                                    </column>
                                </margin>
                            </artificial-constraint>
                        </scrollable-viewport-widget>
                        <spacing/>
                        <row option:multi-container-alignment='@{"main": 0.5, "cross": "stretch"}'>
                            <clicky-button on:click="$backCallback">Back</clicky-button>
                        </row>
                    </column>
                    <column>
                        <!-- CONFIRM_END: show nothing, since this state is handled by a mesh-based ui -->
                    </column>
                    <column>
                        <!-- PERMISSIONS_CONSENT state -->
                        <theme-scope theme="$consentContentTheme">
                            <column>
                                <label option:body-text-align="@0.5">While playing HoverFit, you can choose to join a public chat room.&#xA;Do not share any personal or sensitive information.&#xA;We are not storing any personal information as part of our&#xA;multiplayer service.</label>
                                <row option:multi-container-alignment='@{"main": 0.5, "cross": "stretch"}'>
                                    <label>Privacy policy:</label><hyperlink text="$nicePolicyURL" url="$policyURL"/>
                                </row>
                                <label option:body-text-align="@0.5">By continuing, you agree that you are allowed to join public rooms&#xA;and accept the terms and conditions of the service.</label>
                                <row option:multi-container-alignment='@{"main": 0.5, "cross": "stretch"}'>
                                    <label>Terms and conditions:</label><hyperlink text="$niceTermsURL" url="$termsURL"/>
                                </row>
                                <label option:body-text-align="@0.5">By continuing, you also agree for the storage to be used to save game related informations.</label>
                                <row option:multi-container-alignment='@{"main": 0.5, "cross": "stretch"}'>
                                    <label>Allow microphone to be used for voice chat:</label>
                                    <clicky-checkbox variable="$userAcceptedMicrophoneQueryCheckbox"/>
                                </row>
                            </column>
                        </theme-scope>
                        <spacing/>
                        <spacing/>
                        <spacing/>
                        <row option:multi-container-alignment='@{"main": 0.5, "cross": "stretch"}'>
                            <artificial-constraint constraints="$finalizeConsentConstraints">
                                <clicky-button on:click="$rejectConsent">Deny</clicky-button>
                            </artificial-constraint>
                            <artificial-constraint constraints="$finalizeConsentConstraints">
                                <clicky-button on:click="$acceptConsent">Accept and continue</clicky-button>
                            </artificial-constraint>
                        </row>
                    </column>
                    <column>
                        <!-- CONFIRM_MSG state -->
                        <theme-scope theme="$consentContentTheme">
                            <live-label option:body-text-align="@0.5" text-source="$message"/>
                        </theme-scope>
                        <spacing/>
                        <spacing/>
                        <spacing/>
                        <row option:multi-container-alignment='@{"main": 0.5, "cross": "stretch"}'>
                            <clicky-button option:id="confirmMessageButton" on:click="$applyConfirmMessage">Confirm</clicky-button>
                        </row>
                    </column>
                    <column>
                        <!-- ASK_GAME_MODE state -->
                        <theme-scope theme="$consentContentTheme">
                            <column>
                                <label option:body-text-align="@0.5">Which game mode would you like to play?</label>
                            </column>
                        </theme-scope>
                        <spacing/>
                        <spacing/>
                        <spacing/>
                        <row option:multi-container-alignment='@{"main": 0.5, "cross": "stretch"}'>
                            <margin option:container-padding='@{"left": 5, "right": 5, "top": 0, "bottom": 0}'>
                                <artificial-constraint constraints="$finalizeConsentConstraints">
                                    <container-clicky-button on:click="$askGameModeSingleRace">
                                        <column>
                                            <spacing/>
                                            <icon option:body-text-align="@0.5" image="assets/textures/ui/icons/mode/race-icon.png" option:width="@50" option:height="@25" option:view-box="@[0, 64, 256, 128]"></icon>
                                            <label option:body-text-align="@0.5">Fitness Race</label>
                                            <spacing/>
                                        </column>
                                    </container-clicky-button>
                                </artificial-constraint>
                            </margin>
                            <margin option:container-padding='@{"left": 5, "right": 5, "top": 0, "bottom": 0}'>
                                <artificial-constraint constraints="$finalizeConsentConstraints">
                                    <container-clicky-button on:click="$askGameModeMultiTag">
                                        <column>
                                            <spacing/>
                                            <icon option:body-text-align="@0.5" image="assets/textures/ui/icons/mode/tag-icon.png" option:width="@50" option:height="@25" option:view-box="@[0, 64, 256, 128]"></icon>
                                            <label option:body-text-align="@0.5">Multiplayer Tag</label>
                                            <spacing/>
                                        </column>
                                    </container-clicky-button>
                                </artificial-constraint>
                            </margin>
                        </row>
                    </column>
                </book>
            </margin>
        </background>
    </rounded-corners>
</ui-tree>
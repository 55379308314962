import { Room } from "colyseus.js";
import { Observable, ObservableTransformer, Variable, type ObservableCallback } from "lazy-widgets";
import { AnalyticsUtils } from "wle-pp";
import common from "../common.js";
import { HoverboardGameOnlineConfigJSON } from "../data/game-configuration.js";
import { cancelSceneLoad } from "../misc/load-scene/load-scene.js";
import { LAST_SESSION_GAME_ONLINE_CONFIG_KEY } from "../misc/preferences/pref-keys.js";
import { GLOBAL_PREFS } from "../misc/preferences/preference-manager.js";
import { PopupIconImage } from "../ui/popup/popup.js";
import { replaceSearchParams } from "../utils/url-utils.js";
import { currentRoomData } from "./components/hoverboard-networking-component.js";

export enum RoomState {
    Configuring = 0,
    Disconnected = 1,
    Connecting = 2,
    Connected = 3,
}

export class RoomProxy implements Observable<RoomState> {
    private state = RoomState.Disconnected;
    private callbacks = new Array<ObservableCallback<RoomState>>();
    private _currentRoom: Variable<Room | null>;
    readonly currentRoom: Observable<Room | null>;
    readonly currentRoomID: Observable<string | null>;
    readonly currentRoomText: Observable<string>;

    constructor() {
        this._currentRoom = new Variable(null);
        this.currentRoom = new ObservableTransformer([this._currentRoom, this], () => {
            if (this.state === RoomState.Connected) {
                return this._currentRoom.value;
            } else {
                return null;
            }
        });
        this.currentRoomID = new ObservableTransformer([this.currentRoom, this], () => {
            return this.currentRoom.value?.id ?? null;
        });
        this.currentRoomText = new ObservableTransformer([this.currentRoomID], () => {
            const roomNum = this.currentRoomID.value;
            if (roomNum === null) {
                return "No room joined";
            } else {
                return `Current room: ${roomNum}`;
            }
        });

        const MAIN_CHANNEL = common.MAIN_CHANNEL;
        MAIN_CHANNEL.on("room-no-autojoin", this.setState.bind(this, RoomState.Disconnected));
        MAIN_CHANNEL.on("room-create", this.setState.bind(this, RoomState.Connecting));
        MAIN_CHANNEL.on("room-join", this.setState.bind(this, RoomState.Connecting));
        MAIN_CHANNEL.on("room-init-start", this.onRoomPicked.bind(this));
        MAIN_CHANNEL.on("room-init-done", this.setState.bind(this, RoomState.Connected));
        MAIN_CHANNEL.on("room-create-error", this.setState.bind(this, RoomState.Disconnected));
        MAIN_CHANNEL.on("room-join-error", this.setState.bind(this, RoomState.Disconnected));
        MAIN_CHANNEL.on("room-init-error", this.setState.bind(this, RoomState.Disconnected));
        MAIN_CHANNEL.on("room-leave", this.setState.bind(this, RoomState.Disconnected));
        MAIN_CHANNEL.on("room-error", this.setState.bind(this, RoomState.Disconnected));
        MAIN_CHANNEL.on("load-scene-start", this.setState.bind(this, RoomState.Configuring, false));
        MAIN_CHANNEL.on("load-scene-end", this.setState.bind(this, RoomState.Disconnected, false));
    }

    destroy() {
        this.callbacks.length = 0;
    }

    private onRoomPicked(room: Room) {
        this._currentRoom.value = room;
        this.setState(RoomState.Connecting);
    }

    private setState(state: RoomState, onlyIfNotConfiguring = true) {
        if (this.state === state) return;
        if (onlyIfNotConfiguring && this.state === RoomState.Configuring) return;

        this.state = state;
        for (const callback of this.callbacks) {
            this.doCallback(callback);
        }
    }

    get value() {
        return this.state;
    }

    watch(callback: ObservableCallback<RoomState>, callNow = false, group?: unknown): this {
        this.callbacks.push(callback);

        if (callNow) {
            this.doCallback(callback, group);
        }

        return this;
    }

    unwatch(callback: ObservableCallback<RoomState>): this {
        const i = this.callbacks.indexOf(callback);

        if (i === -1) {
            console.warn("unwatch called, but watcher was not registered");
        } else {
            this.callbacks.splice(i, 1);
        }

        return this;
    }

    private doCallback(callback: ObservableCallback<RoomState>, group?: unknown): void {
        try {
            callback(this, group);
        } catch (e) {
            console.error("Exception in watcher:", e);
        }
    }

    disconnect() {
        if (this.state !== RoomState.Connected) throw new Error("Not connected");

        this._updateLastSessionGameOnlineConfig(false, null, false);

        common.MAIN_CHANNEL.emit("try-disconnect");
    }

    quickPlay() {
        if (this.state !== RoomState.Disconnected) throw new Error("Busy or already connected");
        this._updateLastSessionGameOnlineConfig(true, null, false);

        common.hoverboardNetworking.createOrJoin(null, false, false);

        AnalyticsUtils.sendEvent("play_online");
        AnalyticsUtils.sendEvent("play_online_public");
    }

    cancelConfigurationChange() {
        if (this.state !== RoomState.Configuring) throw new Error("Not configuring");

        if (cancelSceneLoad()) {
            currentRoomData.roomNumber = null;
            currentRoomData.privateRoom = false;
            const url = new URL(window.location.href);
            const searchParams = url.searchParams;
            searchParams.delete("room");
            replaceSearchParams(url, searchParams);

            common.popupManager.showQuickMessagePopup("Cancelled configuration change", PopupIconImage.Warn);
        }
    }

    quickAction() {
        if (this.state === RoomState.Connected) {
            common.roomProxy.disconnect();
        } else if (this.state === RoomState.Configuring) {
            common.roomProxy.cancelConfigurationChange();
        } else if (this.state !== RoomState.Connecting) {
            common.roomProxy.quickPlay();
        }
    }

    hostRoom(roomNumber: number | null, privateRoom: boolean) {
        this._updateLastSessionGameOnlineConfig(true, roomNumber, privateRoom);

        common.hoverboardNetworking.create(roomNumber, privateRoom);

        AnalyticsUtils.sendEvent("play_online");
        if (privateRoom) {
            AnalyticsUtils.sendEvent("play_online_private");
        } else {
            AnalyticsUtils.sendEvent("play_online_public");
        }
    }

    joinRoom(roomNumber: number | null) {
        this._updateLastSessionGameOnlineConfig(true, roomNumber, true);

        common.hoverboardNetworking.join(roomNumber);

        AnalyticsUtils.sendEvent("play_online");
    }

    hostOrJoinRoom(roomNumber: number | null, privateRoom: boolean) {
        this._updateLastSessionGameOnlineConfig(true, roomNumber, privateRoom);

        common.hoverboardNetworking.createOrJoin(roomNumber, privateRoom);

        AnalyticsUtils.sendEvent("play_online");
        if (privateRoom) {
            AnalyticsUtils.sendEvent("play_online_private");
        } else {
            AnalyticsUtils.sendEvent("play_online_public");
        }
    }

    private _updateLastSessionGameOnlineConfig(isOnline: boolean, roomID: number | null, isPrivateRoom: boolean) {
        const newSessionGameOnlineConfig = new HoverboardGameOnlineConfigJSON();
        newSessionGameOnlineConfig.isOnline = isOnline;
        newSessionGameOnlineConfig.roomID = roomID;
        newSessionGameOnlineConfig.isPrivateRoom = isPrivateRoom;

        GLOBAL_PREFS.setPref(LAST_SESSION_GAME_ONLINE_CONFIG_KEY, newSessionGameOnlineConfig);
    }
}
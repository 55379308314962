import { initPlayerLocomotionTeleportMod } from "./components/player_locomotion_teleport_mod.js";

/**
 * TS import preserver
 * 
 * This is only needed to make it so the import is not removed, since it makes the type extensions available to the Typescript 
 */
import "./components/player_locomotion_teleport_mod.js";

export function initPPMods(): void {
    initPlayerLocomotionTeleportMod();
}
import { Component, Property, TextComponent } from "@wonderlandengine/api";
import { ObjectCloneParams, quat2_create } from "wle-pp";
import common from "../../../common.js";
import { currentPlayerData } from "../../../data/player-data.js";
import { GameGlobals } from "../../../misc/game-globals.js";
import { NetworkPlayerComponent } from "../../../network/components/network-player-component.js";

const headTransformQuat = quat2_create();
const markerTransformQuat = quat2_create();

export class RoadMapPlayerMarkerComponent extends Component {
    static TypeName = "road-map-player-marker";
    static Properties = {
        miniMap: Property.object(),
        markerPrototype: Property.object()
    };

    start() {
        this.markerPrototype.pp_setActive(false);

        for (const networkPlayer of common.networkPlayerPool.object.children) {
            let cloneParams = new ObjectCloneParams();
            cloneParams.myUseDefaultComponentCloneAsFallback = true;
            const playerMarkerObject = this.markerPrototype.pp_clone(cloneParams);
            const nameTextComponent = playerMarkerObject.pp_getComponent(TextComponent);
            playerMarkerObject.pp_setActive(false);
            let playerTransformQuat = quat2_create();
            let networkPlayerComponent = networkPlayer.pp_getComponent(NetworkPlayerComponent);

            networkPlayerComponent.onActivateCallbacks.push(() => {
                playerMarkerObject.pp_setActive(true);
            });

            networkPlayerComponent.onDeactivateCallbacks.push(() => {
                playerMarkerObject.pp_setActive(false);
            });

            networkPlayerComponent.onHeadTransformUpdate.push((object) => {
                object.pp_getTransformQuat(playerTransformQuat);
                this.miniMap.pp_convertTransformLocalToWorldQuat(playerTransformQuat, playerTransformQuat);
                playerMarkerObject.pp_setTransformQuat(playerTransformQuat);
                playerMarkerObject.pp_setUp(GameGlobals.up);
            });

            networkPlayerComponent.onNameChange.push((name) => {
                nameTextComponent.text = name;
            });
        }

        let cloneParams = new ObjectCloneParams();
        cloneParams.myUseDefaultComponentCloneAsFallback = true;
        this.currentPlayerMarker = this.markerPrototype.pp_clone(cloneParams);
        this.currentPlayerMarker.pp_setActive(true);
        this.currentPlayerMarkerLabel = this.currentPlayerMarker.pp_getComponent(TextComponent);

        this.updateText();
    }

    init() {
        this.nameListener = this.updateText.bind(this);
    }

    onActivate() {
        currentPlayerData.listen(this.nameListener);
    }

    onDeactivate() {
        currentPlayerData.unlisten(this.nameListener);
    }

    update(dt) {
        common.balcony.playerLocomotion.getPlayerLocomotion().getPlayerHeadManager().getTransformHeadQuat(headTransformQuat);
        this.miniMap.pp_convertTransformLocalToWorldQuat(headTransformQuat, markerTransformQuat);
        this.currentPlayerMarker.pp_setTransformQuat(markerTransformQuat);
        this.currentPlayerMarker.pp_setUp(GameGlobals.up);
    }

    updateText() {
        this.currentPlayerMarkerLabel.text = currentPlayerData.name;
    }

    setEnabled(enabled) {
        this.object.pp_setActive(enabled);
    }
}

import { CatalogItem, heyVRSDK, InventoryItem, OnAuthMessage } from "@heyvr/sdk-types";
import { Emitter } from "@wonderlandengine/api";
import common from "src/hoverfit/common.js";
import { CatalogCategories, classSortedCatalog, slugToCatalogItems, slugToCatalogShopItemButton } from "./shop-interface.js";

export interface OnAuthChangePayload {
    loggedIn: boolean;
    username: string;
    alias: string;
}

interface MockShopConfig {
    catalog: CatalogItem[];
    inventory: InventoryItem[];
}

export abstract class ShopProvider {
    inventoryItems: InventoryItem[] = [];

    isUserLoggedIn: boolean = false;

    catalogReady = false;
    inventoryReady = false;

    readonly onCatalogAvailableEmitter: Emitter<[Map<CatalogCategories, CatalogItem[]>]> = new Emitter();
    readonly onCatalogQueryFailEmitter: Emitter = new Emitter();

    readonly onInventoryUpdatedEmitter: Emitter<[InventoryItem[]]> = new Emitter();
    readonly onInventoryQueryFailEmitter: Emitter = new Emitter();

    abstract getAmountCoins(): Promise<number>;
    abstract fetchCatalog(): Promise<CatalogItem[]>;
    abstract fetchInventory(): Promise<InventoryItem[]>;
    abstract loginUser(): Promise<boolean>;
    abstract purchaseItem(slug: string): Promise<boolean>;

    hasItem(slug: string) {
        return this.inventoryItems.pp_has((element) => element.slug == slug);
    }
}

let mockConfig: MockShopConfig | undefined;

export class MockShopProvider extends ShopProvider {
    private _coins: number;

    private _mockConfigPath = "assets/json/shop/mock-config.json";
    private _configPromise: Promise<MockShopConfig> | undefined;

    constructor() {
        super();
        this._coins = 5000;
        this.isUserLoggedIn = true;
        this.getAmountCoins();
        // Always fetch inventory due to being logged in
        this.fetchInventory();
    }

    getAmountCoins(): Promise<number> {
        common.kioskUpperUI.setHeyVRCoins(this._coins);
        return Promise.resolve(this._coins);
    }

    getConfig(): Promise<MockShopConfig> {
        return fetch(this._mockConfigPath).then(response => {
            if (!response.ok) {
                throw new Error(response.statusText);
            }
            return response.json();
        });
    }

    fetchCatalog(): Promise<CatalogItem[]> {
        if (mockConfig) return this.registerCatalog(mockConfig);

        if (!this._configPromise) this._configPromise = this.getConfig();
        return this._configPromise.then(this.registerCatalog.bind(this));
    }

    registerCatalog(config: MockShopConfig): Promise<CatalogItem[]> {
        if (!mockConfig) mockConfig = config;
        if (config.catalog) {
            for (const item of config.catalog) {
                const shopButton = slugToCatalogShopItemButton.get(item.slug);
                if (shopButton && this.hasItem(item.slug)) {
                    shopButton.setInactive();
                }

                slugToCatalogItems.set(item.slug, item);
                for (const [key, val] of classSortedCatalog.entries()) {
                    if (key === item.item_class) {
                        val.push(item);
                    }
                }
            }

            this.catalogReady = true;

            // console.log("All items categorized: ", verifyAllCatalogItemsAsCategorized(config.catalog));

            this.onCatalogAvailableEmitter.notify(classSortedCatalog);
            return Promise.resolve(config.catalog);
        } else {
            return Promise.reject([]);
        }
    }

    fetchInventory(): Promise<InventoryItem[]> {
        if (mockConfig) return this.registerInventory(mockConfig);

        if (!this._configPromise) this._configPromise = this.getConfig();
        return this._configPromise.then(this.registerInventory.bind(this));
    }

    registerInventory(config: MockShopConfig): Promise<InventoryItem[]> {
        if (!mockConfig) mockConfig = config;
        if (config.inventory) {
            for (const item of config.inventory) {
                this.inventoryItems.push(item);
                const catalogItemButton = slugToCatalogShopItemButton.get(item.slug);
                if (catalogItemButton) {
                    catalogItemButton.setInactive();
                }
            }

            this.inventoryReady = true;

            this.onInventoryUpdatedEmitter.notify(this.inventoryItems);
            return Promise.resolve(this.inventoryItems);
        } else {
            return Promise.reject([]);
        }
    }

    loginUser(): Promise<boolean> {
        return Promise.resolve(true);
    }

    purchaseItem(slug: string): Promise<boolean> {
        const catalogItem = slugToCatalogItems.get(slug);
        if (!catalogItem) return Promise.reject("Purchase failed");

        if (catalogItem.price > this._coins) return Promise.reject("Purchase failed");

        this._coins -= catalogItem.price;

        const itemCatalogButton = slugToCatalogShopItemButton.get(slug);
        if (itemCatalogButton) {
            itemCatalogButton.setInactive();
        }

        const item: InventoryItem = { ...catalogItem, acquired_at: new Date().toISOString(), expires_on: "0", owned_count: 1 };

        this.inventoryItems.push(item);
        // For persistence through map reloads
        mockConfig?.inventory.push(item);
        this.onInventoryUpdatedEmitter.notify([item]);

        this.getAmountCoins();

        return Promise.resolve(true);
    }
}

export class HeyVRShopProvider extends ShopProvider {
    catalogFetchTries: number = 0;
    catalogFetchMaxRetries: number = 3;

    constructor(readonly heyVR: heyVRSDK) {
        super();

        // User is logged in on page load
        this.heyVR.user.isLoggedIn().then((loggedIn: boolean) => {
            if (loggedIn) {
                this.onUserLoggedIn();
            }
        }).catch((err: Error) => {
            console.log("User not logged in: ", err);
        });

        if (this.heyVR.user.onAuthChange) {
            // User logs in after page load
            this.heyVR.user.onAuthChange((payload: OnAuthMessage) => {
                if (payload.loggedIn) {
                    this.onUserLoggedIn();
                } else {
                    // TODO: Handle logout
                }
            });
        }
    }

    override getAmountCoins(): Promise<number> {
        return this.heyVR.user.getAmountCoins().then((amountCoins: number) => {
            common.kioskUpperUI.setHeyVRCoins(amountCoins);
            return amountCoins;
        });
    }

    override fetchCatalog(): Promise<CatalogItem[]> {
        return this.heyVR?.inventory.getCatalog().then((catalog: Array<CatalogItem>) => {
            for (const item of catalog) {
                const shopButton = slugToCatalogShopItemButton.get(item.slug);
                if (shopButton && this.hasItem(item.slug)) {
                    shopButton.setInactive();
                }

                slugToCatalogItems.set(item.slug, item);
                for (const [key, val] of classSortedCatalog.entries()) {
                    if (key === item.item_class) {
                        val.push(item);
                    }
                }
            }

            this.catalogReady = true;

            // console.log("All items categorized: ", verifyAllCatalogItemsAsCategorized(catalog));

            this.onCatalogAvailableEmitter.notify(classSortedCatalog);
            return catalog;
        });
    }

    override fetchInventory(): Promise<InventoryItem[]> {
        return this.heyVR.inventory.get().then((inventoryItems: InventoryItem[]) => {
            for (const item of inventoryItems) {
                this.inventoryItems.push(item);
                const catalogItemButton = slugToCatalogShopItemButton.get(item.slug);
                if (catalogItemButton) {
                    catalogItemButton.setInactive();
                }
            }

            this.inventoryReady = true;

            this.onInventoryUpdatedEmitter.notify(this.inventoryItems);
            return this.inventoryItems;
        });
    }

    override loginUser(): Promise<boolean> {
        return this.heyVR.user.openLogin().then(() => {
            return Promise.resolve(true);
        });
    }

    private onUserLoggedIn(): void {
        this.isUserLoggedIn = true;
        this.getAmountCoins();
        this.inventoryItems = [];
        this.fetchInventory();
    }

    override purchaseItem(slug: string): Promise<boolean> {
        return this.heyVR.inventory.purchase(slug, 1).then((success: string) => {
            if (success) {
                const catalogItem = slugToCatalogItems.get(slug);
                const itemCatalogButton = slugToCatalogShopItemButton.get(slug);
                if (itemCatalogButton) {
                    itemCatalogButton.setInactive();
                }

                const item = { ...catalogItem, acquired_at: new Date().toISOString(), expires_on: "0", owned_count: 1 };
                delete item.expires_after;
                delete item.price;
                delete item.price_discounted;

                this.inventoryItems.push(item as InventoryItem);
                this.onInventoryUpdatedEmitter.notify([item as InventoryItem]);
                return true;
            }
            return false;
        });
    }
}

function __verifyAllCatalogItemsAsCategorized(catalog: Array<CatalogItem>): boolean {
    let totalClassSortedCatalogSize = 0;
    for (const catalog of classSortedCatalog) {
        totalClassSortedCatalogSize += catalog[1].length;
    }
    return totalClassSortedCatalogSize === catalog.length;
}
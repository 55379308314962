import { Component, WonderlandEngine } from "@wonderlandengine/api";
import { GameMode } from "hoverfit-shared-netcode";
import common from "../../../common.js";
import { currentGameConfig } from "../../../data/game-configuration.js";
import { MiniMapManager } from "../mini-map-manager.js";
import { RaceManager } from "../race-manager.js";
import { TagResultsBoard } from "../results/tag-results-board.js";
import { TrackStatistics } from "../results/track-statistics.js";
import { FinishGoalComponent } from "./finish-goal-component.js";
import { StartGoalComponent } from "./start-goal-component.js";
import { TrackComponent } from "./track-component.js";

export class TracksManagerComponent extends Component {
    static TypeName = "tracks-manager";

    private _trackStatistics: TrackStatistics = new TrackStatistics();
    private _raceManager: RaceManager = new RaceManager();

    private _miniMapManager: MiniMapManager = new MiniMapManager();

    private _tagResultsBoard!: TagResultsBoard;

    private _updateCountdown: number = 5;
    private _tracksReady: boolean = false;

    private _trackComponentsByMapTrackIndex: Map<number, TrackComponent> = new Map();
    private _currentTrackComponent: TrackComponent | null = null;
    private _currentMapTrackIndex: number | null = null;

    static onRegister(engine: WonderlandEngine) {
        engine.registerComponent(StartGoalComponent);
        engine.registerComponent(FinishGoalComponent);
    }

    init() {
        common.tracksManager = this;
        this._raceManager.setTracksManager(this);
    }

    start() {
        const tagResultsBoardObject = common.hoverboard.object.pp_getObjectByName("Tag Results Board");
        this._tagResultsBoard = new TagResultsBoard(tagResultsBoardObject!);
        this._tagResultsBoard.setVisible(false);
    }

    returnedToBalcony() {
        if (currentGameConfig.mode == GameMode.Race) {
            this._raceManager.returnedToBalcony();
        }
    }

    countdownStarted() {
        if (currentGameConfig.mode == GameMode.Race) {
            this._raceManager.countdownStarted();
        }

        this.getTrackStatistics().reset();
    }

    areAllTracksReady(): boolean {
        let areAllTracksReady = this._tracksReady;

        for (const trackComponent of this._trackComponentsByMapTrackIndex.values()) {
            areAllTracksReady &&= trackComponent.isSplineLoaded();
        }

        return areAllTracksReady;
    }

    getRaceManager(): RaceManager {
        return this._raceManager;
    }

    getMiniMapManager(): MiniMapManager {
        return this._miniMapManager;
    }

    getTagResultsBoard(): TagResultsBoard {
        return this._tagResultsBoard;
    }

    getTrackStatistics(): TrackStatistics {
        return this._trackStatistics;
    }

    getCurrentTrack(): TrackComponent | null {
        return this._currentTrackComponent;
    }

    setTrackByMapTrackIndex(mapTrackIndex: number) {
        if (this._tracksReady) {

            if (this._currentTrackComponent != null) this._currentTrackComponent.setTrackActive(false);

            this._currentTrackComponent = this._trackComponentsByMapTrackIndex.get(mapTrackIndex)!;
            if (!this._currentTrackComponent) throw new Error("Invalid map track index");

            this._currentTrackComponent.setTrackActive(true);
        }

        this._currentMapTrackIndex = mapTrackIndex;
    }

    update(dt: number) {
        if (this._updateCountdown > 0) {
            this._updateCountdown--;
            if (this._updateCountdown == 0) {
                const modeConfig = currentGameConfig.modeConfig;

                for (const trackComponent of this.object.pp_getComponents(TrackComponent)) {
                    trackComponent.setTracksManager(this);

                    // XXX only include map track if it's referenced by any track under
                    //     the current game mode. some track maps are exclusive to
                    //     specific game modes
                    for (const track of modeConfig.tracks) {
                        if (track.mapTrackIndex === trackComponent.mapTrackIndex) {
                            this._trackComponentsByMapTrackIndex.set(trackComponent.mapTrackIndex, trackComponent);
                            break;
                        }
                    }
                }

                this.setTrackByMapTrackIndex(currentGameConfig.trackConfig.mapTrackIndex);

                this._raceManager.start(this.object);
                this._miniMapManager.start();
            }
        } else {
            if (!this._tracksReady) {
                this._tracksReady = true;
                for (const trackComponent of this._trackComponentsByMapTrackIndex.values()) {
                    this._tracksReady &&= trackComponent.isReady();
                }

                if (this._tracksReady && this._currentMapTrackIndex! != null) {
                    this.setTrackByMapTrackIndex(this._currentMapTrackIndex);
                }
            }

            this._raceManager.update(dt);
            this._miniMapManager.update(dt);
        }
    }
}
import { Component } from "@wonderlandengine/api";
import { property } from "@wonderlandengine/api/decorators.js";
import common from "src/hoverfit/common.js";
import { vec4_create, Vector4 } from "wle-pp";

export class HoverfitSceneResources {
    pickupSpeedHologramConeColor: Vector4 = vec4_create();
    pickupSpringHologramConeColor: Vector4 = vec4_create();
}

export class HoverfitSceneResourcesComponent extends Component {
    static TypeName = "hoverfit-scene-resources";

    @property.color()
    pickupSpeedHologramConeColor!: Vector4;

    @property.color()
    pickupSpringHologramConeColor!: Vector4;

    start() {
        const hoverfitSceneResources = new HoverfitSceneResources();

        hoverfitSceneResources.pickupSpeedHologramConeColor.vec4_copy(this.pickupSpeedHologramConeColor);
        hoverfitSceneResources.pickupSpringHologramConeColor.vec4_copy(this.pickupSpringHologramConeColor);

        common.hoverfitSceneResources = hoverfitSceneResources;
    }
}
import { Component } from "@wonderlandengine/api";
import { property } from "@wonderlandengine/api/decorators.js";
import { GameMode } from "hoverfit-shared-netcode";
import { currentGameConfig } from "src/hoverfit/data/game-configuration.js";
import { GameGlobals } from "src/hoverfit/misc/game-globals.js";
import { PhysicsUtils, Quaternion2, RaycastParams, RaycastResults, quat2_create, quat_create } from "wle-pp";
import { SpawnPositionProvider } from "../spawn-position-provider.js";
import { SplineComponent } from "../splines/components/spline-component.js";
import { Spline } from "../splines/spline.js";
import { TracksManagerComponent } from "./tracks-manager-component.js";

export class TrackComponent extends Component {
    static TypeName = "track";

    @property.int(0)
    mapTrackIndex!: number;

    @property.bool(true)
    showDynamicChevrons!: boolean;

    private _tracksManager!: TracksManagerComponent;

    private _spawnPositionProvider: SpawnPositionProvider = new SpawnPositionProvider();
    private _countdownPosition: Quaternion2 | null = null;
    private _startGoalPosition: Quaternion2 | null = null;
    private _finishGoalPosition: Quaternion2 | null = null;

    private _splineComponent: SplineComponent | null = null;
    private _spline: Spline | null = null;

    private _isRaceSupported: boolean = false;

    private _ready: boolean = false;

    init() {
        this._splineComponent = this.object.pp_getComponent(SplineComponent);

        this._isRaceSupported = this._splineComponent != null;
    }

    start() {
        this._spawnPositionProvider.start();

        if (this._splineComponent != null) {
            this._splineComponent.loadEmitter!.add(function (this: TrackComponent) {
                this._setupTrack();
            }.bind(this), { immediate: true });
        } else {
            this._setupTrack();
        }

        this.object.pp_setActive(false);
    }

    update(dt: number) {
        this._spawnPositionProvider.update(dt);
    }

    isReady(): boolean {
        return this._ready;
    }

    setTracksManager(tracksManager: TracksManagerComponent): void {
        this._tracksManager = tracksManager;
    }

    areDynamicChevronsEnabled(): boolean {
        return this.showDynamicChevrons;
    }

    isCountdownUsingFixedPosition(): boolean {
        return this._countdownPosition != null;
    }

    getCountdownPosition(): Quaternion2 | null {
        return this._countdownPosition;
    }

    hasSpline(): boolean {
        return this._spline != null;
    }

    getSpline(): Spline | null {
        return this._spline;
    }

    isSplineLoaded(): boolean {
        return !this.hasSpline() || this._splineComponent!.isSplineLoaded()!;
    }

    getSpawnPositionProvider(): SpawnPositionProvider {
        return this._spawnPositionProvider;
    }

    setTrackActive(active: boolean) {
        if (active) {
            this._tracksManager!.getMiniMapManager().setTrack(this);

            const raceManager = this._tracksManager.getRaceManager();
            if (!this._isRaceSupported || currentGameConfig.mode != GameMode.Race) {
                raceManager.setRaceElementsEnabled(false);
            } else {
                raceManager.setStartAndFinishGoalsPositions(this._startGoalPosition!, this._finishGoalPosition!);
                raceManager.setRaceElementsEnabled(true);
            }

            this.object.pp_setActive(true);
        } else {
            this._tracksManager.getMiniMapManager().setTrack(null);
            this._tracksManager.getRaceManager().setRaceElementsEnabled(false);

            this.object.pp_setActive(false);
        }
    }

    private _setupTrack() {
        if (this._splineComponent == null) {
            this._countdownPosition = null;
            this._startGoalPosition = null;
            this._finishGoalPosition = null;

            const spawnPositions: Quaternion2[] = [];
            const spawnPositionsObjects = this.object.pp_getObjectByName("Spawn Positions")!.pp_getDescendants();
            for (const spawnPositionsObject of spawnPositionsObjects) {
                const position = spawnPositionsObject.pp_getPosition();
                const raycastParams = new RaycastParams();
                raycastParams.myOrigin.vec3_copy(position.vec3_add(GameGlobals.up.vec3_scale(2)));
                raycastParams.myDirection.vec3_copy(GameGlobals.down);
                raycastParams.myDistance = 50;
                raycastParams.myIgnoreHitsInsideCollision = true;
                raycastParams.myBlockLayerFlags.setAllFlagsActive(true);
                const raycastResults: RaycastResults = PhysicsUtils.raycast(raycastParams);

                if (raycastResults.isColliding()) {
                    const originalSpawnPositionTransformQuat = spawnPositionsObject.pp_getTransformQuat();
                    spawnPositions.push(originalSpawnPositionTransformQuat.quat2_setPosition(raycastResults.myHits[0].myPosition));
                } else {
                    spawnPositions.push(spawnPositionsObject.pp_getTransformQuat());
                }
            }
            this._spawnPositionProvider.setSpawnPositions(spawnPositions);
        } else {
            this._spline = this._splineComponent.getSpline()!;

            const startPositionLocator = this.object.pp_getObjectByName("Start Position Locator");
            if (startPositionLocator != null) {
                this._spline.setStartClosestToPosition(startPositionLocator.pp_getPosition());
                this._spline.setDirectionClosestToForward(startPositionLocator.pp_getForward());
            }

            this._countdownPosition = quat2_create();
            this._startGoalPosition = quat2_create();
            this._finishGoalPosition = quat2_create();

            {
                const countdownBasePosition = this._spline.getPosition(this._spline.getTimeByDistance(10)).pp_clone();
                const countdownBaseForward = this._spline.getForward(0).pp_clone();

                const countdownPosition = countdownBasePosition.vec3_add(GameGlobals.up.vec3_scale(5));
                const countdownRotationQuat = quat_create();
                countdownRotationQuat.quat_setUp(GameGlobals.up, countdownBaseForward.vec3_negate());

                this._countdownPosition.quat2_setPositionRotationQuat(countdownPosition, countdownRotationQuat);
            }

            {
                const goalBasePosition = this._spline.getPosition(this._spline.getTimeByDistance(40)).pp_clone();
                const goalBaseForward = this._spline.getForward(this._spline.getTimeByDistance(40)).pp_clone();

                const goalPosition = goalBasePosition.pp_clone();
                const goalRotationQuat = quat_create();
                goalRotationQuat.quat_setUp(GameGlobals.up, goalBaseForward.vec3_negate());

                this._startGoalPosition.quat2_setPositionRotationQuat(goalPosition, goalRotationQuat);
                this._finishGoalPosition.quat2_setPositionRotationQuat(goalPosition, goalRotationQuat);
            }

            {
                const spawnPositions: Quaternion2[] = [];

                const spawnPositionBasePosition = this._spline.getPosition(0).pp_clone();
                const spawnPositionForward = this._spline.getForward(0).pp_clone();
                const spawnPositioRotationQuat = quat_create();
                spawnPositioRotationQuat.quat_setUp(GameGlobals.up, spawnPositionForward);

                const spawnPositionRight = spawnPositioRotationQuat.quat_getRight();

                for (let i = 1; i <= 6; i++) {
                    const positionSign = (i % 2 == 0) ? -1 : 1;
                    const currentSpawnPosition = spawnPositionBasePosition.vec3_add(spawnPositionRight.vec3_scale((1.5 + (3 * Math.floor((i - 1) / 2))) * positionSign));

                    const currentSpawnTransformQuat = quat2_create();
                    currentSpawnTransformQuat.quat2_setPositionRotationQuat(currentSpawnPosition, spawnPositioRotationQuat);

                    spawnPositions.push(currentSpawnTransformQuat);
                }

                this._spawnPositionProvider.setSpawnPositions(spawnPositions);
            }

            const spawnPosition = this._spline.getPosition(0);
            const finishPosition = this._spline.getPosition(this._spline.getTimeByDistance(40));
            this._spline.setStartClosestToPosition(finishPosition);

            this._spawnPositionProvider.setSpawnPositionSplineTime(this._spline.getClosestTime(spawnPosition));
        }

        this._ready = true;
    }
}
import { Object3D } from "@wonderlandengine/api";
import common from "src/hoverfit/common.js";
import { GameGlobals } from "src/hoverfit/misc/game-globals.js";
import { Globals, MathUtils, ObjectPool, ObjectPoolParams, Timer, quat2_create, quat_create, vec3_create } from "wle-pp";
import { TrackComponent } from "./components/track-component.js";

export class MiniMapManager {

    private _trackMiniMap!: Object3D;
    private _miniMapWorld!: Object3D;
    private _mapFinishLineMarker!: Object3D;
    private _mapTrackDirectionMarker!: Object3D;

    private _currentTrackComponent: TrackComponent | null = null;

    private _poolID: string = "";

    private _visualizationInitialized: boolean = false;
    private _currentVisualizationSplineTime: number = 0;
    private _visualizePointDelay: Timer = new Timer(0.03, false);
    private distanceBetweenPointsAsSplineTime: number = 0;

    private static readonly _distanceBetweenPoints: number = 0.075;

    start() {
        this._trackMiniMap = common.balcony.object.pp_getObjectByName("Track Mini Map")!;
        this._miniMapWorld = this._trackMiniMap.pp_getObjectByName("Mini Map World")!;

        const miniMapElements = this._trackMiniMap.pp_getObjectByName("Elements")!;
        this._mapFinishLineMarker = miniMapElements.pp_getObjectByName("Finish Line Marker")!;
        this._mapTrackDirectionMarker = miniMapElements.pp_getObjectByName("Track Direction Marker")!;

        this._mapFinishLineMarker.pp_setScaleLocal(vec3_create(20 / 1000));
        this._mapFinishLineMarker.pp_setActive(false);

        this._mapTrackDirectionMarker.pp_setScaleLocal(vec3_create(20 / 1000));
        this._mapTrackDirectionMarker.pp_setActive(false);

        const poolParams = new ObjectPoolParams();
        poolParams.myInitialPoolSize = 40;
        poolParams.myPercentageToAddWhenEmpty = 0.5;

        this._poolID = "mini_map_manager_" + MathUtils.randomUUID();
        Globals.getObjectPoolManager()!.addPool(this._poolID, new ObjectPool(this._mapTrackDirectionMarker, poolParams));
    }

    setTrack(trackComponent: TrackComponent | null) {
        this._mapFinishLineMarker.pp_setActive(false);

        Globals.getObjectPoolManager()!.releaseAll(this._poolID);

        this._visualizationInitialized = false;
        this._currentTrackComponent = trackComponent;
    }

    update(dt: number) {
        if (this._currentTrackComponent != null && this._currentTrackComponent.hasSpline()) {
            if (this._visualizationInitialized) {
                const spline = this._currentTrackComponent.getSpline()!;

                if (this._currentVisualizationSplineTime < 1) {
                    this._visualizePointDelay.update(dt);
                    if (this._visualizePointDelay.isDone()) {
                        this._visualizePointDelay.start();

                        const dotMiniMapWorldTransformQuat = quat2_create();

                        const dotMiniMapWorldPosition = spline.getPosition(this._currentVisualizationSplineTime);
                        dotMiniMapWorldPosition.vec3_add(vec3_create(0, 5, 0), dotMiniMapWorldPosition);
                        dotMiniMapWorldTransformQuat.quat2_setPosition(dotMiniMapWorldPosition);

                        const splineForward = spline.getForward(this._currentVisualizationSplineTime);
                        const dotRotationQuat = quat_create();
                        dotRotationQuat.quat_setForward(splineForward, GameGlobals.up);
                        dotMiniMapWorldTransformQuat.quat2_setRotationQuat(dotRotationQuat);

                        if (this._currentVisualizationSplineTime == 0) {
                            const dotWorldTransformQuat = this._miniMapWorld.pp_convertTransformObjectToWorldQuat(dotMiniMapWorldTransformQuat);
                            const dotTrackMiniMapWorldTransformQuat = this._trackMiniMap.pp_convertTransformWorldToObjectQuat(dotWorldTransformQuat);
                            this._mapFinishLineMarker.pp_setTransformLocalQuat(dotTrackMiniMapWorldTransformQuat);
                            this._mapFinishLineMarker.pp_setActive(true);
                        } else {
                            const trackDirectionMarker = Globals.getObjectPoolManager()!.get<Object3D>(this._poolID)!;

                            const dotWorldTransformQuat = this._miniMapWorld.pp_convertTransformObjectToWorldQuat(dotMiniMapWorldTransformQuat);
                            const dotTrackMiniMapWorldTransformQuat = this._trackMiniMap.pp_convertTransformWorldToObjectQuat(dotWorldTransformQuat);
                            trackDirectionMarker.pp_setTransformLocalQuat(dotTrackMiniMapWorldTransformQuat);
                            trackDirectionMarker.pp_setActive(true);
                        }

                        this._currentVisualizationSplineTime += this.distanceBetweenPointsAsSplineTime;
                        if (this._currentVisualizationSplineTime - MathUtils.EPSILON >= 1) {
                            this._currentVisualizationSplineTime = 1;
                        }
                    }
                }
            } else if (this._currentTrackComponent.isSplineLoaded()) {
                this._initializeMiniMapVisualization();
            }
        }
    }

    private _initializeMiniMapVisualization() {
        this._currentVisualizationSplineTime = 0;
        this._visualizePointDelay.start();

        const spline = this._currentTrackComponent!.getSpline()!;
        const miniMapWorldScale = this._miniMapWorld.pp_getScale()[0];
        this.distanceBetweenPointsAsSplineTime = (MiniMapManager._distanceBetweenPoints / miniMapWorldScale) / spline.getLength();

        // Adjusting the distance so that it perfectly ends where it starts
        this.distanceBetweenPointsAsSplineTime = 1 / Math.round(1 / this.distanceBetweenPointsAsSplineTime);

        this._visualizationInitialized = true;
    }
}
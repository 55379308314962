import { Component, MeshComponent, Property } from "@wonderlandengine/api";
import { currentPlayerData } from "src/hoverfit/data/player-data.js";
import { boardMaterialIndexToConfigMaterialKey as assetMaterialIndexToConfigMaterialKey } from "src/hoverfit/data/values/board.js";
import { loadExternalObject, loadTexturesForMaterialList, setMaterialFromMaterialConfig } from "src/hoverfit/misc/asset-loading.js";
import { CatalogCategories } from "src/hoverfit/ui/kiosk/shop/shop-interface.js";
import common from "../../common.js";
import { Gender } from "../../data/values/gender.js";
import { HAIR_COLORS } from "../../data/values/headwear.js";
import { SKIN_COLORS, skinMaterialIndexToConfigMaterialKey } from "../../data/values/skin-color.js";
import { SUIT_COLORS } from "../../data/values/suit-color.js";

export class AvatarSelectorComponent extends Component {
    static TypeName = "avatar-selector";
    static Properties = {
        /* Female mesh properties */
        femaleBodyMesh: Property.mesh(null),
        femaleBodyMaterial: Property.material(null),
        femaleEyelashesMesh: Property.mesh(null),
        femaleEyelashesMaterial: Property.material(null),
        femaleHairMesh: Property.mesh(null),
        femaleHairMaterial: Property.material(null),
        femaleEyesMesh: Property.mesh(null),
        femaleEyesMaterial: Property.material(null),
        femaleTeethMesh: Property.mesh(null),
        femaleTeethMaterial: Property.material(null),
        femaleSuitMesh: Property.mesh(null),
        femaleSuitMaterial: Property.material(null),

        /* Male mesh properties */
        maleBodyMesh: Property.mesh(null),
        maleBodyMaterial: Property.material(null),
        maleEyelashesMesh: Property.mesh(null),
        maleEyelashesMaterial: Property.material(null),
        maleHairMesh: Property.mesh(null),
        maleHairMaterial: Property.material(null),
        maleEyesMesh: Property.mesh(null),
        maleEyesMaterial: Property.material(null),
        maleTeethMesh: Property.mesh(null),
        maleTeethMaterial: Property.material(null),
        maleSuitMesh: Property.mesh(null),
        maleSuitMaterial: Property.material(null),

        localAvatar: Property.object(),
    };

    init() {
        common.avatarSelector = this;
    }

    setAvatarType(type, avatar, shareToNetwork) {
        if (avatar.currentAvatarType === type) return;
        avatar.currentAvatarType = type;

        const body = avatar.bodyObject;

        if (avatar === currentPlayerData.avatar) {
            common.watchController.setWatchCursorOffset(type === Gender.Female);
        }

        let genderPrefix = type === Gender.Female ? "female" : "male";

        const bodyMesh = body.getComponent(MeshComponent, 0);
        bodyMesh.mesh = this[genderPrefix + "BodyMesh"];
        let bodyMeshMat = this[genderPrefix + "BodyMaterial" + bodyMesh.object.pp_getID() + "Cloned"];
        if (!bodyMeshMat) {
            this[genderPrefix + "BodyMaterial" + bodyMesh.object.pp_getID() + "Cloned"] = this[genderPrefix + "BodyMaterial"].clone();
            bodyMeshMat = this[genderPrefix + "BodyMaterial" + bodyMesh.object.pp_getID() + "Cloned"];
        }
        bodyMesh.material = bodyMeshMat;

        const bodyEyelashesMesh = body.getComponent(MeshComponent, 1);
        bodyEyelashesMesh.mesh = this[genderPrefix + "EyelashesMesh"];
        bodyEyelashesMesh.material = this[genderPrefix + "EyelashesMaterial"];

        const bodyHairMesh = body.getComponent(MeshComponent, 2);
        bodyHairMesh.mesh = this[genderPrefix + "HairMesh"];
        let bodyHairMeshMat = this[genderPrefix + "HairMaterial" + bodyHairMesh.object.pp_getID() + "Cloned"];
        if (!bodyHairMeshMat) {
            this[genderPrefix + "HairMaterial" + bodyHairMesh.object.pp_getID() + "Cloned"] = this[genderPrefix + "HairMaterial"].clone();
            bodyHairMeshMat = this[genderPrefix + "HairMaterial" + bodyHairMesh.object.pp_getID() + "Cloned"];
        }
        bodyHairMesh.material = bodyHairMeshMat;

        const eyes = avatar.eyesObject;
        const eyesMesh = eyes.getComponent(MeshComponent, 0);
        eyesMesh.mesh = this[genderPrefix + "EyesMesh"];
        eyesMesh.material = this[genderPrefix + "EyesMaterial"];

        const teethMesh = body.getComponent(MeshComponent, 3);

        if (teethMesh != null) {
            teethMesh.mesh = this[genderPrefix + "TeethMesh"];
            teethMesh.material = this[genderPrefix + "TeethMaterial"];
        }

        const suit = avatar.suitObject;
        const suitMesh = suit.getComponent(MeshComponent, 0);
        let suitMeshMat = this[genderPrefix + "SuitMaterial" + suitMesh.object.pp_getID() + "Cloned"];
        if (!suitMeshMat) {
            suitMesh.mesh = this[genderPrefix + "SuitMesh"];
            this[genderPrefix + "SuitMaterial" + suitMesh.object.pp_getID() + "Cloned"] = this[genderPrefix + "SuitMaterial"].clone();
            suitMeshMat = this[genderPrefix + "SuitMaterial" + suitMesh.object.pp_getID() + "Cloned"];
            suitMesh.material = suitMeshMat;
            this.setAdditionalSuitMeshesActive(suit, false);
        }

        // On avatar type switch, set the same settings for other avatar types
        this.setAvatarSkinColor(avatar.skinColorKey, avatar, false);
        this.setAvatarSuit(avatar.suitVariant, avatar, false);
        this.setAvatarHairColor(avatar.hairColorIndex, avatar, false);
        this.setAvatarHeadwear(avatar.headwearVariant, avatar, false);

        const hoverboardRoom = common.hoverboardNetworking.room;
        if (shareToNetwork && hoverboardRoom) hoverboardRoom.send("set-avatar-type", { type });
    }

    setAdditionalSuitMeshesActive(suitObject, active) {
        const suitMeshes = suitObject.pp_getComponentsSelf(MeshComponent);

        if (suitMeshes.length < 2) {
            const mesh1 = suitObject.addComponent(MeshComponent);
            mesh1.skin = suitMeshes[0].skin;
            const mesh2 = suitObject.addComponent(MeshComponent);
            mesh2.skin = suitMeshes[0].skin;
            suitMeshes.push(mesh1);
            suitMeshes.push(mesh2);
        }

        if (suitMeshes[1]) suitMeshes[1].active = active;
        if (suitMeshes[2]) suitMeshes[2].active = active;
    }

    setAvatarSkinColor(skinColorKey, avatar, shareToNetwork) {
        const body = avatar.bodyObject;
        const bodyMesh = body.getComponent(MeshComponent, 0);
        bodyMesh.material.diffuseColor = SKIN_COLORS[skinColorKey];
        avatar.skinColorKey = skinColorKey;

        const hoverboardRoom = common.hoverboardNetworking.room;
        if (shareToNetwork && hoverboardRoom) hoverboardRoom.send("set-skin-color", { color: skinColorKey });
    }

    setAvatarSuit(suitVariant, avatar, shareToNetwork) {
        if (SUIT_COLORS.includes(suitVariant) || SUIT_COLORS.length > suitVariant) {
            this.setAvatarSuitColor(suitVariant, avatar, shareToNetwork);
        } else {
            this.setAvatarSuit_External(suitVariant, avatar, common.kioskLowerUI.iapContentController.getSuitVariantConfiguration(suitVariant), shareToNetwork);
        }
    }

    setAvatarSuitColor(suitVariant, avatar, shareToNetwork) {
        const suit = avatar.suitObject;
        const suitMesh = suit.getComponent(MeshComponent, 0);
        const suitColors = SUIT_COLORS[suitVariant];

        let genderPrefix = avatar.currentAvatarType === Gender.Female ? "female" : "male";

        suitMesh.mesh = this[genderPrefix + "SuitMesh"];
        let suitMeshMat = this[genderPrefix + "SuitMaterial" + suitMesh.object.pp_getID() + "Cloned"];
        if (!suitMeshMat) {
            this[genderPrefix + "SuitMaterial" + suitMesh.object.pp_getID() + "Cloned"] = this[genderPrefix + "SuitMaterial"].clone();
            suitMeshMat = this[genderPrefix + "SuitMaterial" + suitMesh.object.pp_getID() + "Cloned"];
        }
        suitMesh.material = suitMeshMat;
        suitMesh.material.diffuseColor = suitColors.diffuseColor;
        suitMesh.material.emissiveColor = suitColors.emissiveColor;
        avatar.suitVariant = suitVariant;
        this.setAdditionalSuitMeshesActive(suit, false);

        const hoverboardRoom = common.hoverboardNetworking.room;
        if (shareToNetwork && hoverboardRoom) hoverboardRoom.send("set-suit-variant", { suitVariant });
    }

    setAvatarSuit_External(suitVariant, avatar, config, shareToNetwork) {
        const configObject = config;
        if (!configObject) return console.error("Can't load config ", suitVariant);

        const materialKeys = Object.keys(config.materials);

        const suit = avatar.suitObject;
        this.setAdditionalSuitMeshesActive(suit, true);
        const suitMeshes = suit.pp_getComponentsSelf(MeshComponent);

        loadTexturesForMaterialList(this.engine, materialKeys, config);

        let genderPrefix = avatar.currentAvatarType === Gender.Female ? "female" : "male";

        const promise = config["modelPromise" + genderPrefix] || loadExternalObject(this.engine, config["url" + genderPrefix.charAt(0).toUpperCase() + genderPrefix.slice(1)]);
        config["modelPromise" + genderPrefix] = promise;

        promise.then((suitRoot) => {
            const newSuitObject = suitRoot.pp_getObjectByName("suit", true);
            const newSuitMeshComponents = newSuitObject.pp_getComponentsSelf(MeshComponent);
            for (let i = 0; i < newSuitMeshComponents.length; i++) {
                const oldMeshComponent = suitMeshes[i];
                oldMeshComponent.mesh = newSuitMeshComponents[i].mesh;
                oldMeshComponent.active = true;
                setMaterialFromMaterialConfig(oldMeshComponent, config.materials[skinMaterialIndexToConfigMaterialKey[i]], true, this.engine);
            }
        });

        avatar.suitVariant = suitVariant;

        const hoverboardRoom = common.hoverboardNetworking.room;
        if (shareToNetwork && hoverboardRoom) hoverboardRoom.send("set-suit-variant", { suitVariant });
    }

    setAvatarHeadwear(headwearKey, avatar, shareToNetwork) {
        const helmetObject = avatar.getHelmetObject();
        if (HAIR_COLORS.includes(headwearKey) || HAIR_COLORS.length > headwearKey) {
            if (helmetObject) helmetObject.active = false;
            avatar.bodyObject.getComponent(MeshComponent, 2).active = true;
            this.setAvatarHairColor(headwearKey, avatar, shareToNetwork);
        } else {
            const config = common.kioskLowerUI.iapContentController.getHeadwearVariantConfiguration(headwearKey);
            if (config.type === CatalogCategories.Hair) {
                if (helmetObject) helmetObject.active = false;
                avatar.bodyObject.getComponent(MeshComponent, 2).active = true;
                this.setAvatarHair_External(headwearKey, avatar, config, shareToNetwork);
            } else {
                if (helmetObject) helmetObject.active = true;
                avatar.bodyObject.getComponent(MeshComponent, 2).active = false;
                this.setAvatarHelmet_External(headwearKey, avatar, config, shareToNetwork);
            }
        }
    }

    setAvatarHairColor(hairColorIndex, avatar, shareToNetwork) {
        const body = avatar.bodyObject;
        const bodyHairMesh = body.getComponent(MeshComponent, 2);
        bodyHairMesh.material.diffuseColor = HAIR_COLORS[hairColorIndex].diffuseColor;
        avatar.headwearVariant = hairColorIndex;
        // TODO: Set hair color seperately
        avatar.hairColorIndex = hairColorIndex;

        let genderPrefix = avatar.currentAvatarType === Gender.Female ? "female" : "male";

        bodyHairMesh.mesh = this[genderPrefix + "HairMesh"];
        let bodyHairMeshMat = this[genderPrefix + "HairMaterial" + bodyHairMesh.object.pp_getID() + "Cloned"];
        if (!bodyHairMeshMat) {
            this[genderPrefix + "HairMaterial" + bodyHairMesh.object.pp_getID() + "Cloned"] = this[genderPrefix + "HairMaterial"].clone();
            bodyHairMeshMat = this[genderPrefix + "HairMaterial" + bodyHairMesh.object.pp_getID() + "Cloned"];
        }
        bodyHairMesh.material = bodyHairMeshMat;

        const hoverboardRoom = common.hoverboardNetworking.room;
        // TODO: Set hair color as well
        if (shareToNetwork && hoverboardRoom) {
            hoverboardRoom.send("set-hair-color", { color: Number(hairColorIndex) });
            hoverboardRoom.send("set-headwear-variant", { headwearVariant: hairColorIndex });
        }

    }

    setAvatarHair_External(headwearKey, avatar, config, shareToNetwork) {
        const body = avatar.bodyObject;
        const bodyHeadwearMesh = body.getComponent(MeshComponent, 2);
        // TODO: Set hair color as well
        avatar.headwearVariant = headwearKey;

        let genderPrefix = avatar.currentAvatarType === Gender.Female ? "female" : "male";

        const promise = config["modelPromise" + genderPrefix] || loadExternalObject(this.engine, config["url" + genderPrefix.charAt(0).toUpperCase() + genderPrefix.slice(1)]);
        config["modelPromise" + genderPrefix] = promise;

        promise.then((headwearRoot) => {
            bodyHeadwearMesh.mesh = headwearRoot.pp_getComponent(MeshComponent).mesh;
        });

        const hoverboardRoom = common.hoverboardNetworking.room;
        if (shareToNetwork && hoverboardRoom) hoverboardRoom.send("set-headwear-variant", { headwearVariant: headwearKey });
    }

    setAvatarHelmet_External(headwearKey, avatar, config, shareToNetwork) {
        const helmetObject = avatar.getHelmetObject();
        if (!helmetObject) return;

        avatar.headwearVariant = headwearKey;

        // Load textures
        const materialKeys = Object.keys(config.materials);
        loadTexturesForMaterialList(this.engine, materialKeys, config);

        const promise = config.modelPromise || loadExternalObject(this.engine, config.url);
        config.modelPromise = promise;

        promise.then((helmetRoot) => {
            const mesh0 = helmetObject.getComponent(MeshComponent, 0);
            const mesh1 = helmetObject.getComponent(MeshComponent, 1);
            mesh0.mesh = helmetRoot.pp_getComponent(MeshComponent, 0).mesh;
            mesh1.mesh = helmetRoot.pp_getComponent(MeshComponent, 1).mesh;
            setMaterialFromMaterialConfig(mesh0, config.materials[assetMaterialIndexToConfigMaterialKey[0]], true, this.engine);
            setMaterialFromMaterialConfig(mesh1, config.materials[assetMaterialIndexToConfigMaterialKey[1]], true, this.engine);
            helmetObject.resetTransform();
        });

        const hoverboardRoom = common.hoverboardNetworking.room;
        if (shareToNetwork && hoverboardRoom) hoverboardRoom.send("set-headwear-variant", { headwearVariant: headwearKey });
    }
}

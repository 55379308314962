import { Component, MeshComponent, Property } from "@wonderlandengine/api";
import { loadExternalObject, loadTexturesForMaterialList, setMaterialFromMaterialConfig, setMaterialLightEmissiveDiffuseColor, setMaterialStandardEmissiveDiffuseColor } from "src/hoverfit/misc/asset-loading.js";
import { Globals, quat2_create, vec3_create } from "wle-pp";
import common from "../../../common.js";
import { BOARD_TEXTURE_DATA, boardMaterialIndexToConfigMaterialKey, getRandomBoard } from "../../../data/values/board.js";
import { HoverboardComponent } from "./hoverboard-component.js";

const schema = BOARD_TEXTURE_DATA.reduce((obj, item) => Object.assign(obj, { [item.diffuseTextureKey]: Property.texture(), [item.normalTextureKey]: Property.texture() }), {});

const tempDualQuat = quat2_create();

let defaultBoardData = {};

export class HoverboardSelectorComponent extends Component {
    static TypeName = "hoverboard-selector";
    static Properties = schema;

    start() {
        this.hoverboard = Globals.getRootObject(this.engine).pp_getComponent(HoverboardComponent);

        common.hoverboardSelector = this;
        this.copiedMaterialObjectIds = [];
        defaultBoardData = {};
    }

    setHoverboard(hoverboardVariant, hoverboardObject, isConfigBoard, shareToNetwork) {
        if (!isNaN(hoverboardVariant)) {
            this.setFromIndex(hoverboardVariant, hoverboardObject, isConfigBoard, shareToNetwork);
        } else if (common.kioskLowerUI.iapContentController) {
            this.setFromNameExternal(hoverboardVariant, hoverboardObject, common.kioskLowerUI.iapContentController.getHoverboardVariantConfiguration(hoverboardVariant), isConfigBoard, shareToNetwork);
        }
    }

    setFromNameExternal(hoverboardVariant, hoverboardObject, externalBoardConfigObject, isConfigBoard, shareToNetwork) {
        const boardConfigObject = externalBoardConfigObject;
        if (!boardConfigObject) return console.error("Can't load board ", hoverboardVariant);

        this.setDefaultMeshData(hoverboardObject);

        // Load textures
        const materialKeys = Object.keys(boardConfigObject.materials);
        loadTexturesForMaterialList(this.engine, materialKeys, boardConfigObject);

        const adjustedHoverboardObject = hoverboardObject.pp_getComponent(MeshComponent).object;
        const meshComponents = adjustedHoverboardObject.pp_getComponentsSelf(MeshComponent);
        const streamObjects = hoverboardObject.pp_getObjectsByName("Stream", true);
        const logoObject = hoverboardObject.pp_getObjectByName("Logo", true);

        const promise = boardConfigObject.modelPromise || loadExternalObject(this.engine, boardConfigObject.url);
        boardConfigObject.modelPromise = promise;
        promise.then((boardRoot) => {
            const newHoverboardObject = boardRoot.pp_getObjectByName("Hoverboard", true);
            const newHoverboardMeshComponents = newHoverboardObject.pp_getComponentsSelf(MeshComponent);
            const newStreams = newHoverboardObject.pp_getObjectsByName("Stream", true);

            for (let i = 0; i < newHoverboardMeshComponents.length; i++) {
                const oldMeshComponent = meshComponents[i];
                oldMeshComponent.mesh = newHoverboardMeshComponents[i].mesh;
                setMaterialFromMaterialConfig(oldMeshComponent, boardConfigObject.materials[boardMaterialIndexToConfigMaterialKey[i]], isConfigBoard, this.engine);
            }

            let streamMaterial;
            for (let i = 0; i < streamObjects.length; i++) {
                const streamObject = streamObjects[i];
                const streamMeshComponent = streamObject.pp_getComponent(MeshComponent);
                if (i == 0) {
                    streamMaterial = streamMeshComponent.material;
                    streamMaterial.color = boardConfigObject.jetstreamColor;
                } else {
                    streamMeshComponent.material = streamMaterial;
                }

                if (i < newStreams.length) {
                    streamMeshComponent.active = true;
                    streamMeshComponent.mesh = newStreams[i].pp_getComponent(MeshComponent).mesh;
                    newStreams[i].getTransformLocal(tempDualQuat);
                    streamObject.setTransformLocal(tempDualQuat);
                } else {
                    streamMeshComponent.mesh = null;
                }
            }

            logoObject.setPositionLocal(boardConfigObject.logoLocalPosition || defaultBoardData.logoLocalPosition);
        });

        const hoverboardRoom = common.hoverboardNetworking.room;
        if (shareToNetwork && hoverboardRoom != null) {
            hoverboardRoom.send("set-hoverboard-variant", { hoverboardVariant });
        }
    }

    setFromIndex(hoverboardIndex, hoverboardObject, isConfigBoard, shareToNetwork) {
        let adjustedHoverboardObject = hoverboardObject.pp_getComponent(MeshComponent).object;
        let meshComponents = adjustedHoverboardObject.pp_getComponentsSelf(MeshComponent);

        const hoverboardData = BOARD_TEXTURE_DATA[hoverboardIndex];

        this.applyDefaultMeshData(hoverboardObject);

        for (let meshComponent of meshComponents) {
            if (meshComponent.material.diffuseTexture != null) {
                meshComponent.material.diffuseTexture = this[hoverboardData.diffuseTextureKey];
                meshComponent.material.normalTexture = this[hoverboardData.normalTextureKey];
                if (isConfigBoard) {
                    setMaterialLightEmissiveDiffuseColor(meshComponent.material, null);
                } else {
                    setMaterialStandardEmissiveDiffuseColor(meshComponent.material);
                }
            } else if (meshComponent.material.diffuseColor != null) {
                if (isConfigBoard) {
                    setMaterialLightEmissiveDiffuseColor(meshComponent.material, hoverboardData.metalDiffuseColor);
                } else {
                    setMaterialStandardEmissiveDiffuseColor(meshComponent.material);
                    meshComponent.material.diffuseColor = hoverboardData.metalDiffuseColor;
                }
            } else {
                meshComponent.material.color = hoverboardData.jetstreamColor;
            }
        }

        let streamObjects = adjustedHoverboardObject.pp_getObjectsByName("Stream", true);
        for (const streamObject of streamObjects) {
            let meshComponent = streamObject.pp_getComponent(MeshComponent);
            if (defaultBoardData.streamMesh) meshComponent.mesh = defaultBoardData.streamMesh;
            meshComponent.material.color = hoverboardData.jetstreamColor;
        }

        const hoverboardRoom = common.hoverboardNetworking.room;
        if (shareToNetwork && hoverboardRoom != null) {
            hoverboardRoom.send("set-hoverboard-variant", { hoverboardVariant: String(hoverboardIndex) });
        }
    }

    setRandomHoverboard(hoverboardObject) {
        this.setFromIndex(hoverboardObject, getRandomBoard(), false);
    }

    applyDefaultMeshData(rootMeshObject) {
        if (!defaultBoardData.mesh0) return;

        rootMeshObject.pp_getComponent(MeshComponent, 0).mesh = defaultBoardData.mesh0;
        rootMeshObject.pp_getComponent(MeshComponent, 0).material = defaultBoardData.material0;
        rootMeshObject.pp_getComponent(MeshComponent, 1).mesh = defaultBoardData.mesh1;
        rootMeshObject.pp_getComponent(MeshComponent, 1).material = defaultBoardData.material1;
        rootMeshObject.pp_getComponent(MeshComponent, 2).mesh = defaultBoardData.mesh2;
        rootMeshObject.pp_getComponent(MeshComponent, 2).material = defaultBoardData.material2;
        rootMeshObject.pp_getComponent(MeshComponent, 3).mesh = defaultBoardData.mesh3;
        rootMeshObject.pp_getComponent(MeshComponent, 3).material = defaultBoardData.material3;
        const streamObjects = rootMeshObject.pp_getObjectsByName("Stream", true);
        for (let i = 0; i < streamObjects.length; i++) {
            const streamObject = streamObjects[i];
            let meshComponent = streamObject.pp_getComponent(MeshComponent);
            meshComponent.mesh = defaultBoardData.streamMesh;
            streamObject.setTransformLocal(defaultBoardData["streamTransform" + i]);
        }
        const LogoObject = rootMeshObject.pp_getObjectByName("Logo", true);
        LogoObject.setPositionLocal(defaultBoardData.logoLocalPosition);
    }

    setDefaultMeshData(rootMeshObject) {
        if (defaultBoardData.mesh0) return;

        defaultBoardData.mesh0 = rootMeshObject.pp_getComponent(MeshComponent, 0).mesh;
        defaultBoardData.material0 = rootMeshObject.pp_getComponent(MeshComponent, 0).material;
        defaultBoardData.mesh1 = rootMeshObject.pp_getComponent(MeshComponent, 1).mesh;
        defaultBoardData.material1 = rootMeshObject.pp_getComponent(MeshComponent, 1).material;
        defaultBoardData.mesh2 = rootMeshObject.pp_getComponent(MeshComponent, 2).mesh;
        defaultBoardData.material2 = rootMeshObject.pp_getComponent(MeshComponent, 2).material;
        defaultBoardData.mesh3 = rootMeshObject.pp_getComponent(MeshComponent, 3).mesh;
        defaultBoardData.material3 = rootMeshObject.pp_getComponent(MeshComponent, 3).material;
        const defaultStreams = rootMeshObject.pp_getObjectsByName("Stream", true);
        defaultBoardData.streamMesh = defaultStreams[0].pp_getComponent(MeshComponent).mesh;

        for (let i = 0; i < defaultStreams.length; i++) {
            const defaultStream = defaultStreams[i];
            defaultBoardData["streamTransform" + i] = defaultStream.getTransformLocal(quat2_create());
        }

        const defaultLogo = rootMeshObject.pp_getObjectByName("Logo", true);
        defaultBoardData.logoLocalPosition = defaultLogo.getPositionLocal(vec3_create());
    }
}
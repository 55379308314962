import { Gender } from "../data/values/gender.js";

const maleNames = ["Aiden", "Rex", "Logan", "Viper", "Niko", "Dante", "Max", "Cole", "Ryu", "Jaxon"];
const femaleNames = ["Lyra", "Nova", "Elara", "Cobra", "Vega", "Ember", "Luna", "Mira", "Nyx", "Zara"];

export function getRandomName(setup) {
    if (setup.avatarType === Gender.Male) {
        return maleNames[Math.floor(Math.random() * maleNames.length)];
    } else {
        return femaleNames[Math.floor(Math.random() * femaleNames.length)];
    }
}
import { Alignment, Button, ClickState, LeaveEvent, PointerMoveEvent, PointerPressEvent, PointerReleaseEvent, Rect, Widget, WidgetAutoXML, WidgetEvent, WidgetProperties, safeRoundRect, type Alignment2D } from "lazy-widgets";
import { AudioID } from "../../../audio/audio-manager/audio-id.js";
import { Audio } from "../../../audio/audio-manager/audio.js";
import common from "../../../common.js";
import { downHapticFeedback, hoverHapticFeedback, upHapticFeedback } from "../../../misc/haptic-feedback.js";
import { getCursorFromLZCID } from "../get-cursor-from-lzcid.js";

const BADGE_MARGIN = 2;
const BADGE_LENGTH_PERCENT = 0.4;
const BADGE_TILT_RAD = 0.3490659; // 20 deg
const BADGE_TILT_DURATION_MS = 400;
const BADGE_TILT_SWINGS = 3;

const BADGE_TILT_ALPHA_MUL = Math.PI * 2 * BADGE_TILT_SWINGS / 2;

let LOCK_SHAPE: Path2D;
if (WL_EDITOR) {
    LOCK_SHAPE = null as unknown as Path2D;
} else {
    LOCK_SHAPE = new Path2D("M 0.1 0.5 L 0.1 1 L 0.9 1 L 0.9 0.5 L 0.8 0.5 L 0.8 0.3 C 0.8 -0.1 0.2 -0.1 0.2 0.3 L 0.2 0.5 Z M 0.3 0.5 L 0.7 0.5 L 0.7 0.3 C 0.7 0.05 0.3 0.05 0.3 0.3 Z");
}

export enum DecoratedButtonBadge {
    Lock = "lock",
}

export interface DecoratedButtonProperties extends WidgetProperties {
    badge?: DecoratedButtonBadge | null;
    fontSize?: number;
    hasBorder?: boolean;
    clickAudioIdOverride?: string;
}

export abstract class BaseDecoratedButton<W extends Widget = Widget> extends Button<W> {
    static override autoXML = null as unknown as WidgetAutoXML;

    private curFill = "white";
    private _curState = ClickState.Released;
    private _prevState = ClickState.Released;
    private clickSound: Audio | null;
    private hoverSound: Audio | null;
    private inBounds = false;
    private badgeInBounds = false;
    protected _forced = false;
    private _badge: DecoratedButtonBadge | null;
    private badgeAngle = 0;
    private attentionStart = -1;
    private _hasBorder = true;

    constructor(child: W, properties?: Readonly<DecoratedButtonProperties>) {
        super(
            child,
            {
                containerAlignment: <Alignment2D>{
                    horizontal: Alignment.Center, vertical: Alignment.Stretch,
                },
                roundedCornersRadii: 3,
                ...properties,
            },
        );

        const audioIdString = properties?.clickAudioIdOverride || "BUTTON_CLICK";
        this.clickSound = common.audioManager.getAudio(AudioID[audioIdString as keyof typeof AudioID]);
        this.hoverSound = common.audioManager.getAudio(AudioID.BUTTON_HOVER);

        this._badge = properties?.badge ?? null;
        this._hasBorder = properties?.hasBorder ?? true;
    }

    get badge() {
        return this._badge;
    }

    set badge(badge) {
        if (this._badge === badge) return;
        this._badge = badge;
        this.markWholeAsDirty();
    }

    get hasBorder() {
        return this._hasBorder;
    }

    set hasBorder(hasBorder) {
        if (this._hasBorder === hasBorder) return;
        this._hasBorder = hasBorder;
        this.markWholeAsDirty();
    }

    get forced() {
        return this._forced;
    }

    set forced(forced) {
        if (this._forced === forced) return;
        this._forced = forced;
        this.markWholeAsDirty();
    }

    override click() {
        if (this.clickSound) {
            this.clickSound.play();
        }

        super.click();
    }

    private updateBadgeInBounds(badgeInBounds: boolean) {
        if (this.badgeInBounds === badgeInBounds) return;
        this.badgeInBounds = badgeInBounds;
        if (badgeInBounds) this.giveBadgeAttention();
    }

    giveBadgeAttention() {
        const now = performance.now();
        if (now - this.attentionStart >= BADGE_TILT_DURATION_MS) {
            this.attentionStart = now;
            this.markWholeAsDirty();
        }
    }

    protected override handleEvent(event: WidgetEvent) {
        const captured = super.handleEvent(event);

        if (this.clickHelper.clickStateChanged) {
            this._curState = this.clickHelper.clickState;
        }

        if (this.active && this.clickable) {
            if (event.isa(PointerMoveEvent)) {
                this.updateBadgeInBounds(true);
                if (!this.inBounds) {
                    this.inBounds = true;
                    this.hoverSound!.play();
                    const cursor = getCursorFromLZCID(event);
                    if (cursor) hoverHapticFeedback(cursor);
                }
            } else if (event.isa(LeaveEvent)) {
                this.updateBadgeInBounds(false);
                this.inBounds = false;
            } else if (event.isa(PointerPressEvent)) {
                this.giveBadgeAttention();
                const cursor = getCursorFromLZCID(event);
                if (cursor) downHapticFeedback(cursor);
            } else if (event.isa(PointerReleaseEvent)) {
                const cursor = getCursorFromLZCID(event);
                if (cursor) upHapticFeedback(cursor);
            }
        } else {
            if (event.isa(PointerMoveEvent)) {
                this.updateBadgeInBounds(true);
            } else if (event.isa(LeaveEvent)) {
                this.updateBadgeInBounds(false);
                this.inBounds = false;
            } else if (event.isa(PointerPressEvent)) {
                this.giveBadgeAttention();
            }
        }

        return captured;
    }

    override set clickable(clickable) {
        if (!clickable) {
            this.inBounds = false;
        }

        const oldClickable = this.clickable;
        super.clickable = clickable;
        if (clickable !== oldClickable) {
            this._curState = this.clickHelper.clickState;
            this.handleClickStateChange();
        }
    }

    override get clickable() {
        return super.clickable;
    }

    protected handleClickStateChange() {
        this._prevState = this._curState;
        const oldFill = this.curFill;
        switch (this._curState) {
            case ClickState.Hover:
            case ClickState.Hold:
                this.curFill = "rgb(231,231,241)";
                break;
            default:
                this.curFill = "white";
                break;
        }

        if (oldFill !== this.curFill) this.markWholeAsDirty();
    }

    protected override activate() {
        super.activate();
        this.inBounds = false;
        this._curState = this.clickHelper.clickState;
        this.handleClickStateChange();
    }

    protected override deactivate() {
        super.deactivate();
        this.inBounds = false;
        this._curState = this.clickHelper.clickState;
        this.handleClickStateChange();
    }

    protected override handlePostLayoutUpdate() {
        super.handlePostLayoutUpdate();
        if (this._curState !== this._prevState) this.handleClickStateChange();

        let newAngle = 0;
        if (this.attentionStart >= 0) {
            const now = performance.now();
            const alphaUnscaled = now - this.attentionStart;
            if (alphaUnscaled < BADGE_TILT_DURATION_MS) {
                // badge tilt formula:
                // https://www.desmos.com/calculator/osezxrduju
                // x = alphaUnscaled
                // d = BADGE_TILT_DURATION_MS / 1000
                // t = BADGE_TILT_RAD
                const alpha = alphaUnscaled / BADGE_TILT_DURATION_MS;
                newAngle = Math.sin(alpha * BADGE_TILT_ALPHA_MUL) * Math.sin(alpha * Math.PI) * BADGE_TILT_RAD;
            }
        }

        if (this.badgeAngle !== newAngle) {
            this.badgeAngle = newAngle;
            this.markWholeAsDirty();
        }
    }

    protected paintBadge(ctx: CanvasRenderingContext2D) {
        switch (this._badge) {
            case DecoratedButtonBadge.Lock:
                ctx.fillStyle = "gold";
                ctx.fill(LOCK_SHAPE, "evenodd");
                ctx.strokeStyle = "brown";
                ctx.lineWidth = 0.05;
                ctx.stroke(LOCK_SHAPE);
                break;
        }
    }

    protected override handlePainting(dirtyRects: Rect[]): void {
        const ctx = this.viewport.context;
        ctx.save();
        ctx.beginPath();
        safeRoundRect(ctx, this.x, this.y, this.width, this.height, this.roundedCornersRadii);
        ctx.clip();

        ctx.fillStyle = this.curFill;
        ctx.fillRect(this.x, this.y, this.width, this.height);

        super.handlePainting(dirtyRects);

        if (this._hasBorder) {
            ctx.beginPath();
            safeRoundRect(ctx, this.x, this.y, this.width, this.height, this.roundedCornersRadii);
            const baseLineColor = this._forced ? "0,192,255" : (this.clickable ? "0,0,64" : "128,128,128");
            ctx.strokeStyle = `rgba(${baseLineColor},0.8)`;
            ctx.lineWidth = this._forced ? 6 : 2;
            ctx.stroke();
        }

        ctx.restore();

        const badge = this._badge;
        if (badge !== null) {
            ctx.save();

            const badgeLength = Math.min(this.width, this.height) * BADGE_LENGTH_PERCENT;
            const halfBadgeLength = badgeLength * 0.5;
            const badgeOffset = halfBadgeLength + BADGE_MARGIN;
            const cxOffset = this.x + this.width - badgeOffset;
            const cyOffset = this.y + this.height - badgeOffset;
            if (this.badgeAngle === 0) {
                ctx.translate(cxOffset - halfBadgeLength, cyOffset - halfBadgeLength);
            } else {
                ctx.translate(cxOffset, cyOffset);
                ctx.rotate(this.badgeAngle);
                ctx.translate(-halfBadgeLength, -halfBadgeLength);
            }

            ctx.scale(badgeLength, badgeLength);
            this.paintBadge(ctx);

            ctx.restore();
        }
    }
}
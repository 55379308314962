import { Component, Property } from '@wonderlandengine/api';
import anime from "animejs";
import { getTime } from "src/hoverfit/utils/time-utils.js";
import { Timer, vec4_create } from "wle-pp";

export class AvatarFaceComponent extends Component {
    static TypeName = 'avatar-face';
    /* Properties that are configurable in the editor */
    static Properties = {
        lowerJaw: Property.object(),
        lowerJawMaxXRotation: Property.float(12.0),
        upperEyeLidLeft: Property.object(),
        upperEyeLidLeftMaxXRotation: Property.float(120.0),
        upperEyeLidRight: Property.object(),
        upperEyeLidRightMaxXRotation: Property.float(120.0),
        randomBlink: Property.bool(true),
        blinkDuration: Property.float(0.3),
        blinkTimerBaseDelay: Property.float(3.5),
        blinkTimerRandomAdditionalDelayOffset: Property.float(0.5),
    };

    lowerJawDefaultRotation = vec4_create();
    upperEyeLidLeftDefaultRotation = vec4_create();
    upperEyeLidRightDefaultRotation = vec4_create();

    start() {
        this.lowerJaw.getRotationLocal(this.lowerJawDefaultRotation);
        this.upperEyeLidLeft.getRotationLocal(this.upperEyeLidLeftDefaultRotation);
        this.upperEyeLidRight.getRotationLocal(this.upperEyeLidRightDefaultRotation);

        this.blinkTimer = new Timer(this.blinkTimerBaseDelay + (Math.random() * this.blinkTimerRandomAdditionalDelayOffset) - (this.blinkTimerRandomAdditionalDelayOffset / 2));
    }

    setMouthOpenness(amount) {
        this.lowerJaw.resetRotation();
        this.lowerJaw.setRotationLocal(this.lowerJawDefaultRotation);
        this.lowerJaw.rotateAxisAngleDegObject([1, 0, 0], amount * this.lowerJawMaxXRotation);
    }

    _restartBlinkTimer() {
        this.blinkTimer.start(this.blinkTimerBaseDelay + (Math.random() * this.blinkTimerRandomAdditionalDelayOffset) - (this.blinkTimerRandomAdditionalDelayOffset / 2));
    }

    blink() {
        this.blinkAmount = 0.0;
        let loopCount = 0;
        this.blinkAnim = anime({
            targets: this,
            easing: "easeOutQuad",
            blinkAmount: 1.0,
            duration: this.blinkDuration * 500,
            loop: 1,
            direction: "alternate",
            autoplay: false,
            update: (anim) => {
                if (this.blinkAnim != null) {
                    this.upperEyeLidLeft.resetRotation();
                    this.upperEyeLidLeft.setRotationLocal(this.upperEyeLidLeftDefaultRotation);
                    this.upperEyeLidLeft.rotateAxisAngleDegObject([1, 0, 0], this.blinkAmount * this.upperEyeLidLeftMaxXRotation);

                    this.upperEyeLidRight.resetRotation();
                    this.upperEyeLidRight.setRotationLocal(this.upperEyeLidRightDefaultRotation);
                    this.upperEyeLidRight.rotateAxisAngleDegObject([1, 0, 0], this.blinkAmount * this.upperEyeLidRightMaxXRotation);
                }
            },
            loopComplete: (anim) => {
                loopCount++;
                if (loopCount === 2) {
                    this.upperEyeLidLeft.resetRotation();
                    this.upperEyeLidRight.resetRotation();

                    this.blinkAnim = null;
                }
            }
        });

    }

    update(dt) {
        if (this.blinkAnim) this.blinkAnim.tick(getTime());
        this.blinkTimer.update(dt);

        if (this.blinkTimer.isDone()) {
            this._restartBlinkTimer();
            this.isBlinking = true;
            this.blink();
        }
    }
}

import { Component, MeshComponent, Property } from "@wonderlandengine/api";
import { EasingFunction, EasyTuneNumber, EasyTuneNumberArray, Globals, MathUtils, ObjectCloneParams, ObjectPool, ObjectPoolParams, Timer, Vec3Utils, vec3_create } from "wle-pp";
import common from "../../../common.js";
import { GameGlobals } from "../../../misc/game-globals.js";
import { EasyTuneExtraParamsSet, HoverboardDebugs } from "../../components/hoverboard-debugs-component.js";

export class DynamicChevronComponent extends Component {
    static TypeName = "dynamic-chevrons";

    static Properties = {
        chevronPositionType: Property.enum(["floor", "side"], "floor"),
        chevronsTrailLength: Property.float(200), // actually half length, but easier to understand this way for user I hope
        gapBetweenChevrons: Property.float(5.0),
        chevronMaxAlpha: Property.float(0.95),
        visibleFromBalcony: Property.bool(false),
        xOffset: Property.float(0),
        yOffset: Property.float(0)
    };

    init() {
        this.prepared = false;

        if (!this.active) {
            this.chevronPrototypesParent = this.object.pp_getChildren()[0];
            let chevronsPrototypes = this.chevronPrototypesParent.pp_getChildren();
            for (let chevronPrototype of chevronsPrototypes) {
                chevronPrototype.pp_setActive(false);
            }
        }
    }

    prepareChevrons() {
        if (this.prepared) return;
        this.prepared = true;

        this.tempVec = vec3_create();

        let chevronsAmount = (Math.ceil(this.chevronsTrailLength / this.gapBetweenChevrons) * 2) + 1;

        let chevronsPoolAmount = 0;
        if (this.chevronPositionType == 0) {
            chevronsPoolAmount = chevronsAmount;
        } else {
            chevronsPoolAmount = chevronsAmount * 2;
        }

        let poolParams = new ObjectPoolParams();
        poolParams.myInitialPoolSize = chevronsPoolAmount;
        poolParams.myPercentageToAddWhenEmpty = 0;
        poolParams.myAmountToAddWhenEmpty = 1;

        poolParams.myCloneParams = new ObjectCloneParams();

        this.dynamicChevronPoolIDs = [];
        this.dynamicChevronMaterials = [];

        // To make sure the last chevrons are completely faded I remove a bit from the trail length distance
        // We should actually change maxAlpha to also fade to 0 based on how far the last chevron is
        let maxDistance = this.chevronsTrailLength * 0.85;

        this.chevronPrototypesParent = this.object.pp_getChildren()[0];
        let chevronsPrototypes = this.chevronPrototypesParent.pp_getChildren();
        for (let chevronPrototype of chevronsPrototypes) {
            chevronPrototype.pp_setActive(false);

            let chevronPoolID = "dynamic_chevron_indicators_" + MathUtils.randomUUID();
            this.dynamicChevronPoolIDs.push(chevronPoolID);

            let chevronPrototypeMeshs = chevronPrototype.pp_getComponents(MeshComponent);
            for (const chevronPrototypeMesh of chevronPrototypeMeshs) {
                chevronPrototypeMesh.material = chevronPrototypeMesh.material.clone();

                chevronPrototypeMesh.material.maxAlpha = 0;
                chevronPrototypeMesh.material.minDistance = 5;
                chevronPrototypeMesh.material.maxDistance = maxDistance;
                chevronPrototypeMesh.material.currentMaxDistanceFactor = 0;

                this.dynamicChevronMaterials.push(chevronPrototypeMesh.material);
            }

            Globals.getObjectPoolManager(this.engine).addPool(chevronPoolID, new ObjectPool(chevronPrototype, poolParams));

        }

        this.floorChevrons = [];
        this.leftSideChevrons = [];
        this.rightSideChevrons = [];

        this.animTimer = new Timer(1, false);

        this.activated = false;

        this.chevronsScaleLocal = vec3_create(1, 1, 1);
        this.chevronsOffsetLocal = vec3_create(this.xOffset, this.yOffset, 0);

        this.easyTuneEnabled = false;

        if (this.easyTuneEnabled) {
            this.easyTuneVariablesChanged = false;
            this._setupEasyTuneVariables();
        }
    }

    resetChevrons() {
        if (!this.prepared) {
            this.prepareChevrons();
        }

        this.object.pp_setActive(false);
        this.active = true;

        this.activated = false;

        this.animTimer.reset();

        let maxDistance = this.chevronsTrailLength * 0.85;

        for (let chevronMaterial of this.dynamicChevronMaterials) {
            chevronMaterial.maxAlpha = 0;
            chevronMaterial.currentMaxDistanceFactor = 0;
            chevronMaterial.minDistance = 5;
            chevronMaterial.maxDistance = Math.max(5, maxDistance);
        }

        for (let i = 0; i < this.leftSideChevrons.length; i++) {
            const leftSideChevron = this.leftSideChevrons[i];
            const rightSideChevron = this.rightSideChevrons[i];

            Globals.getObjectPoolManager(this.engine).release(leftSideChevron);
            Globals.getObjectPoolManager(this.engine).release(rightSideChevron);
        }

        for (let i = 0; i < this.floorChevrons.length; i++) {
            const floorChevron = this.floorChevrons[i];

            Globals.getObjectPoolManager(this.engine).release(floorChevron);
        }

        this.floorChevrons = [];
        this.leftSideChevrons = [];
        this.rightSideChevrons = [];
    }

    initializeChevrons() {
        if (!this.visibleFromBalcony && common.balcony.isPlayerOnBalcony) return;
        if (!common.tracksManager.getCurrentTrack().hasSpline()) return;

        this.resetChevrons();

        const spline = common.tracksManager.getCurrentTrack().getSpline();

        let chevronsAmount = (Math.ceil(this.chevronsTrailLength / this.gapBetweenChevrons) * 2) + 1;

        this.chevronDistanceAsSplineTime = this.gapBetweenChevrons / spline.getLength();
        this.normalizedChevronTrailLength = this.chevronDistanceAsSplineTime * (chevronsAmount - 1);

        const spawnPositionProvider = common.tracksManager.getCurrentTrack().getSpawnPositionProvider();
        let spawnPositionSplineTime = spawnPositionProvider.getSpawnPositionSplineTime();
        if (Globals.isDebugEnabled() && HoverboardDebugs.saveSpawnPositionShortcutEnabled && spawnPositionProvider.getDebugSpawnPosition() != null) {
            spawnPositionSplineTime = spline.getClosestTime(spawnPositionProvider.getDebugSpawnPosition().quat2_getPosition());
        }

        this.middleChevronSplineTime = spawnPositionSplineTime;

        let currentChevronSplineTime = spawnPositionSplineTime - this.normalizedChevronTrailLength / 2;
        currentChevronSplineTime = spline.clampTime(currentChevronSplineTime);

        let currentPoolIndex = 0;

        for (let i = 0; i < chevronsAmount; i++) {
            const chevronsPool = Globals.getObjectPoolManager(this.engine).getPool(this.dynamicChevronPoolIDs[currentPoolIndex]);
            currentPoolIndex = (currentPoolIndex + 1) % this.dynamicChevronPoolIDs.length;

            const chevronForward = spline.getForward(currentChevronSplineTime);
            const chevronPosition = spline.getPosition(currentChevronSplineTime);
            if (this.chevronPositionType == 0) {
                const floorChevron = chevronsPool.get();

                floorChevron.pp_resetScaleLocal();

                floorChevron.pp_setForward(chevronForward, GameGlobals.up);

                floorChevron.setPositionWorld(chevronPosition);
                Vec3Utils.set(this.tempVec, this.chevronsOffsetLocal[0], this.chevronsOffsetLocal[1], this.chevronsOffsetLocal[2]);
                floorChevron.translateObject(this.tempVec);

                floorChevron.pp_setScaleLocal(this.chevronsScaleLocal);

                floorChevron.pp_setActive(true);

                this.floorChevrons.push(floorChevron);
            } else {
                const leftSideChevron = chevronsPool.get();
                const rightSideChevron = chevronsPool.get();

                leftSideChevron.pp_resetScaleLocal();
                rightSideChevron.pp_resetScaleLocal();

                leftSideChevron.pp_setForward(chevronForward, GameGlobals.up);
                rightSideChevron.pp_setForward(chevronForward, GameGlobals.up);

                leftSideChevron.setPositionWorld(chevronPosition);
                Vec3Utils.set(this.tempVec, -this.chevronsOffsetLocal[0], this.chevronsOffsetLocal[1], this.chevronsOffsetLocal[2]);
                leftSideChevron.translateObject(this.tempVec);

                rightSideChevron.setPositionWorld(chevronPosition);
                Vec3Utils.set(this.tempVec, this.chevronsOffsetLocal[0], this.chevronsOffsetLocal[1], this.chevronsOffsetLocal[2]);
                rightSideChevron.translateObject(this.tempVec);

                leftSideChevron.pp_setScaleLocal(this.chevronsScaleLocal);
                rightSideChevron.pp_setScaleLocal(this.chevronsScaleLocal);

                leftSideChevron.pp_setActive(true);
                rightSideChevron.pp_setActive(true);

                this.leftSideChevrons.push(leftSideChevron);
                this.rightSideChevrons.push(rightSideChevron);
            }

            currentChevronSplineTime += this.chevronDistanceAsSplineTime;
            currentChevronSplineTime = spline.clampTime(currentChevronSplineTime);
        }
    }

    activateChevrons() {
        if (!this.visibleFromBalcony && common.balcony.isPlayerOnBalcony) return;

        if (this.activated) return;

        this.activated = true;
        this.animTimer.start(2);
    }

    deactivateChevrons() {
        if (this.visibleFromBalcony && common.balcony.isPlayerOnBalcony) return;

        if (!this.activated) return;

        this.activated = false;
        this.animTimer.start(1.5);
    }

    update(dt) {
        if (!this.prepared || common.tracksManager.getCurrentTrack() == null || !common.tracksManager.getCurrentTrack().hasSpline()) return;

        if (this.easyTuneEnabled) {
            this._synWithEasyTuneVariables();
        }

        if (this.animTimer.isRunning()) {
            this.animTimer.update(dt);
            let animFactor = this.animTimer.getPercentage();
            if (!this.activated) {
                animFactor = 1 - animFactor;
            }

            for (let chevronMaterial of this.dynamicChevronMaterials) {
                if (this.activated) {
                    chevronMaterial.currentMaxDistanceFactor = EasingFunction.easeOutStrong(animFactor);
                } else {
                    chevronMaterial.currentMaxDistanceFactor = EasingFunction.easeInStrong(animFactor);
                }

                chevronMaterial.maxAlpha = this.chevronMaxAlpha; // not animated
            }

            if (this.animTimer.isDone() && !this.activated) {
                this.resetChevrons();
            }
        }

        if (this.activated) {
            let hoverboardTime = common.hoverboard.getCurrentSplineTime();

            if (Math.abs(hoverboardTime - this.middleChevronSplineTime) > Math.abs(this.chevronDistanceAsSplineTime)) {
                const spline = common.tracksManager.getCurrentTrack().getSpline();
                const isGoingBack = spline.getClosestTimeDifference(hoverboardTime, this.middleChevronSplineTime) > 0;

                let chevronsAmount = (Math.ceil(this.chevronsTrailLength / this.gapBetweenChevrons) * 2) + 1;
                let firstChevronSplineTIme = this.middleChevronSplineTime - this.normalizedChevronTrailLength / 2;
                let newChevronSplineTime = firstChevronSplineTIme + this.chevronDistanceAsSplineTime * (chevronsAmount - 1);
                if (isGoingBack) {
                    newChevronSplineTime = firstChevronSplineTIme - this.chevronDistanceAsSplineTime;
                }

                this.middleChevronSplineTime = spline.clampTime(this.middleChevronSplineTime + (isGoingBack ? -this.chevronDistanceAsSplineTime : this.chevronDistanceAsSplineTime));

                newChevronSplineTime = spline.clampTime(newChevronSplineTime);

                const chevronForward = spline.getForward(newChevronSplineTime, spline.movementDirection);
                const chevronPosition = spline.getPosition(newChevronSplineTime);
                if (this.chevronPositionType == 0) {
                    const floorChevron = isGoingBack ? this.floorChevrons.pop() : this.floorChevrons.shift();
                    if (isGoingBack) {
                        this.floorChevrons.unshift(floorChevron);
                    } else {
                        this.floorChevrons.push(floorChevron);
                    }

                    floorChevron.pp_resetScaleLocal();

                    floorChevron.pp_setForward(chevronForward, GameGlobals.up);

                    floorChevron.setPositionWorld(chevronPosition);
                    Vec3Utils.set(this.tempVec, this.chevronsOffsetLocal[0], this.chevronsOffsetLocal[1], this.chevronsOffsetLocal[2]);
                    floorChevron.translateObject(this.tempVec);

                    floorChevron.pp_setScaleLocal(this.chevronsScaleLocal);
                } else {
                    const leftSideChevron = isGoingBack ? this.leftSideChevrons.pop() : this.leftSideChevrons.shift();
                    const rightSideChevron = isGoingBack ? this.rightSideChevrons.pop() : this.rightSideChevrons.shift();

                    if (isGoingBack) {
                        this.leftSideChevrons.unshift(leftSideChevron);
                        this.rightSideChevrons.unshift(rightSideChevron);
                    } else {
                        this.leftSideChevrons.push(leftSideChevron);
                        this.rightSideChevrons.push(rightSideChevron);
                    }

                    leftSideChevron.pp_resetScaleLocal();
                    rightSideChevron.pp_resetScaleLocal();

                    leftSideChevron.pp_setForward(chevronForward, GameGlobals.up);
                    rightSideChevron.pp_setForward(chevronForward, GameGlobals.up);

                    leftSideChevron.setPositionWorld(chevronPosition);
                    Vec3Utils.set(this.tempVec, -this.chevronsOffsetLocal[0], this.chevronsOffsetLocal[1], this.chevronsOffsetLocal[2]);
                    leftSideChevron.translateObject(this.tempVec);

                    rightSideChevron.setPositionWorld(chevronPosition);
                    Vec3Utils.set(this.tempVec, this.chevronsOffsetLocal[0], this.chevronsOffsetLocal[1], this.chevronsOffsetLocal[2]);
                    rightSideChevron.translateObject(this.tempVec);

                    leftSideChevron.pp_setScaleLocal(this.chevronsScaleLocal);
                    rightSideChevron.pp_setScaleLocal(this.chevronsScaleLocal);
                }
            }
        }
    }

    _setupEasyTuneVariables() {
        let easyTuneVariables = Globals.getEasyTuneVariables();

        let postfix = this.chevronPositionType == 0 ? "Floor" : "Side";
        easyTuneVariables.add(new EasyTuneNumber("Chevron Trail Length " + postfix, this.chevronsTrailLength, () => this.easyTuneVariablesChanged = true, true, 3, 50).setEasyTuneVariableExtraParams(EasyTuneExtraParamsSet.importExportDisabled));
        easyTuneVariables.add(new EasyTuneNumber("Chevron Gap Between " + postfix, this.gapBetweenChevrons, () => this.easyTuneVariablesChanged = true, true, 3, 10).setEasyTuneVariableExtraParams(EasyTuneExtraParamsSet.importExportDisabled));
        easyTuneVariables.add(new EasyTuneNumberArray("Chevron Offset " + postfix, this.chevronsOffsetLocal, () => this.easyTuneVariablesChanged = true, true, 3, 10).setEasyTuneVariableExtraParams(EasyTuneExtraParamsSet.importExportDisabled));
        easyTuneVariables.add(new EasyTuneNumberArray("Chevron Scale " + postfix, this.chevronsScaleLocal, () => this.easyTuneVariablesChanged = true, true, 3, 10).setEasyTuneVariableExtraParams(EasyTuneExtraParamsSet.importExportDisabled));
    }

    _synWithEasyTuneVariables() {
        let easyTuneVariables = Globals.getEasyTuneVariables();

        let postfix = this.chevronPositionType == 0 ? "Floor" : "Side";
        this.chevronsTrailLength = easyTuneVariables.get("Chevron Trail Length " + postfix);
        this.gapBetweenChevrons = easyTuneVariables.get("Chevron Gap Between " + postfix);
        this.chevronsOffsetLocal.vec3_copy(easyTuneVariables.get("Chevron Offset " + postfix));
        this.chevronsScaleLocal.vec3_copy(easyTuneVariables.get("Chevron Scale " + postfix));

        if (this.activated && this.easyTuneVariablesChanged) {
            this.initializeChevrons();
            this.activateChevrons();

            this.easyTuneVariablesChanged = false;
        }
    }
}

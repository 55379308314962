import { Label, TextAlignMode, WidgetAutoXML } from "lazy-widgets";
import { BaseDecoratedButton, type DecoratedButtonProperties } from "./base-decorated-button.js";

export class DecoratedButton extends BaseDecoratedButton<Label> {
    static override autoXML: WidgetAutoXML = {
        name: "decorated-button",
        inputConfig: [
            {
                mode: "text",
                name: "name",
            }
        ]
    };

    constructor(name: string, properties?: Readonly<DecoratedButtonProperties>) {
        super(
            new Label(
                name,
                {
                    bodyTextFill: "black",
                    bodyTextFont: (properties?.fontSize || 0.9) + "em sui-generis",
                    bodyTextAlign: TextAlignMode.Center,
                    bodyTextSpacing: 0,
                },
            ),
            properties,
        );

        this.updateTextColor();
    }

    private updateTextColor() {
        this.child.bodyTextFill = this.clickable ? "black" : "rgb(128,128,128)";
    }

    override set clickable(clickable) {
        super.clickable = clickable;
        this.updateTextColor();
    }

    override get clickable() {
        return super.clickable;
    }
}
import { CatalogItem, InventoryItem } from "@heyvr/sdk-types";
import { CatalogCategories, Shop } from "../shop/shop-interface.js";
import { AssetManifest, HeadwearVariant, HoverboardVariant, SuitVariant } from "./asset-manifest-types.js";
import { KioskLowerUIComponent } from "./kiosk-lower-ui-component.js";

export const AssetManifestURI = "assets/json/shop/asset-manifest.json";

export interface KioskContent {
    name: string,
    value: string,
    imageUri: string,
}

const DEFAULT_HOVERBOARD_COLORS: Array<KioskContent> = [
    {
        name: "Oak",
        value: "0",
        imageUri: "assets/textures/ui/thumbnails/hoverboard/skins/thumbnail-board-redwood.webp"
    },
    {
        name: "Spruce",
        value: "1",
        imageUri: "assets/textures/ui/thumbnails/hoverboard/skins/thumbnail-board-lightwood.webp"
    },
    {
        name: "Carbon",
        value: "2",
        imageUri: "assets/textures/ui/thumbnails/hoverboard/skins/thumbnail-board-carbon.webp"
    }
];

const DEFAULT_HAIRS: Array<KioskContent> = [
    {
        name: "Black",
        value: "0",
        imageUri: "assets/textures/ui/thumbnails/avatar/hairs/thumbnail-hair-black.png"
    },
    {
        name: "Brown",
        value: "1",
        imageUri: "assets/textures/ui/thumbnails/avatar/hairs/thumbnail-hair-brown.png"
    },
    {
        name: "Red",
        value: "2",
        imageUri: "assets/textures/ui/thumbnails/avatar/hairs/thumbnail-hair-red.png"
    },
    {
        name: "Blue",
        value: "3",
        imageUri: "assets/textures/ui/thumbnails/avatar/hairs/thumbnail-hair-blue.png"
    },
    {
        name: "Ginger",
        value: "4",
        imageUri: "assets/textures/ui/thumbnails/avatar/hairs/thumbnail-hair-ginger.png"
    },
    {
        name: "Blonde",
        value: "5",
        imageUri: "assets/textures/ui/thumbnails/avatar/hairs/thumbnail-hair-blonde.png"
    }
];

const DEFAULT_SUIT_COLORS: Array<KioskContent> = [
    {
        name: "Red",
        value: "0",
        imageUri: "assets/textures/ui/thumbnails/avatar/suits/thumbnail-suit-red.webp"
    },
    {
        name: "Blue",
        value: "1",
        imageUri: "assets/textures/ui/thumbnails/avatar/suits/thumbnail-suit-blue.webp"
    },
    {
        name: "Black",
        value: "2",
        imageUri: "assets/textures/ui/thumbnails/avatar/suits/thumbnail-suit-black.webp"
    },
    {
        name: "Green",
        value: "3",
        imageUri: "assets/textures/ui/thumbnails/avatar/suits/thumbnail-suit-green.webp"
    },
    {
        name: "White",
        value: "4",
        imageUri: "assets/textures/ui/thumbnails/avatar/suits/thumbnail-suit-white.webp"
    },
    {
        name: "Pink",
        value: "5",
        imageUri: "assets/textures/ui/thumbnails/avatar/suits/thumbnail-suit-pink.webp"
    }
];

export class IAPContentController {
    private _kioskLowerUIComponent: KioskLowerUIComponent;

    assetManifest: AssetManifest | undefined;

    constructor(kioskLowerUIComponent: KioskLowerUIComponent) {
        this._kioskLowerUIComponent = kioskLowerUIComponent;
        this.loadManifest().then((manifest: AssetManifest) => {
            this.assetManifest = manifest;
        });
    }

    setShop(shop: Shop) {
        if (shop.provider.inventoryReady) {
            this.parseInventory(shop.getInventory());
        }
        shop.provider.onInventoryUpdatedEmitter.add(this.parseInventory.bind(this));

        if (shop.provider.catalogReady) {
            this.parseCatalog(shop.getCatalog());
        } else {
            shop.provider.onCatalogAvailableEmitter.add(this.parseCatalog.bind(this));
        }
    }

    async loadManifest(): Promise<AssetManifest> {
        return fetch(AssetManifestURI)
            .then(response => response.json())
            .catch(error => {
                console.error("Error loading JSON data:", error);
            });
    }

    parseInventory(inventory: ReadonlyArray<Readonly<InventoryItem>>) {
        const hoverboards = [];
        const suits = [];
        const hairs = [];
        const helmets = [];
        for (let i = 0; i < inventory.length; i++) {
            const item = inventory[i];
            switch (item.item_class) {
                case CatalogCategories.Hoverboard:
                    hoverboards.push(this.itemToKioskContent(item));
                    break;
                case CatalogCategories.Suit:
                    suits.push(this.itemToKioskContent(item));
                    break;
                case CatalogCategories.Hair:
                    hairs.push(this.itemToKioskContent(item));
                    break;
                case CatalogCategories.Helmet:
                    helmets.push(this.itemToKioskContent(item));
                    break;
            }
        }

        if (hoverboards.length) {
            this._kioskLowerUIComponent?.hoverboardVariantsInventoryUpdatedEmitter.notify(hoverboards);
        }

        if (suits.length) {
            this._kioskLowerUIComponent?.suitVariantsInventoryUpdatedEmitter.notify(suits);
        }

        if (hairs.length) {
            this._kioskLowerUIComponent?.hairVariantsInventoryUpdatedEmitter.notify(hairs);
        }

        if (helmets.length) {
            this._kioskLowerUIComponent?.helmetVariantsInventoryUpdatedEmitter.notify(helmets);
        }
    }

    parseCatalog(catalog: Map<CatalogCategories, Array<CatalogItem>>) {
        const hoverboards = catalog.get(CatalogCategories.Hoverboard);
        const suits = catalog.get(CatalogCategories.Suit);
        const hairs = catalog.get(CatalogCategories.Hair);
        const helmets = catalog.get(CatalogCategories.Helmet);

        if (hoverboards?.length) {
            this._kioskLowerUIComponent?.hoverboardVariantsCatalogUpdatedEmitter.notify(hoverboards);
        }

        if (suits?.length) {
            this._kioskLowerUIComponent?.suitVariantsCatalogUpdatedEmitter.notify(suits);
        }

        if (hairs?.length) {
            this._kioskLowerUIComponent?.hairVariantsCatalogUpdatedEmitter.notify(hairs);
        }

        if (helmets?.length) {
            this._kioskLowerUIComponent?.helmetVariantsCatalogUpdatedEmitter.notify(helmets);
        }
    }

    getHoverboardVariantConfiguration(slug: string): HoverboardVariant {
        return this.assetManifest!.hoverboards[slug];
    }

    getSuitVariantConfiguration(slug: string): SuitVariant {
        return this.assetManifest!.suits[slug];
    }

    getHeadwearVariantConfiguration(slug: string): HeadwearVariant {
        return this.assetManifest!.headwears[slug];
    }

    itemToKioskContent(item: InventoryItem): KioskContent {
        return {
            name: item.name,
            value: item.slug,
            imageUri: item.thumbnail,
        };
    }

    initialize() {
        this.setShop(this._kioskLowerUIComponent.shop!);

        this._kioskLowerUIComponent.hoverboardVariantsInventoryUpdatedEmitter.notify(DEFAULT_HOVERBOARD_COLORS);
        this._kioskLowerUIComponent.suitVariantsInventoryUpdatedEmitter.notify(DEFAULT_SUIT_COLORS);
        this._kioskLowerUIComponent.hairVariantsInventoryUpdatedEmitter.notify(DEFAULT_HAIRS);
    }
}
import { Component, TextComponent } from "@wonderlandengine/api";
import common from "../../../common.js";

function getLapToLabelSpaces(num) {
    return num > 9 ? "          " : "      ";
}

export class TimerComponent extends Component {
    static TypeName = "timer";
    static Properties = {};

    init() {
        common.timer = this;
    }

    start() {
        this.time = 0;
        this.duration = null;

        this.textTimeIndicator = common.hoverboard.object.pp_getObjectByName("Time Indicator").pp_getComponent(TextComponent);
        this.textLastLapTimeIndicator = common.hoverboard.object.pp_getObjectByName("Time Indicator Last Lap").pp_getComponent(TextComponent);
        this.textLapIndicator = common.hoverboard.object.pp_getObjectByName("Lap Indicator").pp_getComponent(TextComponent);
        this.textLapIndicatorLabel = common.hoverboard.object.pp_getObjectByName("Lap Indicator Label").pp_getComponent(TextComponent);
        this.textTimeIndicator.active = false;
        this.textLastLapTimeIndicator.active = false;
        this.textLapIndicator.active = false;

        this.running = false;

        this.updateTimer = false;
    }

    setDuration(duration = null) {
        this.duration = duration;
    }

    startTimer() {
        if (this.running) return;

        this.updateTimer = true;

        this.running = true;
        this.time = this.duration | 0;
        this.bestLapTime = Number.MAX_VALUE;
        this.bestLapIndex = 0;

        if (!common.balcony.isPlayerOnBalcony) {
            this.textTimeIndicator.active = false;
            this.textLastLapTimeIndicator.active = false;
        }
    }

    stopTimer() {
        if (this.updateTimer) {
            this.stopUpdatingTimer();
            this.showTime(this.time);
        }

        this.running = false;
    }

    stopUpdatingTimer() {
        this.updateTimer = false;
    }

    hideTimer() {
        this.stopTimer();
        this.textTimeIndicator.active = false;
        this.textLastLapTimeIndicator.active = false;
    }

    setLapIndicatorVisible(value) {
        this.textLapIndicator.active = value;
        this.textLapIndicatorLabel.active = value;
    }

    showCurrentTimer() {
        this.textTimeIndicator.active = true;
    }

    hideLastLapTimer() {
        this.textLastLapTimeIndicator.active = false;
    }

    resetTimer() {
        this.time = 0;
        this.showTime(this.time);
    }

    pauseTimer() {
        this.running = false;
    }

    resumeTimer() {
        this.running = true;
    }

    submitScore(name) {
        common.leaderboard.submitScore(Math.floor(this.time * 1000), name);
    }

    updateLapsAmount(lapsAmount, totalLaps) {
        this.textLapIndicator.text = `     ${lapsAmount}   ${totalLaps}`;
        this.textLapIndicatorLabel.text = `Lap${getLapToLabelSpaces(lapsAmount)}of`;
    }

    updateLastLapTime(lastLapTime) {
        let time = lastLapTime;
        let m = String(Math.floor(time / 60)).padStart(2, "0");
        let s = String(Math.floor(time) % 60).padStart(2, "0");
        let ms = String(Math.floor((time * 1000) % 1000)).padStart(3, "0");
        const lastLapText = `${m}:${s}.${ms}`;
        this.textLastLapTimeIndicator.text = lastLapText;
    }

    updateBestLapTime(lastLapTime) {
        if (lastLapTime < this.bestLapTime) {
            this.bestLapTime = lastLapTime;
            this.bestLapIndex = Math.max(common.tracksManager.getRaceManager().getPlayerLapsAmount(), 0);
        }
    }

    setStartingLastLapTime() {
        this.textLastLapTimeIndicator.text = `--:--.--`;
    }

    showTime(time) {
        let m = String(Math.floor(time / 60)).padStart(2, "0");
        let s = String(Math.floor(time) % 60).padStart(2, "0");
        let ms = String(Math.floor((time * 1000) % 1000)).padStart(3, "0");
        const timeString = `${m}:${s}.${ms}`;
        if (common.watchController) common.watchController.updateTime(timeString);
        this.totalTimeString = timeString;
        this.textTimeIndicator.text = timeString;
    }

    update(dt) {
        if (this.running) {
            if (this.duration == null) {
                this.time += dt;
            } else {
                this.time -= dt;
                this.time = Math.max(0, this.time);
            }

            if (this.updateTimer) {
                this.showTime(this.time);
            }
        }
    }
}
import { LocationConfiguration } from './game-configuration-config-types.js';
import { type LocationConfigurationTemplate } from './game-configuration-template-types.js';
import { GameLocation } from './game-configuration-types.js';
import gameConfigurationRaw from '../../game-configuration.json';

export const VALID_CONFIGURATIONS = new Map<GameLocation, LocationConfiguration>();
for (const [locationKey, locationData] of Object.entries(gameConfigurationRaw) as [GameLocation, LocationConfigurationTemplate][]) {
    VALID_CONFIGURATIONS.set(locationKey, LocationConfiguration.fromObj(locationData));
}

export const PROTOCOL_VERSION = 1;
import { MathUtils } from "wle-pp";
import { PopupIconImage } from "../ui/popup/popup.js";

const _encouragements = [
    "Great!",
    "Awesome!",
    "Amazing!",
    "Wow!",
    "Terrific!",
    "Fantastic!",
    "Way to go!",
    "Incredible!"
];

export function getRandomEncouragement(): string {
    return MathUtils.randomPick(_encouragements)!.toUpperCase();
}

export enum RewardTier {
    None = "none",
    Bronze = "bronze",
    Silver = "silver",
    Gold = "gold",
    Platinum = "platinum",
}

const _dailyMedalFitPointsThresholds: Map<RewardTier, number> = new Map([
    [RewardTier.Bronze, 50],
    [RewardTier.Silver, 150],
    [RewardTier.Gold, 300],
    [RewardTier.Platinum, 500]
]);

export function getDailyMedalFitPointsThreshold(rewardTier: RewardTier): number {
    return _dailyMedalFitPointsThresholds.get(rewardTier)!;
}

export function getDailyMedalFitPointsTier(dailyFitPoints: number): RewardTier {
    let dailyRewardTier = RewardTier.None;

    for (const [rewardTier, threshold] of _dailyMedalFitPointsThresholds.entries()) {
        if (dailyFitPoints >= threshold) {
            dailyRewardTier = rewardTier;
        } else {
            break;
        }
    }

    return dailyRewardTier;
}

export function getDailyMedalPopupIconImage(rewardTier: RewardTier): PopupIconImage {
    let icon = PopupIconImage.None;

    switch (rewardTier) {
        case RewardTier.Bronze:
            icon = PopupIconImage.DailyMedalBronze;
            break;
        case RewardTier.Silver:
            icon = PopupIconImage.DailyMedalSilver;
            break;
        case RewardTier.Gold:
            icon = PopupIconImage.DailyMedalGold;
            break;
        case RewardTier.Platinum:
            icon = PopupIconImage.DailyMedalPlatinum;
            break;
    }

    return icon;
}

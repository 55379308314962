import { Alignment, ArtificialConstraint, AxisCoupling, Background, Column, Container, FlexAlignment, Label, LayeredContainer, RoundedCorners, Row, ScrollableViewportWidget, ScrollbarMode, TextAlignMode, WrapMode, filterIDFromProperties, type Widget, type WidgetAutoXML, type WidgetProperties } from "lazy-widgets";

export function formatScoreToTime(time: number | null) {
    if (time == null) return "--:--.---";

    const m = String(Math.floor(time / 60)).padStart(2, "0");
    const s = String(Math.floor(time) % 60).padStart(2, "0");
    const ms = String(Math.floor((time * 1000) % 1000)).padStart(3, "0");
    return `${m}:${s}.${ms}`;
}

export interface LeaderboardEntry {
    rank: number,
    displayName: string,
    score: number | null,
}

export class Leaderboard extends ScrollableViewportWidget<LayeredContainer> {
    static override autoXML: WidgetAutoXML = {
        name: "leaderboard",
        inputConfig: [
            {
                name: "empty-child",
                mode: "widget",
            },
            {
                name: "table-width",
                mode: "value",
                validator: "number",
            },
            {
                name: "rank-width",
                mode: "value",
                validator: "number",
            },
            {
                name: "time-width",
                mode: "value",
                validator: "number",
            },
            {
                name: "row-height",
                mode: "value",
                validator: "number",
            },
        ]
    };

    private column: Column;

    constructor(readonly emptyChild: Widget, readonly tableWidth: number, readonly rankWidth: number, readonly timeWidth: number, readonly rowHeight: number, properties?: Readonly<WidgetProperties>) {
        const column = new Column([], {
            multiContainerSpacing: 2,
            multiContainerAlignment: {
                main: FlexAlignment.Start, cross: Alignment.Stretch,
            },
            ...filterIDFromProperties(properties),
        });

        super(
            new LayeredContainer(
                [
                    {
                        child: new Container(
                            column,
                            {
                                containerPadding: {
                                    top: 0, right: 4, bottom: 0, left: 0,
                                },
                            },
                        ),
                    },
                    {
                        child: emptyChild,
                        canExpand: false,
                    },
                ]
            ),
            {
                minWidth: 300,
                minHeight: 10,
                scrollBarThickness: 12,
                widthCoupling: AxisCoupling.Bi,
                heightCoupling: AxisCoupling.Uni,
                flex: 1,
                scrollbarMode: ScrollbarMode.Layout,
                backgroundFill: "rgba(0,0,0,0)",
                primaryFill: "rgb(227, 229, 241)",
                accentFill: "rgb(176, 177, 210)",
                ...properties,
            },
        );

        this.column = column;
    }

    private getEntryContainer(data: LeaderboardEntry) {
        const nameWidth = this.tableWidth - this.rankWidth - this.timeWidth;

        return new ArtificialConstraint(
            new RoundedCorners(
                new Background(
                    new Row(
                        [
                            new ArtificialConstraint(
                                new Container(
                                    new Label(
                                        `${data.rank}`,
                                        {
                                            bodyTextAlign: TextAlignMode.Center,
                                            bodyTextFill: "black",
                                        },
                                    ),
                                    {
                                        containerPadding: { left: 0, right: 1, top: 0, bottom: 0 },
                                        containerAlignment: { horizontal: Alignment.Stretch, vertical: Alignment.Stretch },
                                    },
                                ),
                                [this.rankWidth, this.rankWidth, this.rowHeight, this.rowHeight],
                            ),
                            new ArtificialConstraint(
                                new Label(
                                    `${data.displayName}`,
                                    {
                                        bodyTextAlign: TextAlignMode.Center,
                                        wrapMode: WrapMode.Ellipsis,
                                        bodyTextFill: "black",
                                    },
                                ),
                                [nameWidth, nameWidth, this.rowHeight, this.rowHeight],
                            ),
                            new ArtificialConstraint(
                                new Container(
                                    new Label(
                                        formatScoreToTime(data.score),
                                        {
                                            bodyTextAlign: TextAlignMode.Center,
                                            bodyTextFont: "1em sui-generis-mono-digits",
                                            bodyTextFill: "black",
                                        },
                                    ),
                                    {
                                        containerPadding: { left: 0, right: 6, top: 0, bottom: 0 },
                                        containerAlignment: { horizontal: Alignment.Stretch, vertical: Alignment.Stretch },
                                    },
                                ),
                                [this.timeWidth, this.timeWidth, this.rowHeight, this.rowHeight],
                            ),
                        ],
                        {
                            multiContainerSpacing: 0,
                        },
                    ),
                    {
                        canvasFill: "rgba(255, 255, 255, 0.75)",
                    },
                ),
                {
                    roundedCornersRadii: 3,
                },
            ),
            [this.tableWidth, this.tableWidth, this.rowHeight, this.rowHeight],
        );
    }

    replaceLeaderboardData(leaderboardDataArray: Array<LeaderboardEntry>) {
        this.column.clearChildren();

        if (leaderboardDataArray.length === 0) {
            this.emptyChild.enabled = true;
        } else {
            this.emptyChild.enabled = false;
            // This assumes the data is sorted
            for (const leaderboardEntry of leaderboardDataArray) {
                this.column.add(this.getEntryContainer(leaderboardEntry));
            }
        }
    }
}
import { vec4 } from "gl-matrix";

export const enum HeadWearTypes {
    Hair = "hair",
    Helmet = "helmet"
}

export const enum HairColor {
    Black,
    Brown,
    Red,
    Blue,
    Ginger,
    Blonde,
}

export const HAIR_COLORS = [
    {
        diffuseColor: vec4.fromValues(0, 0, 0, 1),
    },
    {
        diffuseColor: vec4.fromValues(0.5874, 0.2208, 0.0237, 1),
    },
    {
        diffuseColor: vec4.fromValues(1, 0, 0.2959, 1),
    },
    {
        diffuseColor: vec4.fromValues(0, 0.2062, 1, 1),
    },
    {
        diffuseColor: vec4.fromValues(1, 0.3228, 0, 1),
    },
    {
        diffuseColor: vec4.fromValues(1, 0.8717, 0.4798, 1),
    },
] as const;

export const HAIR_COLOR_COUNT = HAIR_COLORS.length;
export function getRandomHairColor(): string {
    return String(Math.trunc(Math.random() * HAIR_COLOR_COUNT));
}
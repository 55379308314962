import { ArtificialConstraint, Background, Button, ClickState, Column, Container, Icon, Label, LeaveEvent, PointerMoveEvent, PointerPressEvent, PointerReleaseEvent, RoundedCorners, TextAlignMode, safeRoundRect, type Rect, type Root, type Variable, type Viewport, type Widget, type WidgetAutoXML, type WidgetEvent, type WidgetProperties } from "lazy-widgets";
import { AudioID } from "../../../audio/audio-manager/audio-id.js";
import { Audio } from "../../../audio/audio-manager/audio.js";
import common from "../../../common.js";
import { downHapticFeedback, hoverHapticFeedback, upHapticFeedback } from "../../../misc/haptic-feedback.js";
import { getCursorFromLZCID } from "../get-cursor-from-lzcid.js";
import { BevelledCorners } from "./bevelled-corners.js";
import { Carousel } from "./carousel.js";
import { FilterContainer } from "./filter-container.js";

export interface LocationButtonProperties extends WidgetProperties {
    releasedFilter?: string,
    hoverFilter?: string,
    holdFilter?: string,
}

export class CustomisationButton extends Button<FilterContainer> {
    static override autoXML: WidgetAutoXML = {
        name: "customisation-button",
        inputConfig: [
            {
                mode: "text",
                name: "title",
            },
            {
                mode: "value",
                name: "value",
            },
            {
                mode: "value",
                name: "variable",
                validator: "variable",
            },
            {
                mode: "value",
                name: "image",
                validator: "image-source"
            }
        ]
    };

    private _releasedFilter: string;
    private _hoverFilter: string;
    private _holdFilter: string;
    private _curState = ClickState.Released;
    private _prevState = ClickState.Released;
    private _filterChangedExternally = false;
    private clickSound: Audio | null;
    private hoverSound: Audio | null;
    private inBounds = false;
    private forced: boolean;
    private ascendantCarousel: Carousel | null = null;

    constructor(title: string, readonly locationID: string, readonly locationVariable: Variable<string>, image: HTMLImageElement | string, properties?: Readonly<LocationButtonProperties>) {
        const releasedFilter = properties?.releasedFilter ?? "";

        super(
            new FilterContainer(
                new ArtificialConstraint(
                    new Background(
                        new Column([
                            new Container(
                                new Label(
                                    title,
                                    {
                                        bodyTextFill: "black",
                                        bodyTextFont: "0.9em sui-generis",
                                        bodyTextAlign: TextAlignMode.Center,
                                        bodyTextSpacing: 0,
                                    },
                                ),
                                {
                                    containerPadding: { left: 0, right: 0, top: 4, bottom: 0 },
                                },
                            ),
                            new Container(
                                new RoundedCorners(
                                    new BevelledCorners(
                                        new ArtificialConstraint(
                                            new Icon(image),
                                            [88, 88, 88, 88],
                                        ),
                                        {
                                            roundedCornersRadii: [24, 0, 0, 0],
                                        },
                                    ),
                                ),
                                {
                                    containerPadding: {
                                        left: 4, right: 4, top: 4, bottom: 4
                                    },
                                },
                            ),
                        ]),
                        {
                            containerPadding: { left: 0, right: 0, top: 0, bottom: 0 },
                            canvasFill: "white",
                        },
                    ),
                    [96, 96, 0, Infinity],
                ),
                {
                    filter: releasedFilter,
                },
            ),
            {
                containerPadding: { left: 0, right: 0, top: 0, bottom: 0 },
                ...properties,
            },
        );

        this._releasedFilter = releasedFilter;
        // XXX these can either be css filters, or an rgba array containing the
        // tint color that will be overlayed, with RGB components in the range
        // 0-255, and A component in the range 0-1
        this._hoverFilter = properties?.hoverFilter ?? "brightness(75%)";// [127, 131, 255, 0.5];
        this._holdFilter = properties?.holdFilter ?? "brightness(50%)";// [127, 131, 255, 0.5];

        this.clickSound = common.audioManager.getAudio(AudioID.BUTTON_CLICK);
        this.hoverSound = common.audioManager.getAudio(AudioID.BUTTON_HOVER);

        this.forced = this.locationVariable.value === this.locationID;
        this.locationVariable.watch(() => {
            const newHasBorder = this.locationVariable.value === this.locationID;
            if (this.forced === newHasBorder) return;
            this.forced = newHasBorder;
            this.markWholeAsDirty();
        });

        this.on("click", () => {
            this.locationVariable.value = this.locationID;
        });
    }

    get releasedFilter() {
        return this._releasedFilter;
    }

    set releasedFilter(releasedFilter) {
        if (this._releasedFilter !== releasedFilter) {
            this._releasedFilter = releasedFilter;
            this._filterChangedExternally = true;
        }
    }

    get hoverFilter() {
        return this._hoverFilter;
    }

    set hoverFilter(hoverFilter) {
        if (this._hoverFilter !== hoverFilter) {
            this._hoverFilter = hoverFilter;
            this._filterChangedExternally = true;
        }
    }

    get holdFilter() {
        return this._holdFilter;
    }

    set holdFilter(holdFilter) {
        if (this._holdFilter !== holdFilter) {
            this._holdFilter = holdFilter;
            this._filterChangedExternally = true;
        }
    }

    private _updatePickedFilter() {
        switch (this._curState) {
            case ClickState.Hover:
                this.child.filter = this._hoverFilter;
                break;
            case ClickState.Hold:
                this.child.filter = this._holdFilter;
                break;
            default:
                this.child.filter = this._releasedFilter;
                break;
        }
    }

    override click() {
        if (this.clickSound) {
            this.clickSound.play();
        }

        super.click();
    }

    override handleEvent(event: WidgetEvent) {
        const captured = super.handleEvent(event);

        if (this.clickHelper.clickStateChanged) {
            this._curState = this.clickHelper.clickState;
        }

        if (this.active && this.clickable) {
            if (event.isa(PointerMoveEvent)) {
                if (!this.inBounds) {
                    this.inBounds = true;
                    this.hoverSound!.play();
                    const cursor = getCursorFromLZCID(event);
                    if (cursor) hoverHapticFeedback(cursor);
                }
            } else if (event.isa(LeaveEvent)) {
                if (this.inBounds) this.inBounds = false;
            } else if (event.isa(PointerPressEvent)) {
                const cursor = getCursorFromLZCID(event);
                if (cursor) downHapticFeedback(cursor);
            } else if (event.isa(PointerReleaseEvent)) {
                const cursor = getCursorFromLZCID(event);
                if (cursor) upHapticFeedback(cursor);
            }
        }

        return captured;
    }

    override set clickable(clickable: boolean) {
        if (!clickable) {
            this.inBounds = false;
        }

        super.clickable = clickable;
    }

    override get clickable() {
        return super.clickable;
    }

    override deactivate() {
        super.deactivate();
        this.inBounds = false;
    }

    override handlePostLayoutUpdate() {
        super.handlePostLayoutUpdate();

        if (this._curState !== this._prevState) {
            this._prevState = this._curState;
            this._updatePickedFilter();
        } else if (this._filterChangedExternally) {
            this._updatePickedFilter();
        }

        this._filterChangedExternally = false;

        if (this.ascendantCarousel && this.forced) {
            this.ascendantCarousel.slideToDescendant(this);
        }
    }

    protected override handlePainting(dirtyRects: Rect[]): void {
        const ctx = this.viewport.context;
        ctx.save();
        ctx.beginPath();
        safeRoundRect(ctx, this.x, this.y, this.width, this.height, this.roundedCornersRadii);
        ctx.clip();

        super.handlePainting(dirtyRects);

        ctx.beginPath();
        safeRoundRect(ctx, this.x, this.y, this.width, this.height, this.roundedCornersRadii);
        const baseLineColor = this.forced ? "0,192,255" : (this.clickable ? "0,0,64" : "128,128,128");
        ctx.strokeStyle = `rgba(${baseLineColor},0.8)`;
        ctx.lineWidth = this.forced ? 6 : 2;
        ctx.stroke();

        ctx.restore();
    }

    override attach(root: Root, viewport: Viewport, parent: Widget | null): void {
        super.attach(root, viewport, parent);

        let focus = this.parent;
        while (focus) {
            if (focus instanceof Carousel) {
                this.ascendantCarousel = focus;
                break;
            }
            focus = focus.parent;
        }
    }

    override detach(): void {
        super.detach();
        this.ascendantCarousel = null;
    }
}
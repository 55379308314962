import { Object3D } from "@wonderlandengine/api";
import { AudioID } from "src/hoverfit/audio/audio-manager/audio-id.js";
import { GAME_STATES } from "src/hoverfit/game/game-states.js";
import { NumberOverFactor } from "wle-pp";
import common from "../../common.js";
import { ExpiringPriorityQueue, PriorityLevel } from "../../misc/data-structs/expiring-priority-queue.js";
import { LapCompletedPopupParams, LapCompletedPopupWindow, LapCompletedPopupWindowParams } from "./implementations/lap-completed-popup.js";
import { MessagePopupParams, MessagePopupWindow, MessagePopupWindowParams } from "./implementations/message-popup.js";
import { Popup, PopupIconDecoration, PopupIconImage, PopupImplementation, PopupParams, PopupResources, PopupType, PopupWindow, PopupWindowParams } from "./popup.js";

export class PopupManagerParams {
    walkPopupAnchor!: Object3D;
    hoverboardPopupAnchor!: Object3D;

    simplePopupWindowObject!: Object3D;
    lapCompletedPopupWindowObject!: Object3D;

    priorityLevelWeightMap: Map<PriorityLevel, number> = new Map();

    popupResources: PopupResources = new PopupResources();
}

export class PopupManager {

    private _params: PopupManagerParams;
    private _popupWindowObjects: Map<PopupType, PopupWindow> = new Map();

    private _popupQueue: ExpiringPriorityQueue<Popup>;
    private _currentPopup: Popup | null = null;

    private _active: boolean = true;

    constructor(params: PopupManagerParams) {
        this._params = params;

        this._popupQueue = new ExpiringPriorityQueue(this._params.priorityLevelWeightMap);

        this._popupWindowObjects.set(PopupType.Message, new MessagePopupWindow(this._params.simplePopupWindowObject!));
        this._popupWindowObjects.set(PopupType.LapCompleted, new LapCompletedPopupWindow(this._params.lapCompletedPopupWindowObject!));
    }

    showPopup<T extends PopupWindowParams>(popupParams: PopupParams<T>): void {
        let popupToShow: Popup | null = null;

        switch (popupParams.popupType) {
            case PopupType.Message: {
                const simplePopupParams = popupParams as unknown as MessagePopupParams;
                popupToShow = new PopupImplementation<MessagePopupWindowParams>(this._popupWindowObjects.get(PopupType.Message)!, simplePopupParams, this._params.popupResources);
                break;
            }
            case PopupType.LapCompleted: {
                const lapCompletedPopupParams = popupParams as unknown as LapCompletedPopupParams;
                popupToShow = new PopupImplementation<LapCompletedPopupWindowParams>(this._popupWindowObjects.get(PopupType.LapCompleted)!, lapCompletedPopupParams, this._params.popupResources);
                break;
            }
        }

        this._popupQueue.push(popupToShow, popupParams.priorityParams);
    }

    endCurrentPopup(priorityLevelAndBelow?: PriorityLevel): void {
        if (this._currentPopup != null) {
            if (priorityLevelAndBelow == null || this._currentPopup.getPriorityLevel() <= priorityLevelAndBelow) {
                this._currentPopup.end();
            }
        }
    }

    hidePopups(priorityLevelAndBelow?: PriorityLevel): void {
        this.clearPopupQueue(priorityLevelAndBelow);

        if (this._currentPopup != null) {
            if (priorityLevelAndBelow == null || this._currentPopup.getPriorityLevel() <= priorityLevelAndBelow) {
                this._currentPopup.hide();
                this._currentPopup = null;
            }
        }
    }

    clearPopupQueue(priorityLevelAndBelow?: PriorityLevel): void {
        if (priorityLevelAndBelow == null) {
            this._popupQueue.clear();
        } else {
            for (let priorityLevel = PriorityLevel.MAX; priorityLevel >= 0; priorityLevel--) {
                if (priorityLevel <= priorityLevelAndBelow) {
                    this._popupQueue.clear(priorityLevel);
                }
            }
        }
    }

    isShowingPopup() {
        return this._currentPopup != null;
    }

    showQuickMessagePopup(message: string, popupIcon: PopupIconImage, popupDecoration: PopupIconDecoration = PopupIconDecoration.None) {
        const params = new MessagePopupParams();
        params.popupWindowParams.popupIconImage = popupIcon;
        params.popupWindowParams.popupIconDecoration = popupDecoration;
        params.popupWindowParams.message = message;

        params.durationSeconds = new NumberOverFactor(1, 0.5, 0, 6);
        params.showSeconds = new NumberOverFactor(0.5, 0.25, 0, 4);
        params.hideSeconds = new NumberOverFactor(0.5, 0.25, 0, 4);

        this.showPopup(params);
    }

    showMessagePopup(message: string, popupIcon: PopupIconImage, popupDecoration: PopupIconDecoration = PopupIconDecoration.None) {
        const params = new MessagePopupParams();
        params.popupWindowParams.popupIconImage = popupIcon;
        params.popupWindowParams.popupIconDecoration = popupDecoration;
        params.popupWindowParams.message = message;
        this.showPopup(params);
    }

    isActive(): boolean {
        return this._active;
    }

    setActive(active: boolean): void {
        this._active = active;

        if (!this._active) {
            if (this._currentPopup != null) {
                this._currentPopup.hide();
                this._currentPopup = null;
            }
        }
    }

    update(dt: number) {
        if (!this.isActive()) return;

        this._popupQueue.update(dt);

        if (this._currentPopup == null && !this._popupQueue.isEmpty()) {
            this._currentPopup = this._popupQueue.pop();
            if (this._currentPopup != null) {
                // Set the audio to be played based off of game state
                const popupShowAudio = common.audioManager.getAudio(common.CURRENT_STATE === GAME_STATES.GAME ? AudioID.POPUP_SHOW_GAME : AudioID.POPUP_SHOW_NON_GAME)!;
                this._currentPopup.setAudio(popupShowAudio);
                this._currentPopup.show(this._popupQueue.pressure(this._currentPopup.getPriorityLevel()));
            }
        }

        if (this._currentPopup != null) {
            this._currentPopup.setAnchor(common.balcony.isPlayerOnBalcony ? this._params.walkPopupAnchor! : this._params.hoverboardPopupAnchor!);

            this._currentPopup.update(dt, this._popupQueue.pressure(this._currentPopup.getPriorityLevel()));

            if (this._currentPopup.isDone()) {
                this._currentPopup = null;
            }
        }
    }
}
import { ArtificialConstraint, Icon, Margin, RoundedCorners, filterIDFromProperties, type Variable, type WidgetAutoXML, type WidgetProperties } from "lazy-widgets";
import { Gender } from "../../../data/values/gender.js";
import { InternalClickyButton } from "../../lazy-widgets/widgets/clicky-button.js";

export class CustomiseGenderButton extends ArtificialConstraint<RoundedCorners<InternalClickyButton<Margin<Icon>>>> {
    static override autoXML: WidgetAutoXML = {
        name: "customise-gender-button",
        inputConfig: [
            {
                mode: "value",
                name: "gender",
            },
            {
                mode: "value",
                name: "variable",
                validator: "variable",
            }
        ]
    };

    constructor(gender: Gender, variable: Variable<Gender>, properties?: Readonly<WidgetProperties>) {
        const filteredProps = filterIDFromProperties(properties);

        const button = new InternalClickyButton(
            new Margin(
                new Icon(`assets/textures/ui/icons/kiosk/${gender === Gender.Female ? "fe" : ""}male.svg`),
                {
                    containerPadding: { top: 6, right: 6, bottom: 6, left: 6 },
                },
            ),
            {
                backgroundFill: "rgba(120, 120, 120, 0.25)",
                backgroundGlowFill: "rgba(120, 120, 120, 0.35)",
                primaryFill: "rgba(0, 120, 240, 0.45)",
                accentFill: "rgba(0, 120, 240, 0.35)",
                containerPadding: { top: 0, right: 0, bottom: 0, left: 0 },
                noCursorOnForced: true,
                ...filteredProps,
            }
        );

        super(new RoundedCorners(
            button,
            {
                roundedCornersRadii: 4,
                ...filteredProps,
            },
        ), [24, 24, 24, 24], properties);

        variable.watch(() => {
            button.forced = variable.value === gender;
        }, true);

        this.on("click", () => {
            variable.value = gender;
        });
    }
}
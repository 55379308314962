import { ArtificialConstraint, RoundedCorners, filterIDFromProperties, type Variable, type WidgetAutoXML, type WidgetProperties } from "lazy-widgets";
import { InternalClickyTextButton } from "../../lazy-widgets/widgets/clicky-button.js";
import { sfThemeSmaller } from "../../misc/sf-theme.js";

export class CustomiseTabButton extends ArtificialConstraint<RoundedCorners<InternalClickyTextButton>> {
    static override autoXML: WidgetAutoXML = {
        name: "customise-tab-button",
        inputConfig: [
            {
                mode: "text",
                name: "name",
            },
            {
                mode: "value",
                name: "value",
            },
            {
                mode: "value",
                name: "variable",
                validator: "variable",
            }
        ]
    };

    constructor(name: string, value: unknown, variable: Variable<unknown>, properties?: Readonly<WidgetProperties>) {
        const filteredProps = filterIDFromProperties(properties);

        const textButton = new InternalClickyTextButton(
            name,
            {
                bodyTextFont: sfThemeSmaller.bodyTextFont,
                backgroundFill: "rgba(120, 120, 120, 0.25)",
                backgroundGlowFill: "rgba(120, 120, 120, 0.35)",
                primaryFill: "rgba(0, 120, 240, 0.45)",
                accentFill: "rgba(0, 120, 240, 0.35)",
                containerPadding: { top: 0, right: 0, bottom: 0, left: 0 },
                noCursorOnForced: true,
                ...filteredProps,
            }
        );

        super(new RoundedCorners(
            textButton,
            {
                roundedCornersRadii: 4,
                ...filteredProps,
            },
        ), [72, 72, 24, 24], properties);

        variable.watch(() => {
            textButton.forced = variable.value === value;
        }, true);

        this.on("click", () => {
            variable.value = value;
        });
    }
}
import { Component, Property } from "@wonderlandengine/api";
import { Globals, ObjectCloneParams, ObjectPool, ObjectPoolParams } from "wle-pp";
import common from "../../common.js";
import { NetworkPlayerComponent } from "./network-player-component.js";

export class NetworkPlayerPoolComponent extends Component {
    static TypeName = "network-player-pool";
    static Properties = {
        networkPlayerPrototype: Property.object()
    };

    init() {
        common.networkPlayerPool = this;
        this.ready = false;
        this.readyCallbacks = [];
    }

    onPoolReady(callback) {
        if (this.ready) {
            callback();
        } else {
            this.readyCallbacks.push(callback);
        }
    }

    start() {
        let poolParams = new ObjectPoolParams();
        poolParams.myInitialPoolSize = 10;
        poolParams.myPercentageToAddWhenEmpty = 0;
        poolParams.myAmountToAddWhenEmpty = 1;

        poolParams.mySetActiveCallback = function () { }; // Deactivation managed by the network player component

        poolParams.myCloneParams = new ObjectCloneParams();
        poolParams.myCloneParams.myUseDefaultObjectClone = true;

        Globals.getObjectPoolManager(this.engine).addPool(this, new ObjectPool(this.networkPlayerPrototype, poolParams));

        this.ready = true;
        for (const callback of this.readyCallbacks) {
            callback();
        }
        this.readyCallbacks.length = 0;
    }

    getEntity() {
        let entity = Globals.getObjectPoolManager(this.engine).get(this);

        const networkPlayerComponent = entity.getComponent(NetworkPlayerComponent);
        networkPlayerComponent.resetPlayer();

        return entity;
    }

    returnEntity(entity) {
        let shouldReturnEntity = false;

        if (Globals.getObjectPoolManager(this.engine).isBusy(this, entity)) {
            shouldReturnEntity = true;

            Globals.getObjectPoolManager(this.engine).release(entity);
        }

        if (shouldReturnEntity) {
            entity.parent = this.object;
            entity.pp_resetTransformLocal();

            const networkPlayerComponent = entity.getComponent(NetworkPlayerComponent);
            networkPlayerComponent.setEnabled(false);
            networkPlayerComponent.resetPlayer();
        }
    }
}
import { Column, MultiParentXMLInputConfig } from "lazy-widgets";

export class Book extends Column {
    static autoXML = {
        name: "book",
        inputConfig: MultiParentXMLInputConfig
    };

    constructor(pages, properties) {
        if (pages) {
            for (let i = 1; i < pages.length; i++) {
                pages[i].enabled = false;
            }
        }

        super(pages, properties);

        this.pageNames = properties.pageNames ?? null;
        this.currentPage = 0;
    }

    changePage(newPage) {
        let i;
        if (this.pageNames && typeof newPage === "string") {
            i = this.pageNames.indexOf(newPage);
        } else {
            i = newPage;
        }

        if (i !== this.currentPage) {
            this._children[this.currentPage].enabled = false;
            this._children[i].enabled = true;
            this.currentPage = i;
        }
    }

    isInPage(page) {
        if (this.pageNames && typeof page === "string") {
            page = this.pageNames.indexOf(page);
        }

        return page === this.currentPage;
    }
}
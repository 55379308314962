import { ArrayUtils } from "wle-pp";

export enum PriorityLevel {
    Low,
    VeryLow,
    Middle,
    High,
    VeryHigh,
    MIN = Low,
    MAX = VeryHigh
}

export class ExpiringPriorityQueueElementParams {
    priorityLevel: PriorityLevel = PriorityLevel.Middle;
    expireSeconds: number = Infinity;
}

export class ExpiringPriorityQueue<T> {

    private _queue: [T, number][][] = [];
    private _priorityLevelWeightMap: Map<PriorityLevel, number>;

    constructor(priorityLevelWeightMap: Map<PriorityLevel, number>) {
        this._priorityLevelWeightMap = priorityLevelWeightMap;

        for (let currentPriorityLevel = PriorityLevel.MAX; currentPriorityLevel >= 0; currentPriorityLevel--) {
            this._queue[currentPriorityLevel] = [];
        }
    }

    push(element: T, params: ExpiringPriorityQueueElementParams): void {
        const priorityLevelElements = this._queue[params.priorityLevel];
        priorityLevelElements!.push([element, params.expireSeconds]);
    }

    pop(priorityLevel?: PriorityLevel): T | null {
        let firstElement: T | null = null;

        if (priorityLevel != null) {
            if (this._queue[priorityLevel].length > 0) {
                firstElement = this._queue[priorityLevel].shift()![0];
            }
        } else {
            for (let priorityLevel = PriorityLevel.MAX; priorityLevel >= 0; priorityLevel--) {
                const priorityLevelElements = this._queue[priorityLevel];
                if (priorityLevelElements.length > 0) {
                    firstElement = priorityLevelElements.shift()![0];
                    break;
                }
            }
        }

        return firstElement;
    }

    first(priorityLevel?: PriorityLevel): T | null {
        let firstElement: T | null = null;

        if (priorityLevel != null) {
            if (this._queue[priorityLevel].length > 0) {
                firstElement = this._queue[priorityLevel][0][0];
            }
        } else {
            for (let priorityLevel = PriorityLevel.MAX; priorityLevel >= 0; priorityLevel--) {
                const priorityLevelElements = this._queue[priorityLevel];
                if (priorityLevelElements.length > 0) {
                    firstElement = priorityLevelElements[0][0];
                    break;
                }
            }
        }

        return firstElement;
    }

    length(priorityLevel?: PriorityLevel): number {
        let length = 0;

        if (priorityLevel != null) {
            length = this._queue[priorityLevel].length;
        } else {
            for (const queueElements of this._queue) {
                length += queueElements.length;
            }
        }

        return length;
    }

    isEmpty(priorityLevel?: PriorityLevel): boolean {
        return this.length(priorityLevel) == 0;
    }

    pressure(priorityLevel?: PriorityLevel): number {
        let pressure = 0;

        const adjustedPriorityLevel = priorityLevel ?? PriorityLevel.MIN;
        const priorityLevelWeight = this._priorityLevelWeightMap.get(adjustedPriorityLevel)!;

        for (let currentPriorityLevel = adjustedPriorityLevel; currentPriorityLevel <= PriorityLevel.MAX; currentPriorityLevel++) {
            // This way the pressure for the given element is 1 for the ones with same priority, 
            // while it is "normalized" for the higher ones based on the difference between their weights
            const weightDifference = this._priorityLevelWeightMap.get(currentPriorityLevel)! - priorityLevelWeight;
            pressure += this.length(currentPriorityLevel) * (weightDifference + 1);
        }

        return pressure;
    }

    clear(priorityLevel?: PriorityLevel) {
        if (priorityLevel != null) {
            ArrayUtils.clear(this._queue[priorityLevel]);
        } else {
            for (let currentPriorityLevel = PriorityLevel.MAX; currentPriorityLevel >= 0; currentPriorityLevel--) {
                ArrayUtils.clear(this._queue[currentPriorityLevel]);
            }
        }
    }

    update(dt: number): void {
        for (const queueElements of this._queue) {

            if (queueElements.length > 0) {
                for (const queueElement of queueElements) {
                    queueElement[1] -= dt;
                }

                ArrayUtils.removeAll(queueElements, (element: [T, number]) => element[1] <= 0);
            }
        }
    }
}
<?xml version="1.0" encoding="UTF-8"?>
<ui-tree name="default" xmlns="lazy-widgets" xmlns:option="lazy-widgets:options" xmlns:on="lazy-widgets:on">
    <artificial-constraint constraints="@[546,546,306,306]">
        <kiosk-background>
            <column>
                <margin option:container-padding='@{"left":20,"right":20,"top":8,"bottom":8}' option:flex="@1">
                    <book option:id="kiosk-book">
                        <column option:flex="@1" option:multi-container-alignment='@{"main":0.5,"cross":0.5}'>
                            <label option:body-text-align="@0.5">COMING SOON!</label>
                        </column>
                        <column option:flex="@1">
                            <book option:id="multiplayer-book" option:flex="@1">
                                <column option:flex="@1" option:multi-container-alignment='@{"main":"between","cross":"stretch"}' option:multi-container-spacing="@32">
                                    <spacing/>
                                    <label option:body-text-align="@0.5">CHANGING GAME CONFIGURATION...</label>
                                    <decorated-button on:click="$cancelConfigurationChange">CANCEL</decorated-button>
                                    <spacing/>
                                </column>
                                <column option:flex="@1" option:multi-container-alignment='@{"main":"between","cross":"stretch"}' option:multi-container-spacing="@32">
                                    <spacing option:flex="@0" option:min-height="@16"/>
                                    <label option:body-text-align="@0.5" option:body-text-font="1.5em sui-generis">START MULTIPLAYER</label>
                                    <spacing/>
                                    <row option:multi-container-alignment='@{"main":"around","cross":"stretch"}' option:multi-container-spacing="@16">
                                        <margin option:container-padding='@{"left":0,"right":0,"top":0,"bottom":30}'>
                                            <artificial-constraint constraints="$bigButtonConstraints">
                                                <decorated-button on:click="$quickPlay" option:font-size="@1.5">QUICK PLAY</decorated-button>
                                            </artificial-constraint>
                                        </margin>
                                        <margin option:container-padding='@{"left":0,"right":0,"top":30,"bottom":0}'>
                                            <artificial-constraint constraints="$bigButtonConstraints">
                                                <decorated-button option:id="hostRoomButton" option:font-size="@1.5">HOST ROOM</decorated-button>
                                            </artificial-constraint>
                                        </margin>
                                        <margin option:container-padding='@{"left":0,"right":0,"top":0,"bottom":30}'>
                                            <artificial-constraint constraints="$bigButtonConstraints">
                                                <decorated-button option:id="joinRoomButton" option:font-size="@1.5">JOIN ROOM</decorated-button>
                                            </artificial-constraint>
                                        </margin>
                                    </row>
                                    <spacing/>
                                </column>
                                <column option:flex="@1" option:multi-container-alignment='@{"main":"between","cross":"stretch"}' option:multi-container-spacing="@32">
                                    <spacing/>
                                    <label option:body-text-align="@0.5">CONNECTING...</label>
                                    <spacing/>
                                </column>
                                <column option:flex="@1" option:multi-container-alignment='@{"main":"between","cross":"stretch"}' option:multi-container-spacing="@32">
                                    <header-pane>
                                        <live-label option:body-text-align="@0.5" option:body-text-font="1.5em sui-generis" text-source="$currentRoomLong"/>
                                    </header-pane>
                                    <row option:multi-container-alignment='@{"main":"between","cross":"stretch"}' option:multi-container-spacing="@32" option:flex="@1">
                                        <column option:multi-container-spacing="@32">
                                            <spacing/>
                                            <artificial-constraint constraints="$bigButtonConstraints">
                                                <decorated-button option:id="toTrackButtonMP" on:click="$goToTrack" option:font-size="@1.5"></decorated-button>
                                            </artificial-constraint>
                                            <artificial-constraint constraints="$bigButtonConstraints">
                                                <decorated-button on:click="$disconnect" option:font-size="@1.5">DISCONNECT</decorated-button>
                                            </artificial-constraint>
                                            <spacing/>
                                        </column>
                                        <player-list option:flex="@1"/>
                                    </row>
                                </column>
                                <margin option:flex="@1" option:container-padding='@{"left":100,"right":100,"top":0,"bottom":0}'>
                                    <column>
                                        <spacing/>
                                        <label option:body-text-align="@0.5">HOST ROOM - ENTER PREFERRED ROOM ID:</label>
                                        <text-input variable="$roomIDVar" option:input-filter="$numpadInputFilter"></text-input>
                                        <numpad variable="$roomIDVar" option:button-width="@80"/>
                                        <spacing option:flex="@0" option:min-height="@4"/>
                                        <row>
                                            <label>PRIVATE:</label>
                                            <clicky-checkbox variable="$privateRoomVar"/>
                                            <spacing option:flex="@1" option:min-width="@0" option:min-height="@0"/>
                                            <artificial-constraint constraints="$numpadBottomRowButtonConstraints">
                                                <decorated-button option:id="cancelHostButton">CANCEL</decorated-button>
                                            </artificial-constraint>
                                            <artificial-constraint constraints="$numpadBottomRowButtonConstraints">
                                                <decorated-button on:click="$hostRoom">HOST</decorated-button>
                                            </artificial-constraint>
                                        </row>
                                        <spacing/>
                                    </column>
                                </margin>
                                <margin option:flex="@1" option:container-padding='@{"left":100,"right":100,"top":0,"bottom":0}'>
                                    <column>
                                        <spacing/>
                                        <label option:body-text-align="@0.5">JOIN ROOM - ENTER ROOM ID:</label>
                                        <text-input variable="$roomIDVar" option:input-filter="$numpadInputFilter"></text-input>
                                        <numpad variable="$roomIDVar" option:button-width="@80"/>
                                        <spacing option:flex="@0" option:min-height="@4"/>
                                        <row option:multi-container-alignment='@{"main":1,"cross":"stretch"}'>
                                            <artificial-constraint constraints="$numpadBottomRowButtonConstraints">
                                                <decorated-button option:id="cancelJoinButton">CANCEL</decorated-button>
                                            </artificial-constraint>
                                            <artificial-constraint constraints="$numpadBottomRowButtonConstraints">
                                                <decorated-button option:id="doRoomJoinButton" on:click="$joinRoom">JOIN</decorated-button>
                                            </artificial-constraint>
                                        </row>
                                        <spacing/>
                                    </column>
                                </margin>
                            </book>
                        </column>
                        <column option:flex="@1">
                            <column option:flex="@1" option:multi-container-alignment='@{"main":"between","cross":"stretch"}'>
                                <center>
                                    <artificial-constraint constraints="$carouselConstraints">
                                        <carousel option:slide-speed-percent="@0" option:slide-threshold="@0.075">
                                            <row option:id="location-row">
                                                <spacing option:flex="@0"/>
                                                <!-- XXX filled in at runtime -->
                                            </row>
                                        </carousel>
                                    </artificial-constraint>
                                </center>
                                <center>
                                    <carousel option:slide-speed-percent="@0">
                                        <row>
                                            <spacing option:flex="@0"/>
                                            <artificial-constraint constraints="$buttonConstraints">
                                                <option-button option:id="race-mode-button" value="race" variable="$mode">RACE</option-button>
                                            </artificial-constraint>
                                            <artificial-constraint constraints="$buttonConstraints">
                                                <option-button option:id="tag-mode-button" value="tag" variable="$mode">TAG</option-button>
                                            </artificial-constraint>
                                            <artificial-constraint constraints="$buttonConstraints">
                                                <option-button option:id="roam-mode-button" value="roam" variable="$mode">ROAM</option-button>
                                            </artificial-constraint>
                                        </row>
                                    </carousel>
                                </center>
                                <row option:multi-container-alignment='@{"main":"between","cross":1}' option:flex="@1">
                                    <artificial-constraint constraints="$buttonConstraints">
                                        <!-- XXX this is just for counter-balancing the space taken by the "tutorial" button -->
                                        <spacing/>
                                    </artificial-constraint>
                                    <column option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}'>
                                        <artificial-constraint constraints="$bigButtonConstraints">
                                            <decorated-button option:id="toTrackButton" on:click="$goToTrack" option:font-size="@1.5"></decorated-button>
                                        </artificial-constraint>
                                    </column>
                                    <artificial-constraint constraints="$buttonConstraints">
                                        <decorated-button option:id="tutorial-button" on:click="$toggleTutorial">TUTORIAL</decorated-button>
                                    </artificial-constraint>
                                </row>
                                <back-pane option:flex="@1">
                                    <margin option:container-padding='@{"left":10,"right":10,"top":0,"bottom":0}'>
                                        <row option:multi-container-alignment='@{"main":"between","cross":"stretch"}'>
                                            <artificial-constraint constraints="$gameOptsConstraints">
                                                <column option:multi-container-alignment='@{"main":"around","cross":"stretch"}' option:multi-container-spacing="@0">
                                                    <stepper-input option:id="trackInput" option:value-width="@100" option:value-height="@36" option:labels="$trackLabels" variable="$track" min-value="@0" max-value="$trackMax">TRACK</stepper-input>
                                                </column>
                                            </artificial-constraint>
                                            <artificial-constraint constraints="$gameOptsConstraints">
                                                <column option:multi-container-alignment='@{"main":"around","cross":"stretch"}' option:multi-container-spacing="@0">
                                                    <stepper-input option:id="npcsAmountInput" option:value-width="@72" variable="$npcsAmount" min-value="@0" max-value="@5">CPU RACERS</stepper-input>
                                                    <stepper-input option:id="npcsDifficultyInput" option:value-width="@72" option:labels='@["Very Easy","Easy","Moderate","Hard","Very Hard"]' variable="$npcsDifficulty" min-value="@0" max-value="@4">CPU DIFFICULTY</stepper-input>
                                                </column>
                                            </artificial-constraint>
                                            <artificial-constraint constraints="$gameOptsConstraints">
                                                <column option:multi-container-alignment='@{"main":"around","cross":"stretch"}' option:multi-container-spacing="@0">
                                                    <stepper-input option:id="lapsAmountInput" option:value-width="@100" option:value-height="@36" variable="$lapsAmount" min-value="@1" max-value="@50">LAPS</stepper-input>
                                                    <time-stepper-input option:id="tagDurationInput" option:value-width="@100" option:value-height="@36" variable="$tagDuration" min-value="@30" max-value="@1200">TAG DURATION</time-stepper-input>
                                                </column>
                                            </artificial-constraint>
                                        </row>
                                    </margin>
                                </back-pane>
                            </column>
                        </column>
                        <column option:flex="@1" option:multi-container-alignment='@{"main":"between","cross":"stretch"}'>
                            <column>
                                <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}'>
                                    <customise-tab-button variable="$customPage" value="@0">SKIN TONE</customise-tab-button>
                                    <customise-tab-button variable="$customPage" value="@1">BOARD</customise-tab-button>
                                    <customise-tab-button variable="$customPage" value="@2">SUIT</customise-tab-button>
                                    <customise-tab-button variable="$customPage" value="@3">HAIR</customise-tab-button>
                                    <customise-tab-button variable="$customPage" value="@4">HELMET</customise-tab-button>
                                </row>
                                <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}'>
                                    <customise-gender-button variable="$gender" gender="@0"/>
                                    <customise-gender-button variable="$gender" gender="@1"/>
                                </row>
                            </column>
                            <!-- XXX just to provide extra blank space between top tabs and items. space is occupied by multi-container-spacing; this spacing widget is 0-height -->
                            <spacing option:flex="@0"/>
                            <margin option:container-padding='@{"left":40,"right":40,"top":0,"bottom":0}' option:container-alignment='@{"horizontal":"stretch","vertical":"stretch"}'>
                                <carousel option:slide-speed-percent="@0">
                                    <book option:id="custom-book" option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}'>
                                        <row>
                                            <customisation-button value="@0" variable="$skinColor" image="assets/textures/ui/thumbnails/avatar/skins/thumbnail-skin-light.png">LIGHT</customisation-button>
                                            <customisation-button value="@1" variable="$skinColor" image="assets/textures/ui/thumbnails/avatar/skins/thumbnail-skin-mid.png">MEDIUM</customisation-button>
                                            <customisation-button value="@2" variable="$skinColor" image="assets/textures/ui/thumbnails/avatar/skins/thumbnail-skin-dark.png">DARK</customisation-button>
                                            <!-- XXX 0-width spacing included so that there is a gap between the last and first element in the carousel -->
                                            <spacing option:min-width="@0"/>
                                        </row>
                                        <row option:id="hoverboard-row">
                                            <spacing option:min-width="@0"/>
                                        </row>
                                        <row option:id="suits-row">
                                            <spacing option:min-width="@0"/>
                                        </row>
                                        <row option:id="hairs-row">
                                            <spacing option:min-width="@0"/>
                                        </row>
                                        <row option:id="helmets-row">
                                            <spacing option:min-width="@0"/>
                                        </row>
                                    </book>
                                </carousel>
                            </margin>
                            <center>
                                <artificial-constraint constraints="$equipConstraints">
                                    <decorated-button on:click="$equip" option:click-audio-id-override="BUTTON_EQUIP">EQUIP</decorated-button>
                                </artificial-constraint>
                            </center>
                            <!-- XXX just to provide extra blank space at the bottom. space is occupied by multi-container-spacing; this spacing widget is 0-height -->
                            <spacing option:flex="@0"/>
                        </column>
                        <column option:flex="@1" option:multi-container-alignment='@{"main":"between","cross":"stretch"}'>
                            <column>
                                <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}'>
                                    <customise-tab-button variable="$shopPage" value="@0">HOVERBOARDS</customise-tab-button>
                                    <customise-tab-button variable="$shopPage" value="@1">SUITS</customise-tab-button>
                                    <customise-tab-button variable="$shopPage" value="@2">HAIR</customise-tab-button>
                                    <customise-tab-button variable="$shopPage" value="@3">HELMET</customise-tab-button>
                                    <customise-tab-button variable="$shopPage" value="@4">BUNDLES</customise-tab-button>
                                </row>
                            </column>
                            <spacing option:flex="@0"/>
                            <artificial-constraint constraints="@[0, 546, 0, 235]">
                                <margin option:container-padding='@{"left":0,"right":0,"top":0,"bottom":0}' option:container-alignment='@{"horizontal":"stretch","vertical":"stretch"}'>
                                    <book option:id="shop-or-item-book">
                                        <carousel option:slide-speed-percent="@0" option:vertical="@true" option:loops="@false">
                                            <book option:id="shop-book">
                                                <kiosk-shop-page variable="$showItemPage" option:id="shop-page-hoverboard"></kiosk-shop-page>
                                                <kiosk-shop-page variable="$showItemPage" option:id="shop-page-suits"></kiosk-shop-page>
                                                <kiosk-shop-page variable="$showItemPage" option:id="shop-page-hairs"></kiosk-shop-page>
                                                <kiosk-shop-page variable="$showItemPage" option:id="shop-page-helmets"></kiosk-shop-page>
                                                <kiosk-shop-page variable="$showItemPage" option:id="shop-page-bundles"></kiosk-shop-page>
                                            </book>
                                        </carousel>
                                        <artificial-constraint constraints="@[546,546,235,235]">
                                            <rounded-corners option:rounded-corners-radii="@4">
                                                <item-page-background>
                                                    <column>
                                                        <spacing option:flex="@0" option:min-height="@8"/>
                                                        <label option:id="itemPageLabel" option:body-text-align="@0.5" option:body-text-font="1.5em sui-generis" option:body-text-fill="black">You are about to buy item X</label>
                                                        <row option:multi-container-alignment='@{"main":"around","cross":"stretch"}' option:multi-container-spacing="@16">
                                                            <margin option:container-padding='@{"left":0,"right":0,"top":0,"bottom":30}'>
                                                                <artificial-constraint constraints="$bigButtonConstraints">
                                                                    <decorated-button option:id="itemBackButton" option:font-size="@1.5">Back</decorated-button>
                                                                </artificial-constraint>
                                                            </margin>
                                                            <margin option:container-padding='@{"left":0,"right":0,"top":0,"bottom":30}'>
                                                                <artificial-constraint constraints="$bigButtonConstraints">
                                                                    <decorated-button option:id="purchaseItemButton" option:font-size="@1.5">Next</decorated-button>
                                                                </artificial-constraint>
                                                            </margin>
                                                        </row>
                                                    </column>
                                                    <!-- <kiosk-shop-popup>
                                                    </kiosk-shop-popup> -->
                                                </item-page-background>
                                            </rounded-corners>
                                        </artificial-constraint>
                                        <artificial-constraint constraints="@[546,546,235,235]">
                                            <rounded-corners option:rounded-corners-radii="@4">
                                                <item-page-background>
                                                    <column>
                                                        <spacing option:flex="@0" option:min-height="@8"/>
                                                        <label option:id="itemBuyConfirmLabel" option:body-text-align="@0.5" option:body-text-font="1.5em sui-generis" option:body-text-fill="black">Are you sure you want to Purchase X?</label>
                                                        <row option:multi-container-alignment='@{"main":"around","cross":"stretch"}' option:multi-container-spacing="@16">
                                                            <margin option:container-padding='@{"left":0,"right":0,"top":0,"bottom":30}'>
                                                                <artificial-constraint constraints="$bigButtonConstraints">
                                                                    <decorated-button option:id="itemPurchaseCancelButton" option:font-size="@1.5">Cancel</decorated-button>
                                                                </artificial-constraint>
                                                            </margin>
                                                            <margin option:container-padding='@{"left":0,"right":0,"top":0,"bottom":30}'>
                                                                <artificial-constraint constraints="$bigButtonConstraints">
                                                                    <decorated-button option:id="purchaseItemConfirmButton" option:font-size="@1.5">Buy</decorated-button>
                                                                </artificial-constraint>
                                                            </margin>
                                                        </row>
                                                    </column>
                                                </item-page-background>
                                            </rounded-corners>
                                        </artificial-constraint>
                                        <artificial-constraint constraints="@[546,546,235,235]">
                                            <rounded-corners option:rounded-corners-radii="@4">
                                                <item-page-background>
                                                    <column>
                                                        <spacing option:flex="@0" option:min-height="@8"/>
                                                        <label option:id="itemPostPurchaseLabel" option:body-text-align="@0.5" option:body-text-font="1.5em sui-generis" option:body-text-fill="black">Are you sure you want to Purchase X?</label>
                                                        <row option:multi-container-alignment='@{"main":"around","cross":"stretch"}' option:multi-container-spacing="@16">
                                                            <margin option:container-padding='@{"left":0,"right":0,"top":0,"bottom":30}'>
                                                                <artificial-constraint constraints="$bigButtonConstraints">
                                                                    <decorated-button option:id="itemPostPurchaseCancelButton" option:font-size="@1.5">Cancel</decorated-button>
                                                                </artificial-constraint>
                                                            </margin>
                                                            <margin option:container-padding='@{"left":0,"right":0,"top":0,"bottom":30}'>
                                                                <artificial-constraint constraints="$bigButtonConstraints">
                                                                    <decorated-button option:id="itemPostPurchaseEquipButton" option:font-size="@1.5">Equip</decorated-button>
                                                                </artificial-constraint>
                                                            </margin>
                                                        </row>
                                                    </column>
                                                </item-page-background>
                                            </rounded-corners>
                                        </artificial-constraint>
                                    </book>
                                </margin>
                            </artificial-constraint>
                        </column>
                    </book>
                </margin>
                <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}'>
                    <kiosk-tab-button variable="$kioskPage" value="@0">REWARDS</kiosk-tab-button>
                    <kiosk-tab-button variable="$kioskPage" value="@1">MULTIPLAYER</kiosk-tab-button>
                    <kiosk-tab-button variable="$kioskPage" value="@2">PLAY</kiosk-tab-button>
                    <kiosk-tab-button variable="$kioskPage" value="@3">CUSTOMISE</kiosk-tab-button>
                    <kiosk-tab-button variable="$kioskPage" value="@4">SHOP</kiosk-tab-button>
                </row>
            </column>
        </kiosk-background>
    </artificial-constraint>
</ui-tree>
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CatalogItem, InventoryItem } from "@heyvr/sdk-types";
import { ShopItemButton } from "../../lazy-widgets/widgets/shop-item-button.js";
import { HeyVRShopProvider, MockShopProvider } from "./shop-provider.js";

export enum CatalogCategories {
    Hoverboard = "hoverboard",
    Hair = "hair",
    Helmet = "helmet",
    Suit = "suit",
}

export const slugToCatalogShopItemButton = new Map<string, ShopItemButton>();
export const slugToCatalogItems = new Map<string, CatalogItem>();
export const classSortedCatalog = new Map<CatalogCategories, Array<CatalogItem>>();

export class Shop {
    readonly provider: HeyVRShopProvider | MockShopProvider;

    constructor() {
        if (window.heyVR) {
            this.provider = new HeyVRShopProvider(window.heyVR);
        } else {
            this.provider = new MockShopProvider();
        }

        for (const key in CatalogCategories) {
            classSortedCatalog.set(CatalogCategories[key as keyof typeof CatalogCategories], []);
        }

        this.fetchCatalog();
    }

    getInventory(): ReadonlyArray<Readonly<InventoryItem>> {
        return this.provider.inventoryItems;
    }

    getCatalog(): Map<CatalogCategories, Array<CatalogItem>> {
        return classSortedCatalog;
    }

    getAmountCoins() {
        return this.provider.getAmountCoins();
    }

    fetchCatalog() {
        return this.provider.fetchCatalog();
    }

    getItemFromSlug(slug: string) {
        return slugToCatalogItems.get(slug);
    }

    purchaseItem(slug: string) {
        return this.provider.purchaseItem(slug);
    }

    fetchInventory() {
        return this.provider.fetchInventory();
    }
}
import { vec4 } from "gl-matrix";

export const skinMaterialIndexToConfigMaterialKey = {
    0: "primaryMaterial",
    1: "secondaryMaterial",
    2: "emissive"
};

export const enum SkinColor {
    Light,
    Mid,
    Dark,
}

export const SKIN_COLORS = [
    vec4.fromValues(0.8430, 0.5860, 0.4914, 1),
    vec4.fromValues(0.6098, 0.3797, 0.2406, 1),
    vec4.fromValues(0.2600, 0.1481, 0.0804, 1),
] as const;

export const SKIN_COLOR_COUNT = SKIN_COLORS.length;
export function getRandomSkinColor(): SkinColor {
    return Math.trunc(Math.random() * SKIN_COLOR_COUNT);
}
import { CatalogItem } from "@heyvr/sdk-types";
import { Column, Row, ScrollableViewportWidget, Spacing, Variable, type WidgetAutoXML, type WidgetProperties } from "lazy-widgets";
import common from "src/hoverfit/common.js";
import { ShopItemButton } from "../../lazy-widgets/widgets/shop-item-button.js";
import { slugToCatalogShopItemButton } from "../shop/shop-interface.js";

function chunkArray(inputArray: Array<CatalogItem>, chunkSize = 5): Array<Array<CatalogItem>> {
    const chunks = [];
    for (let i = 0; i < inputArray.length; i += chunkSize) {
        chunks.push(inputArray.slice(i, i + chunkSize));
    }
    return chunks;
}

export class KioskShopPage extends ScrollableViewportWidget<Column> {
    static override autoXML: WidgetAutoXML = {
        name: "kiosk-shop-page",
        inputConfig: [
            {
                mode: "value",
                name: "variable",
                validator: "variable",
            },
        ]
    };

    private column: Column;
    private itemLocationVariable: Variable<string>;

    constructor(readonly locationVariable: Variable<string>, properties?: Readonly<WidgetProperties>) {
        const column = new Column([]);

        super(
            column, {
            minHeight: 235,
            ...properties,
        }
        );

        this.column = column;
        this.itemLocationVariable = locationVariable;
    }

    private getShopRow(data: Array<CatalogItem>, id: string) {
        const row = new Row();
        row.id = id;
        for (let i = 0; i < data.length; i++) {
            const catalogItem = data[i];
            const button = new ShopItemButton(catalogItem.name, catalogItem.slug, this.itemLocationVariable, catalogItem.thumbnail, String(catalogItem.price));
            slugToCatalogShopItemButton.set(catalogItem.slug, button);
            if (common.kioskLowerUI.shop?.provider.hasItem(catalogItem.slug)) {
                button.setInactive();
            }
            row.add(button);
        }
        row.add(new Spacing({ minWidth: 0 }));
        const column = new Column();
        column.add(row);

        return column;
    }

    replaceShopData(catalogArray: Array<CatalogItem>) {
        this.column.clearChildren();

        if (catalogArray.length !== 0) {
            const chunkedCatalogArrays = chunkArray(catalogArray);
            for (let i = 0; i < chunkedCatalogArrays.length; i++) {
                this.column.add(this.getShopRow(chunkedCatalogArrays[i], this.id ? this.id + i : String(i)));
            }
        }
    }
}
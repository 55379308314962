import { Component, Object3D, WonderlandEngine } from "@wonderlandengine/api";
import { property } from "@wonderlandengine/api/decorators.js";
import { GameLocation, GameMode } from "hoverfit-shared-netcode";
import { Howler } from "howler";
import { AudioPlayer, AudioSetup } from "wle-pp";
import common from "../../../common.js";
import { currentGameConfig } from "../../../data/game-configuration.js";
import { EncryptedAudioComponent } from "../../encrypted-audio/components/encrypted-audio-component.js";
import { AudioChannelName } from "../audio-channel.js";
import { AudioID } from "../audio-id.js";
import { AudioManager } from "../audio-manager.js";
import { applyHowlerVolumeMixerExtension } from "../implementations/howler-audio.js";
import { TrackMusicManager } from "../track-music-manager.js";

// TODO: Move to config file
// const baseURL = "https://cdn.vhiterabbitxr.com/";
export const baseMusicURL = "assets/audio/music/";
export const baseMusicJSONURL = "assets/json/audio/music/";

export const modeToName = {
    [GameMode.Race]: "race",
    [GameMode.Roam]: "roam",
    [GameMode.Tag]: "tag",
    // TODO re-enable when practice is added
    // [GameMode.Practice]: "Practice",
};

export const locationToName = {
    [GameLocation.Snow]: "snow",
    [GameLocation.City]: "city",
    [GameLocation.Canyon]: "canyon",
};

export const locationToStingPath = {
    [GameLocation.Snow]: "assets/audio/sfx/maps/snow/snow-sting.webm",
    [GameLocation.City]: "assets/audio/sfx/maps/city/city-sting.webm",
    [GameLocation.Canyon]: "assets/audio/sfx/maps/canyon/canyon-sting.webm",
};

export class AudioManagerComponent extends Component {
    static override TypeName = "audio-manager";

    @property.string("UI AUDIOS")
    uiAudios!: string;

    @property.string("assets/audio/sfx/ui/misc/click.webm")
    buttonClickPath!: string;

    @property.string("assets/audio/sfx/ui/misc/cancel.webm")
    buttonCancelPath!: string;

    @property.string("assets/audio/sfx/ui/misc/hover.webm")
    buttonHoverPath!: string;

    @property.string("assets/audio/sfx/ui/misc/equip.webm")
    buttonEquipPath!: string;

    @property.string("assets/audio/sfx/ui/misc/popup-game.webm")
    popupShowGamePath!: string;

    @property.string("assets/audio/sfx/ui/misc/popup-non-game.webm")
    popupShowNonGamePath!: string;

    @property.string("MAPS AUDIOS")
    mapsAudios!: string;

    @property.string("assets/audio/music/maps/misc/environment/ambient-wind.webm")
    ambientPath!: string;

    @property.float(0.3)
    ambientVolume!: number;

    @property.string("assets/audio/music/maps/misc/hub/balcony.webm")
    balconyMusicPath!: string;

    @property.string("assets/audio/music/maps/misc/hub/shop.webm")
    shopMusicPath!: string;


    @property.string("AVATAR AUDIOS")
    avatarAudios!: string;

    @property.string("assets/audio/sfx/avatar/watch-click.webm")
    watchButtonClickPath!: string;


    @property.string("HOVERBOARD AUDIOS")
    hoverboardAudios!: string;

    @property.string("assets/audio/sfx/hoverboard/misc/ramp-boost.webm")
    rampBoostPath!: string;

    @property.string("assets/audio/sfx/hoverboard/misc/pickup-rocket.webm")
    rocketPickupPath!: string;

    @property.string("assets/audio/sfx/hoverboard/misc/pickup-spring.webm")
    springPickupPath!: string;

    @property.string("assets/audio/sfx/hoverboard/misc/wall-hit.webm")
    wallHitPath!: string;


    @property.string("RACE AUDIOS")
    raceAudios!: string;

    @property.string("assets/audio/sfx/hoverboard/misc/race-finish.webm")
    raceFinishPath!: string;

    @property.string("assets/audio/sfx/maps/misc/track/misc/race/racer-ready.webm")
    racerReadyPath!: string;

    @property.string("assets/audio/sfx/maps/misc/track/misc/race/racers-ready.webm")
    racersReadyPath!: string;

    @property.object()
    trackMusicAudioSinkObject!: Object3D;


    @property.string("TAG AUDIOS")
    tagAudios!: string;

    @property.string("assets/audio/sfx/maps/misc/track/misc/tag/chaser-ready.mp3")
    chaserReadyPath!: string;

    @property.string("assets/audio/sfx/maps/misc/track/misc/tag/evader-ready.mp3")
    evaderReadyPath!: string;

    private _trackMusicManager!: TrackMusicManager;

    static override onRegister(engine: WonderlandEngine) {
        engine.registerComponent(EncryptedAudioComponent);
    }

    override init() {
        Howler.unload();
        applyHowlerVolumeMixerExtension();
        common.audioManager = new AudioManager();

        this._setupIntroAudios();
        this._setupUIAudios();
        this._setupMapsAudios();
        this._setupAvatarAudios();
        this._setupHoverboardAudios();
        this._setupRaceAudios();
    }

    override start() {
        // Some audios might use other game infos like game mode so we need to delay their setup to the start method
        this._setupTagAudios();
        this._trackMusicManager = new TrackMusicManager(this.trackMusicAudioSinkObject, this.object);
    }

    override update(dt: number): void {
        this._trackMusicManager.update(dt);
    }

    private _setupIntroAudios() {
        const introPath = locationToStingPath[currentGameConfig.location];
        const introSetup = new AudioSetup(introPath);
        introSetup.mySpatial = false;
        common.audioManager.addSourceAudioToChannel(AudioID.INTRO, new AudioPlayer(introSetup), AudioChannelName.MUSIC_QUIET);
    }

    private _setupUIAudios() {
        const buttonClickAudioSetup = new AudioSetup(this.buttonClickPath);
        common.audioManager.addSourceAudioToChannel(AudioID.BUTTON_CLICK, new AudioPlayer(buttonClickAudioSetup), AudioChannelName.SFX);

        const buttonCancelAudioSetup = new AudioSetup(this.buttonCancelPath);
        common.audioManager.addSourceAudioToChannel(AudioID.BUTTON_CANCEL, new AudioPlayer(buttonCancelAudioSetup), AudioChannelName.SFX);

        const buttonHoverAudioSetup = new AudioSetup(this.buttonHoverPath);
        buttonHoverAudioSetup.myVolume = 0.1;
        common.audioManager.addSourceAudioToChannel(AudioID.BUTTON_HOVER, new AudioPlayer(buttonHoverAudioSetup), AudioChannelName.SFX);

        const buttonEquipAudioSetup = new AudioSetup(this.buttonEquipPath);
        buttonEquipAudioSetup.myVolume = 0.5;
        common.audioManager.addSourceAudioToChannel(AudioID.BUTTON_EQUIP, new AudioPlayer(buttonEquipAudioSetup), AudioChannelName.SFX);

        const popupShowGameAudioSetup = new AudioSetup(this.popupShowGamePath);
        popupShowGameAudioSetup.myVolume = 0.1;
        common.audioManager.addSourceAudioToChannel(AudioID.POPUP_SHOW_GAME, new AudioPlayer(popupShowGameAudioSetup), AudioChannelName.SFX);

        const popupShowNonGameAudioSetup = new AudioSetup(this.popupShowNonGamePath);
        popupShowNonGameAudioSetup.myVolume = 0.1;
        common.audioManager.addSourceAudioToChannel(AudioID.POPUP_SHOW_NON_GAME, new AudioPlayer(popupShowNonGameAudioSetup), AudioChannelName.SFX);
    }

    private _setupMapsAudios() {
        const ambientAudioSetup = new AudioSetup(this.ambientPath);
        ambientAudioSetup.myVolume = 0.0;
        ambientAudioSetup.myLoop = true;
        common.audioManager.addSourceAudioToChannel(AudioID.AMBIENT, new AudioPlayer(ambientAudioSetup), AudioChannelName.AMBIENT);

        common.audioManager.getAudio(AudioID.AMBIENT)!.setDefaultVolume(this.ambientVolume);

        const balconyMusicAudioSetup = new AudioSetup(this.balconyMusicPath);
        balconyMusicAudioSetup.myVolume = 0.0;
        balconyMusicAudioSetup.myLoop = true;
        common.audioManager.addSourceAudioToChannel(AudioID.BALCONY_MUSIC, new AudioPlayer(balconyMusicAudioSetup), AudioChannelName.MUSIC_ALMOST_QUIET);

        common.audioManager.getAudio(AudioID.BALCONY_MUSIC)!.setDefaultVolume(0.1);

        const shopMusicAudioSetup = new AudioSetup(this.shopMusicPath);
        shopMusicAudioSetup.myVolume = 0.0;
        shopMusicAudioSetup.myLoop = true;
        common.audioManager.addSourceAudioToChannel(AudioID.SHOP_MUSIC, new AudioPlayer(shopMusicAudioSetup), AudioChannelName.MUSIC_ALMOST_QUIET);

        common.audioManager.getAudio(AudioID.SHOP_MUSIC)!.setDefaultVolume(0.1);
    }

    private _setupAvatarAudios() {
        const watchbuttonClickAudioSetup = new AudioSetup(this.watchButtonClickPath);
        watchbuttonClickAudioSetup.myVolume = 0.5;
        watchbuttonClickAudioSetup.mySpatial = true;
        common.audioManager.addSourceAudioToChannel(AudioID.WATCH_BUTTON_CLICK, new AudioPlayer(watchbuttonClickAudioSetup), AudioChannelName.SFX);
    }

    private _setupHoverboardAudios() {
        const rampBoostAudioSetup = new AudioSetup(this.rampBoostPath);
        common.audioManager.addSourceAudioToChannel(AudioID.RACE_RAMP_BOOST, new AudioPlayer(rampBoostAudioSetup), AudioChannelName.SFX);

        const rocketPickupAudioSetup = new AudioSetup(this.rocketPickupPath);
        rocketPickupAudioSetup.myVolume = 0.5;
        common.audioManager.addSourceAudioToChannel(AudioID.PICKUP_ROCKET, new AudioPlayer(rocketPickupAudioSetup), AudioChannelName.SFX);

        const springPickupAudioSetup = new AudioSetup(this.springPickupPath);
        springPickupAudioSetup.myVolume = 0.5;
        common.audioManager.addSourceAudioToChannel(AudioID.PICKUP_SPRING, new AudioPlayer(springPickupAudioSetup), AudioChannelName.SFX);

        const wallHitSetup = new AudioSetup(this.wallHitPath);
        wallHitSetup.myVolume = 0.75;
        wallHitSetup.mySpatial = true;
        common.audioManager.addSourceAudioToChannel(AudioID.RACE_WALL_HIT, new AudioPlayer(wallHitSetup), AudioChannelName.SFX);
    }

    private _setupRaceAudios() {
        const racerReadyAudioSetup = new AudioSetup(this.racerReadyPath);
        racerReadyAudioSetup.myVolume = 0.5;
        common.audioManager.addSourceAudioToChannel(AudioID.RACER_READY, new AudioPlayer(racerReadyAudioSetup), AudioChannelName.VOICE_OVERS);

        const racersReadyAudioSetup = new AudioSetup(this.racersReadyPath);
        racersReadyAudioSetup.myVolume = 0.5;
        common.audioManager.addSourceAudioToChannel(AudioID.RACERS_READY, new AudioPlayer(racersReadyAudioSetup), AudioChannelName.VOICE_OVERS);

        const raceFinishAudioSetup = new AudioSetup(this.raceFinishPath);
        common.audioManager.addSourceAudioToChannel(AudioID.RACE_FINISH, new AudioPlayer(raceFinishAudioSetup), AudioChannelName.SFX_QUIET);

        common.audioManager.getAudio(AudioID.RACE_FINISH)!.setDefaultVolume(1);
    }

    private _setupTagAudios() {
        if (currentGameConfig.mode === GameMode.Tag) {
            const chaserReadyAudioSetup = new AudioSetup(this.chaserReadyPath);
            common.audioManager.addSourceAudioToChannel(AudioID.CHASER_READY, new AudioPlayer(chaserReadyAudioSetup), AudioChannelName.SFX);

            const evaderReadyAudioSetup = new AudioSetup(this.buttonHoverPath);
            common.audioManager.addSourceAudioToChannel(AudioID.EVADER_READY, new AudioPlayer(evaderReadyAudioSetup), AudioChannelName.SFX);
        }
    }
}
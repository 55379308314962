// @ts-ignore - The build system supports XML importing, but typescript doesn't
import xmlContent from "../xml/kiosk-upper.xml";

import { ObservableTransformer, Variable, Widget, XMLUIParserContext } from "lazy-widgets";
import { WLVirtualKeyboardRoot } from "lazy-widgets-wle";
import common from "../../../common.js";
import { currentGameConfig } from "../../../data/game-configuration.js";
import { currentPlayerData } from "../../../data/player-data.js";
import { BaseFitnessResortUIComponent } from "../../lazy-widgets/components/base-fitness-resort-ui-component.js";
import { BackPane } from "../../lazy-widgets/widgets/back-pane.js";
import { Book } from "../../lazy-widgets/widgets/book.js";
import { CurrencyRow } from "../widgets/currency-row.js";
import { KioskBackground } from "../widgets/kiosk-background.js";
import { Leaderboard, type LeaderboardEntry } from "../widgets/leaderboard.js";

const DUMMY_ARRAY: LeaderboardEntry[] = [];

export const enum UpperUIMode {
    Leaderboard,
    CustomisationPreview,
}

export class KioskUpperUIComponent extends BaseFitnessResortUIComponent {
    static override TypeName = "kiosk-upper-ui";

    private playerName!: Variable<string>;
    private playerDataListener!: () => void;
    private leaderboardData = new Variable<Array<LeaderboardEntry>>([]);
    private lastTrackChecked!: Variable<number>;
    private modeBook!: Book;
    private fitPoints = new Variable<number>(0);
    private fitabux = new Variable<number>(0);
    private heyVRCoins = new Variable<number>(0);

    override init() {
        super.init();

        this.playerName = new Variable<string>(currentPlayerData.name!);
        this.playerDataListener = () => {
            this.playerName!.value = currentPlayerData.name!;
        };

        common.kioskUpperUI = this;
    }

    override createXMLParser() {
        const parser = super.createXMLParser();
        parser.autoRegisterFactory(Leaderboard);
        parser.autoRegisterFactory(Book);
        parser.autoRegisterFactory(KioskBackground);
        parser.autoRegisterFactory(BackPane);
        parser.autoRegisterFactory(CurrencyRow);

        return parser;
    }

    override getXMLParserConfig() {
        const TABLE_WIDTH = 560;
        const RANK_WIDTH = 100;
        const TIME_WIDTH = 100;
        const NAME_WIDTH = TABLE_WIDTH - RANK_WIDTH - TIME_WIDTH;
        this.lastTrackChecked = new Variable(currentGameConfig.track);

        return {
            ...super.getXMLParserConfig(),
            variables: {
                tableHeaderConstraints: [TABLE_WIDTH, TABLE_WIDTH, 0, Infinity],
                rankHeaderEntryConstraints: [RANK_WIDTH, RANK_WIDTH, 0, Infinity],
                nameHeaderEntryConstraints: [NAME_WIDTH, NAME_WIDTH, 0, Infinity],
                timeHeaderEntryConstraints: [TIME_WIDTH, TIME_WIDTH, 0, Infinity],
                welcome: new ObservableTransformer([this.playerName], () => `Welcome, ${this.playerName.value}`),
                fitPoints: this.fitPoints,
                fitabux: this.fitabux,
                heyvrCoins: this.heyVRCoins,
                leaderboardWidth: TABLE_WIDTH,
                leaderboardRankWidth: RANK_WIDTH,
                leaderboardTimeWidth: TIME_WIDTH,
                leaderboardRowHeight: 18,
                headerText: new ObservableTransformer([this.lastTrackChecked], () => {
                    return `${currentGameConfig.locationConfig.name}, ${currentGameConfig.trackConfig.name}`;
                }),
            },
        };
    }

    protected override onUITreePicked(uiTree: Widget, context: XMLUIParserContext): void {
        super.onUITreePicked(uiTree, context);

        const leaderboard = context.idMap.get("leaderboard") as Leaderboard;
        this.leaderboardData.watch(() => {
            leaderboard.replaceLeaderboardData(this.leaderboardData.value);
        }, true);

        this.modeBook = context.idMap.get("mode-book") as Book;
    }

    replaceLeaderboardData(leaderboardDataArray: Array<LeaderboardEntry>) {
        // HACK change to dummy before changing to actual data so that the
        //      change is detected. ideally we would use a Variable class that
        //      can detect changes in an array
        this.leaderboardData.value = DUMMY_ARRAY;
        this.leaderboardData.value = leaderboardDataArray;
    }

    setHeyVRCoins(amount: number) {
        this.heyVRCoins.value = amount;
    }

    setLastRaceTime(finishTime: number, bestLapTime: number) {
        // TODO
        console.debug("!!!!!!", finishTime, bestLapTime);
    }

    changeMode(mode: UpperUIMode) {
        this.modeBook.changePage(mode);
    }

    protected override getXMLContent(): string {
        return xmlContent;
    }

    protected override beforeWidgetUpdate(root: WLVirtualKeyboardRoot, dt: number): boolean | void {
        this.lastTrackChecked.value = currentGameConfig.track;
        super.beforeWidgetUpdate(root, dt);
    }

    override update(dt: number): void {
        this.fitPoints.value = currentPlayerData.totalFitPoints;
        // TODO fitabux/heyvr coins providers
        super.update(dt);
    }

    override onActivate(): void {
        super.onActivate();
        currentPlayerData.listen(this.playerDataListener);
    }

    override onDeactivate(): void {
        currentPlayerData.unlisten(this.playerDataListener);
        super.onDeactivate();
    }
}

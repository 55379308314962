import { Component, Property } from "@wonderlandengine/api";
import { AnalyticsUtils, Globals } from "wle-pp";
import common from "../../../common.js";
import { currentGameConfig } from "../../../data/game-configuration.js";

// TODO ~~nuke this to smithereens~~ actually, don't!:
// - move this out of the kiosk folder
// - make this not have any relationship with kiosk
// - make this component listenable (add addEventListener/removeEventListener methods)
// - make kiosk depend on this component, instead of this component depend on kiosk; use aforementioned event listeners

export class LeaderboardComponent extends Component {
    static TypeName = "leaderboard-component";
    static Properties = {
        saveKey: Property.string("Game"),
        amountScoresDisplayed: Property.int(10),
    };

    start() {
        common.leaderboard = this;
        this.saveData = { scores: [] };
        this.externalData = { scores: [] };

        this.clearOnNextAddEntryFlag = false;

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const room = urlParams.get("room");
        const clearAll = urlParams.get("clearall");
        const clearPlayer = urlParams.get("clearplayer");

        common.kioskUpperUI.setLastRaceTime(null, null);

        if (clearAll) {
            this.clearAll();
        } else if (clearPlayer) {
            this.clearPlayerScore(clearPlayer);
        }

        if (!(room || common.hoverboardNetworking.room)) {
            this.loadScores();
            this.getLeaderboard();
        }

        common.MAIN_CHANNEL.on("room-init-start", this.getLeaderboard.bind(this));
        common.MAIN_CHANNEL.on("room-leave", this.getLeaderboard.bind(this));

        for (let i = 0; i < 20; i++) { this.addExternalData({ name: `player ${i}`, finishTime: 1000 + i * 10 }); } this.displayLeaderboard();
    }

    setHidden(b) {
        this.title.active = !b;
        this.firstColumn.active = !b;
        this.secondColumn.active = !b;
        this.thirdColumn.active = !b;
    }

    loadScores() {
        let saveString = localStorage.getItem(this.saveKey);
        if (saveString) this.saveData = JSON.parse(saveString);
    }

    clearAll() {
        this.saveData = { scores: [] };
        localStorage.setItem(this.saveKey, JSON.stringify(this.saveData));
    }

    clearPlayerScore(name) {
        for (let i = 0; i < this.saveData.scores.length; i++) {
            const currentEntry = this.saveData.scores[i];
            if (currentEntry.displayName === name) this.saveData.scores.splice(i, 1);
        }
        localStorage.setItem(this.saveKey, JSON.stringify(this.saveData));
    }

    clearDisplay() {
        this.displayLeaderboard([]);
    }

    submitToHeyVR(finishTime, bestLapTime = -1) {
        let isNPCRace = common.menu.currentNPCs.length > 0;
        window.heyVR?.leaderboard
            .postScore(currentGameConfig.map, Math.floor(((bestLapTime > -1) ? bestLapTime : finishTime) * 1000))
            .then((e) => {
                AnalyticsUtils.sendEvent("learderboard_score_submit");

                console.debug("Score posted!", e);

                if (!common.hoverboardNetworking.room && !isNPCRace) {
                    this.getLeaderboard(); // only update the leaderboard if not online, online we want a room local leaderboard
                }
            })
            .catch((err) => {
                console.error(err);
            });

        common.kioskUpperUI.setLastRaceTime(finishTime, bestLapTime);
    }

    getLeaderboard() {
        window.heyVR?.leaderboard.get(currentGameConfig.map, this.amountScoresDisplayed).then((leaderboard) => {
            this.clearExternalData();

            let lastValidLeaderboardEntry = null;
            for (const entry of leaderboard) {
                if (entry == null && lastValidLeaderboardEntry != null && Globals.isDebugEnabled()) {
                    this.addExternalDataHeyVR({
                        rank: lastValidLeaderboardEntry.rank,
                        name: lastValidLeaderboardEntry.user,
                        finishTime: lastValidLeaderboardEntry.score / 1000,
                    });
                } else if (entry != null) {
                    this.addExternalDataHeyVR({
                        rank: entry.rank,
                        name: entry.user,
                        finishTime: entry.score / 1000,
                    });

                    lastValidLeaderboardEntry = entry;
                }
            }

            this.displayLeaderboard();
        });
    }

    displayLeaderboard() {
        common.kioskUpperUI?.replaceLeaderboardData(this.externalData.scores);
    }

    clearExternalData() {
        this.clearOnNextAddEntryFlag = false;

        this.externalData.scores = [];
        this.displayLeaderboard();
    }

    addExternalData(player) {
        if (this.clearOnNextAddEntryFlag) {
            common.leaderboard.clearExternalData();
        }

        this.externalData.scores.push({
            rank: this.externalData.scores.length + 1,
            displayName: player.name,
            score: player.finishTime,
        });

        this.externalData.scores.sort((first, second) => first.score - second.score);

        for (let i = 0; i < this.externalData.scores.length; i++) {
            this.externalData.scores[i].rank = i + 1;
        }
    }

    addExternalDataHeyVR(player) {
        if (this.clearOnNextAddEntryFlag) {
            common.leaderboard.clearExternalData();
        }

        this.externalData.scores.push({
            rank: player.rank,
            displayName: player.name,
            score: player.finishTime,
        });
    }

    clearOnNextAddEntry() {
        this.clearOnNextAddEntryFlag = true;
    }
}

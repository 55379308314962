import { Object3D, TextComponent } from "@wonderlandengine/api";
import { formatSecondsToTime } from "src/hoverfit/utils/string-utils.js";
import { ObjectUtils, Vector3, vec3_create } from "wle-pp";
import { NPCDifficulty } from "../../hoverboard/components/npc-controller-component.js";
import { getRacePlacementSuffix, getRacePlacementSuffixTextOffset } from "../track-utils.js";

export class RaceResultBoardData {

    placement: number = 0;

    lapsCompleted: number = 0;
    totalLapsAmount: number = 0;
    bestLap: number = 0;
    bestLapSeconds: number = 0;

    npcDifficulty = NPCDifficulty.Easy;

    totalSeconds: number = 0;

    fitPoints: number = 0;

    maxSpeed: number = 0;
    maxAirSeconds: number = 0;
    squatsAmount: number = 0;
    jumpsAmount: number = 0;
}

export class RaceResultsBoard {

    private _raceResultsBoardObject: Object3D;

    private _placementText: TextComponent;
    private _placementSuffixObject: Object3D;
    private _placementSuffixText: TextComponent;
    private _placementSuffixObjectBaseLocalPosition: Vector3;

    private _lapsCompletedText: TextComponent;
    private _bestLapLabelText: TextComponent;
    private _bestLapText: TextComponent;

    private _npcDifficultyText: TextComponent;

    private _totalTimeText: TextComponent;

    private _fitPointsText: TextComponent;

    private _maxAirTimeParent: Object3D;
    private _maxAirTimeText: TextComponent;

    private _maxSpeedText: TextComponent;

    private _squatsAmountText: TextComponent;

    private _jumpsAmountParent: Object3D;
    private _jumpsAmountText: TextComponent;

    constructor(raceResultsBoardObject: Object3D) {
        this._raceResultsBoardObject = raceResultsBoardObject;

        this._raceResultsBoardObject.pp_resetPositionLocal();

        this._placementText = ObjectUtils.getObjectByName(this._raceResultsBoardObject, "Placement Text")!.getComponent(TextComponent)!;
        this._placementSuffixObject = ObjectUtils.getObjectByName(this._raceResultsBoardObject, "Placement Suffix Text")!;
        this._placementSuffixText = this._placementSuffixObject.getComponent(TextComponent)!;
        this._placementSuffixObjectBaseLocalPosition = this._placementSuffixObject.getPositionLocal(vec3_create());

        this._lapsCompletedText = ObjectUtils.getObjectByName(this._raceResultsBoardObject, "Laps Completed Text")!.getComponent(TextComponent)!;
        this._bestLapLabelText = ObjectUtils.getObjectByName(this._raceResultsBoardObject, "Fastest Lap Label")!.getComponent(TextComponent)!;
        this._bestLapText = ObjectUtils.getObjectByName(this._raceResultsBoardObject, "Fastest Lap Text")!.getComponent(TextComponent)!;

        this._npcDifficultyText = ObjectUtils.getObjectByName(this._raceResultsBoardObject, "NPC Difficulty Text")!.getComponent(TextComponent)!;

        this._totalTimeText = ObjectUtils.getObjectByName(this._raceResultsBoardObject, "Total Time Text")!.getComponent(TextComponent)!;

        this._fitPointsText = ObjectUtils.getObjectByName(this._raceResultsBoardObject, "Fit Points Text")!.getComponent(TextComponent)!;

        this._maxSpeedText = ObjectUtils.getObjectByName(this._raceResultsBoardObject, "Max Speed Text")!.getComponent(TextComponent)!;

        this._maxAirTimeParent = ObjectUtils.getObjectByName(this._raceResultsBoardObject, "Max Air Time")!;
        this._maxAirTimeText = ObjectUtils.getObjectByName(this._raceResultsBoardObject, "Max Air Time Text")!.getComponent(TextComponent)!;

        this._squatsAmountText = ObjectUtils.getObjectByName(this._raceResultsBoardObject, "Squats Amount Text")!.getComponent(TextComponent)!;

        this._jumpsAmountParent = ObjectUtils.getObjectByName(this._raceResultsBoardObject, "Jumps Amount")!;
        this._jumpsAmountText = ObjectUtils.getObjectByName(this._raceResultsBoardObject, "Jumps Amount Text")!.getComponent(TextComponent)!;

        this.setVisible(false);
    }

    setVisible(visible: boolean): void {
        ObjectUtils.setActive(this._raceResultsBoardObject, visible);

        ObjectUtils.setActive(this._maxAirTimeParent, false);
        ObjectUtils.setActive(this._jumpsAmountParent, false);
    }

    updateBoardData(boardData: RaceResultBoardData): void {
        this._placementText.text = boardData.placement;
        this._placementSuffixText.text = getRacePlacementSuffix(boardData.placement);
        this._placementSuffixObjectBaseLocalPosition[0] = getRacePlacementSuffixTextOffset(boardData.placement);
        this._placementSuffixObject.setPositionLocal(this._placementSuffixObjectBaseLocalPosition);

        this._lapsCompletedText.text = boardData.lapsCompleted + " / " + boardData.totalLapsAmount;
        this._bestLapLabelText.text = "Fasted Lap - #" + boardData.bestLap;
        this._bestLapText.text = formatSecondsToTime(boardData.bestLapSeconds);

        this._npcDifficultyText.text = boardData.npcDifficulty;

        this._totalTimeText.text = formatSecondsToTime(boardData.totalSeconds);

        this._fitPointsText.text = Math.floor(boardData.fitPoints);

        this._maxSpeedText.text = boardData.maxSpeed + " mph";
        this._maxAirTimeText.text = boardData.maxAirSeconds + " sec";
        this._squatsAmountText.text = boardData.squatsAmount;
        this._jumpsAmountText.text = boardData.jumpsAmount;
    }
}
import { Alignment, ArtificialConstraint, Column, FlexAlignment, Label, LiveLabel, ObservableTransformer, Row, TextAlignMode, filterIDFromProperties, type Variable, type WidgetProperties } from "lazy-widgets";
import { ArrowDecoratedButton } from "./arrow-decorated-button.js";
import { ArrowDirection } from "./arrow-icon.js";
import { DecoratedButtonLikePane } from "./decorated-button-like-pane.js";

export interface StepperInputProperties extends WidgetProperties {
    labels?: Array<string>;
    valueWidth?: number;
    valueHeight?: number;
}

export class StepperInput extends Column {
    static override autoXML = {
        name: "stepper-input",
        inputConfig: [
            {
                mode: "text",
                name: "title"
            },
            {
                mode: "value",
                // FIXME i would use a variable validator, but it doesn't work
                //       for variable-like classes, like our GameModeVariable
                //       class
                // validator: "variable",
                name: "variable"
            },
            {
                mode: "value",
                validator: "number",
                name: "min-value"
            },
            {
                mode: "value",
                validator: "number",
                name: "max-value"
            },
        ],
    };

    private decButton: ArrowDecoratedButton;
    private incButton: ArrowDecoratedButton;
    private pane: DecoratedButtonLikePane;
    private valueLabel: LiveLabel;
    private titleLabel: Label;
    clickable = true;

    constructor(title: string, readonly variable: Variable<number>, readonly minValue: number, readonly maxValue: number, properties?: StepperInputProperties) {
        const labels = properties?.labels;
        const valueWidth = properties?.valueWidth ?? 40;
        const valueHeight = properties?.valueHeight ?? 24;
        const filteredProps = filterIDFromProperties(properties);

        const decButton = new ArrowDecoratedButton(
            ArrowDirection.Left,
            {
                iconSize: 10,
                ...filteredProps,
            },
        ).on("click", () => {
            if (variable.value > minValue) variable.value--;
        });

        const incButton = new ArrowDecoratedButton(
            ArrowDirection.Right,
            {
                iconSize: 10,
                ...filteredProps,
            },
        ).on("click", () => {
            if (variable.value < maxValue) variable.value++;
        });

        const valueLabel = new LiveLabel(
            new ObservableTransformer([variable], () => {
                if (labels) {
                    return labels[variable.value];
                } else {
                    return `${variable.value}`.padStart(Math.ceil(Math.log10(maxValue)), "0");
                }
            }),
            {
                bodyTextAlign: TextAlignMode.Center,
                ...filteredProps,
            },
        );

        const titleLabel = new Label(
            title,
            {
                bodyTextAlign: TextAlignMode.Center,
                ...filteredProps,
            },
        );

        const pane = new DecoratedButtonLikePane(valueLabel);

        super(
            [
                titleLabel,
                new Row(
                    [
                        new Column(
                            [decButton],
                            {
                                multiContainerAlignment: {
                                    main: FlexAlignment.Center,
                                    cross: Alignment.Center,
                                },
                                ...filteredProps,
                            },
                        ),
                        new ArtificialConstraint(
                            pane,
                            [valueWidth, valueWidth, valueHeight, valueHeight],
                        ),
                        new Column(
                            [incButton],
                            {
                                multiContainerAlignment: {
                                    main: FlexAlignment.Center,
                                    cross: Alignment.Center,
                                },
                                ...filteredProps,
                            },
                        ),
                    ],
                    {
                        multiContainerAlignment: {
                            main: FlexAlignment.Center,
                            cross: Alignment.Center,
                        },
                        ...filteredProps,
                    },
                ),
            ],
            properties,
        );

        this.decButton = decButton;
        this.incButton = incButton;
        this.pane = pane;
        this.valueLabel = valueLabel;
        this.titleLabel = titleLabel;
    }

    override preLayoutUpdate(): void {
        const curVal = this.variable.value;
        this.decButton.clickable = curVal !== this.minValue && this.clickable;
        this.incButton.clickable = curVal !== this.maxValue && this.clickable;
        this.pane.looksClickable = this.clickable;
        this.valueLabel.bodyTextFill = this.clickable ? "black" : "rgb(128,128,128)";
        this.titleLabel.bodyTextFill = this.clickable ? "white" : "rgb(128,128,128)";
        super.preLayoutUpdate();
    }
}
{
    "canyon": {
        "name": "Thunder Canyon",
        "defaultMode": "race",
        "modes": {
            "race": {
                "tracks": [
                    {
                        "name": "Track 1",
                        "map": "canyon-open",
                        "mapTrackIndex": 1,
                        "maxPlayers": 6,
                        "difficulties": 5
                    },
                    {
                        "name": "Track 2",
                        "map": "canyon-open",
                        "mapTrackIndex": 2,
                        "maxPlayers": 6,
                        "difficulties": 5
                    },
                    {
                        "name": "Track 3",
                        "map": "canyon-open",
                        "mapTrackIndex": 3,
                        "maxPlayers": 6,
                        "difficulties": 5
                    }
                ]
            },
            "tag": {
                "tracks": [
                    {
                        "name": "Open track",
                        "map": "canyon-open",
                        "mapTrackIndex": 0,
                        "maxPlayers": 6,
                        "difficulties": 0
                    }
                ]
            },
            "roam": {
                "tracks": [
                    {
                        "name": "Open track",
                        "map": "canyon-open",
                        "mapTrackIndex": 0,
                        "maxPlayers": null,
                        "difficulties": 0
                    }
                ]
            }
        }
    },
    "snow": {
        "name": "Polar World",
        "defaultMode": "tag",
        "modes": {
            "race": {
                "defaultTrack": 1,
                "tracks": [
                    {
                        "name": "Track 1",
                        "map": "snow-race",
                        "mapTrackIndex": 1,
                        "maxPlayers": 6,
                        "difficulties": 5
                    },
                    {
                        "name": "Track 2",
                        "map": "snow-race",
                        "mapTrackIndex": 2,
                        "maxPlayers": 6,
                        "difficulties": 5
                    },
                    {
                        "name": "Track 3",
                        "map": "snow-race",
                        "mapTrackIndex": 3,
                        "maxPlayers": 6,
                        "difficulties": 5
                    }
                ]
            },
            "tag": {
                "tracks": [
                    {
                        "name": "Open track",
                        "map": "snow",
                        "mapTrackIndex": 0,
                        "maxPlayers": 6,
                        "difficulties": 0
                    }
                ]
            },
            "roam": {
                "tracks": [
                    {
                        "name": "Open track",
                        "map": "snow",
                        "mapTrackIndex": 0,
                        "maxPlayers": null,
                        "difficulties": 0
                    }
                ]
            }
        }
    },
    "city": {
        "name": "Thrill City",
        "defaultMode": "race",
        "modes": {
            "race": {
                "defaultTrack": 1,
                "tracks": [
                    {
                        "name": "Track 1",
                        "map": "city",
                        "mapTrackIndex": 1,
                        "maxPlayers": 6,
                        "difficulties": 5
                    },
                    {
                        "name": "Track 2",
                        "map": "city",
                        "mapTrackIndex": 2,
                        "maxPlayers": 6,
                        "difficulties": 5
                    },
                    {
                        "name": "Track 3",
                        "map": "city",
                        "mapTrackIndex": 3,
                        "maxPlayers": 6,
                        "difficulties": 5
                    }
                ]
            },
            "tag": {
                "tracks": [
                    {
                        "name": "Open track",
                        "map": "city",
                        "mapTrackIndex": 0,
                        "maxPlayers": 6,
                        "difficulties": 0
                    }
                ]
            },
            "roam": {
                "tracks": [
                    {
                        "name": "Open track",
                        "map": "city",
                        "mapTrackIndex": 0,
                        "maxPlayers": null,
                        "difficulties": 0
                    }
                ]
            }
        }
    }
}
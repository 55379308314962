import { TrackConfigurationTemplate, type LocationConfigurationTemplate, type ModeConfigurationTemplate } from './game-configuration-template-types.js';
import { type GameMode, GameMap } from './game-configuration-types.js';

export class TrackConfiguration {
    constructor(readonly name: string, readonly map: GameMap, readonly mapTrackIndex: number, readonly maxPlayers: number | null, readonly difficulties: number) {}

    static fromObj(obj: TrackConfigurationTemplate) {
        return new TrackConfiguration(obj.name, obj.map, obj.mapTrackIndex, obj.maxPlayers, obj.difficulties);
    }
}

export type TrackList = Array<TrackConfiguration>;
export class ModeConfiguration {
    constructor(readonly tracks: TrackList, readonly defaultTrack: number) {}

    hasTrack(track: number) {
        return track >= 0 && track < this.tracks.length;
    }

    static fromObj(obj: ModeConfigurationTemplate) {
        const tracks: TrackList = [];
        for (const track of obj.tracks) {
            tracks.push(TrackConfiguration.fromObj(track));
        }

        if (tracks.length === 0) throw new Error('No tracks specified');

        return new ModeConfiguration(tracks, obj.defaultTrack ?? 0);
    }
}

export type ModeList = Map<GameMode, ModeConfiguration>;
export class LocationConfiguration {
    constructor(readonly name: string, readonly modes: ModeList, readonly defaultMode: GameMode) {}

    hasMode(mode: GameMode) {
        return this.modes.has(mode);
    }

    get defaultModeConfig() {
        return this.modes.get(this.defaultMode)!;
    }

    static fromObj(obj: LocationConfigurationTemplate) {
        const modes: ModeList = new Map();
        for (const [mode, modeObj] of Object.entries(obj.modes)) {
            modes.set(mode as GameMode, ModeConfiguration.fromObj(modeObj));
        }

        if (modes.size === 0) throw new Error('No game modes specified');

        let defaultMode = obj.defaultMode;
        if (!defaultMode) {
            if (modes.size !== 1) {
                throw new Error('Default game mode needs to be specified if location supports multiple game modes');
            }
            defaultMode = modes.keys().next().value as GameMode;
        }

        if (!modes.has(defaultMode)) {
            throw new Error(`Invalid default game mode; this location does not support the "${defaultMode}" mode`);
        }

        return new LocationConfiguration(obj.name, modes, defaultMode);
    }

    supportsGameMode(mode: GameMode) {
        return this.modes.has(mode);
    }
}

// @ts-ignore - The build system supports XML importing, but typescript doesn't
import xmlContent from "../xml/kiosk-lower.xml";

import { CatalogItem } from "@heyvr/sdk-types";
import { Emitter } from "@wonderlandengine/api";
import { GameLocation, GameMode, VALID_CONFIGURATIONS } from "hoverfit-shared-netcode";
import { ClickEvent, Label, ObservableTransformer, Row, ValidatedVariable, Variable, type LayoutConstraints } from "lazy-widgets";
import { type WLRoot, type WLVirtualKeyboardRoot } from "lazy-widgets-wle";
import { AudioID } from "src/hoverfit/audio/audio-manager/audio-id.js";
import { AnalyticsUtils, BrowserUtils } from "wle-pp";
import common from "../../../common.js";
import { HoverboardGameConfig, currentGameConfig } from "../../../data/game-configuration.js";
import { currentPlayerData } from "../../../data/player-data.js";
import { Gender } from "../../../data/values/gender.js";
import { SkinColor } from "../../../data/values/skin-color.js";
import { canJoinTrack } from "../../../game/track/track-utils.js";
import { RoomData, currentRoomData } from "../../../network/components/hoverboard-networking-component.js";
import { RoomState } from "../../../network/room-proxy.js";
import { BaseFitnessResortUIComponent } from "../../lazy-widgets/components/base-fitness-resort-ui-component.js";
import { BackPane } from "../../lazy-widgets/widgets/back-pane.js";
import { Book } from "../../lazy-widgets/widgets/book.js";
import { Carousel } from "../../lazy-widgets/widgets/carousel.js";
import { ClickyButton } from "../../lazy-widgets/widgets/clicky-button.js";
import { ClickyCheckbox } from "../../lazy-widgets/widgets/clicky-checkbox.js";
import { CustomisationButton } from "../../lazy-widgets/widgets/customisation-button.js";
import { DecoratedButton } from "../../lazy-widgets/widgets/decorated-button.js";
import { HeaderPane } from "../../lazy-widgets/widgets/header-pane.js";
import { Numpad } from "../../lazy-widgets/widgets/numpad.js";
import { OptionButton } from "../../lazy-widgets/widgets/option-button.js";
import { ShopItemButton } from "../../lazy-widgets/widgets/shop-item-button.js";
import { StepperInput } from "../../lazy-widgets/widgets/stepper-input.js";
import { TimeStepperInput } from "../../lazy-widgets/widgets/time-stepper-input.js";
import { CatalogCategories, Shop } from "../shop/shop-interface.js";
import { CustomiseGenderButton } from "../widgets/customise-gender-button.js";
import { CustomiseTabButton } from "../widgets/customise-tab-button.js";
import { ItemPageBackground } from "../widgets/item-page-background.js";
import { KioskBackground } from "../widgets/kiosk-background.js";
import { KioskShopPage } from "../widgets/kiosk-shop-page.js";
import { KioskShopPopup } from "../widgets/kiosk-shop-popup.js";
import { KioskTabButton } from "../widgets/kiosk-tab-button.js";
import { LocationButton } from "../widgets/location-button.js";
import { PlayerList } from "../widgets/player-list.js";
import { IAPContentController, KioskContent } from "./iap-content-controller.js";
import { UpperUIMode } from "./kiosk-upper-ui-component.js";

const BUTTON_HEIGHT = 20;
const BUTTON_CONSTRAINTS: LayoutConstraints = [80, 80, BUTTON_HEIGHT, BUTTON_HEIGHT];

enum MultiplayerBookSpecialPage {
    Start = RoomState.Disconnected,
    HostNumpad = 4,
    JoinNumpad = 5,
}

type MultiplayerBookPage = RoomState | MultiplayerBookSpecialPage;

function validateRoomID(str: string): [boolean, null | number] {
    if (str === "") {
        return [true, null];
    }

    const num = parseInt(str, 10);
    if (isNaN(num) || !isFinite(num) || num < 0) {
        return [false, null];
    }

    return [true, num];
}

export class KioskLowerUIComponent extends BaseFitnessResortUIComponent {
    static override TypeName = "kiosk-lower-ui";

    private previousKioskPageNumber: number = 1;
    private kioskPage!: Variable<number>;
    private customPage!: Variable<number>;
    private shopPage!: Variable<number>;
    private showItemPage!: Variable<string>;
    private gender!: Variable<Gender>;
    private skinColor!: Variable<SkinColor>;
    private hoverboard!: Variable<string>;
    private suit!: Variable<string>;
    private headwear!: Variable<string>;
    private roomIDVar!: ValidatedVariable<string, number | null>;
    private privateRoomVar!: Variable<boolean>;
    private npcsAmountInput!: StepperInput;
    private lapsAmountInput!: StepperInput;
    private tagDurationInput!: TimeStepperInput;
    private npcsDifficultyInput!: StepperInput;
    private toTrackButton!: DecoratedButton;
    private toTrackButtonMP!: DecoratedButton;
    private location = new Variable<GameLocation | null>(null);
    private mode = new Variable<GameMode | null>(null);
    private track = new Variable<number | null>(null);

    hoverboardVariantsInventoryUpdatedEmitter: Emitter<[KioskContent[]]> = new Emitter<[KioskContent[]]>();
    hoverboardVariantsCatalogUpdatedEmitter: Emitter<[CatalogItem[]]> = new Emitter<[CatalogItem[]]>();
    helmetVariantsInventoryUpdatedEmitter: Emitter<[KioskContent[]]> = new Emitter<[KioskContent[]]>();
    hairVariantsInventoryUpdatedEmitter: Emitter<[KioskContent[]]> = new Emitter<[KioskContent[]]>();
    helmetVariantsCatalogUpdatedEmitter: Emitter<[CatalogItem[]]> = new Emitter<[CatalogItem[]]>();
    hairVariantsCatalogUpdatedEmitter: Emitter<[CatalogItem[]]> = new Emitter<[CatalogItem[]]>();
    suitVariantsInventoryUpdatedEmitter: Emitter<[KioskContent[]]> = new Emitter<[KioskContent[]]>();
    suitVariantsCatalogUpdatedEmitter: Emitter<[CatalogItem[]]> = new Emitter<[CatalogItem[]]>();

    iapContentController = new IAPContentController(this);
    shop: Shop | undefined;

    override init(): void {
        super.init();

        this.kioskPage = new Variable(2);
        this.customPage = new Variable(1);
        this.shopPage = new Variable(0);
        this.showItemPage = new Variable("");
        this.gender = new Variable(0);
        this.skinColor = new Variable(0);
        this.hoverboard = new Variable("0");
        this.suit = new Variable("0");
        this.headwear = new Variable("0");
        this.roomIDVar = new ValidatedVariable<string, number | null>("", validateRoomID);
        this.privateRoomVar = new Variable(false);

        this.gender.watch(() => {
            common.avatarSelector.setAvatarType(this.gender.value, common.kioskController.configAvatarComponent, false);
        });

        this.skinColor.watch(() => {
            common.avatarSelector.setAvatarSkinColor(this.skinColor.value, common.kioskController.configAvatarComponent, false);
        });

        this.hoverboard.watch(() => {
            common.hoverboardSelector.setHoverboard(this.hoverboard.value, common.kioskController.configBoard, true, false);
            common.hoverboardSelector.setHoverboard(this.hoverboard.value, common.kioskController.configAvatarBoard, true, false);
        });

        this.suit.watch(() => {
            common.avatarSelector.setAvatarSuit(this.suit.value, common.kioskController.configAvatarComponent, false);
        });

        this.headwear.watch(() => {
            common.avatarSelector.setAvatarHeadwear(this.headwear.value, common.kioskController.configAvatarComponent, false);
        });

        common.kioskLowerUI = this;
    }

    protected override createXMLParser() {
        const parser = super.createXMLParser();
        parser.autoRegisterFactory(Book);
        parser.autoRegisterFactory(ClickyButton);
        parser.autoRegisterFactory(PlayerList);
        parser.autoRegisterFactory(Carousel);
        parser.autoRegisterFactory(KioskBackground);
        parser.autoRegisterFactory(ItemPageBackground);
        parser.autoRegisterFactory(KioskShopPopup);
        parser.autoRegisterFactory(KioskShopPage);
        parser.autoRegisterFactory(DecoratedButton);
        parser.autoRegisterFactory(OptionButton);
        parser.autoRegisterFactory(CustomisationButton);
        parser.autoRegisterFactory(ShopItemButton);
        parser.autoRegisterFactory(Numpad);
        parser.autoRegisterFactory(ClickyCheckbox);
        parser.autoRegisterFactory(StepperInput);
        parser.autoRegisterFactory(TimeStepperInput);
        parser.autoRegisterFactory(LocationButton);
        parser.autoRegisterFactory(KioskTabButton);
        parser.autoRegisterFactory(BackPane);
        parser.autoRegisterFactory(CustomiseTabButton);
        parser.autoRegisterFactory(CustomiseGenderButton);
        parser.autoRegisterFactory(HeaderPane);
        return parser;
    }

    protected override getRootProperties() {
        return {
            ...super.getRootProperties(),
            enablePasteEvents: !BrowserUtils.isMobile(), // XXX paste event handling shows virtual keyboard on mobile, so disable it for mobile
        };
    }

    protected override getXMLParserConfig() {
        const curTracks = currentGameConfig.modeConfig.tracks;
        const trackCount = curTracks.length;
        const trackLabels: string[] = [];
        for (let track = 0; track < trackCount; track++) {
            trackLabels.push(curTracks[track].name.toUpperCase());
        }

        return {
            ...super.getXMLParserConfig(),
            variables: {
                kioskPage: this.kioskPage,
                customPage: this.customPage,
                shopPage: this.shopPage,
                showItemPage: this.showItemPage,
                gender: this.gender,
                board: this.hoverboard,
                skinColor: this.skinColor,
                suit: this.suit,
                headwear: this.headwear,
                disconnect: () => common.roomProxy.disconnect(),
                quickPlay: () => common.roomProxy.quickPlay(),
                cancelConfigurationChange: () => common.roomProxy.cancelConfigurationChange(),
                quickAction: () => common.roomProxy.quickAction(),
                hostRoom: () => common.roomProxy.hostRoom(this.roomIDVar.validValue, this.privateRoomVar.value),
                joinRoom: () => common.roomProxy.joinRoom(this.roomIDVar.validValue),
                goToTrack: () => common.menu.moveToTrack(),
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                toggleTutorial: (clickEvent: ClickEvent) => common.kioskController.toggleTutorial(clickEvent.origin),
                equip: this.writeCurrentStyle.bind(this),
                currentRoomLong: new ObservableTransformer([common.roomProxy.currentRoomText], () => common.roomProxy.currentRoomText.value.toUpperCase()),
                numpadInputFilter: (inStr: string) => /^\d+$/.test(inStr),
                roomIDVar: this.roomIDVar,
                privateRoomVar: this.privateRoomVar,
                bigButtonConstraints: [160, 160, 48, 48],
                gameOptsConstraints: [150, 150, 0, Infinity],
                customiseButtonsConstraints: [130, 130, 0, Infinity],
                equipConstraints: [80, 80, 25, 25],
                numpadConstraints: [250, 250, 0, Infinity],
                numpadBottomRowButtonConstraints: [60, 60, 0, Infinity],
                npcsAmount: currentGameConfig.npcsAmount,
                lapsAmount: currentGameConfig.lapsAmount,
                tagDuration: currentGameConfig.tagDuration,
                npcsDifficulty: currentGameConfig.npcsDifficulty,
                location: this.location,
                mode: this.mode,
                track: this.track,
                buttonConstraints: BUTTON_CONSTRAINTS,
                goConstraints: [100, 100, 30, 30],
                carouselConstraints: [430, 430, 0, Infinity],
                trackLabels,
                trackMax: trackCount - 1,
            },
        };
    }

    protected override onRootReady(root: WLRoot): void {
        // HACK this cast shouldn't be necessary. there's an issue with types in
        //      lazy-widgets-wle. FIXME in lazy-widgets-wle
        super.onRootReady(root as WLVirtualKeyboardRoot);

        const onConfigChange = () => {
            const location = this.location.value!;
            const locConfig = VALID_CONFIGURATIONS.get(location)!;
            const mode = this.mode.value!;
            const modeConfig = locConfig.modes.get(mode)!;
            const track = modeConfig.defaultTrack;
            const trackConfig = modeConfig.tracks[track]!;

            AnalyticsUtils.sendEvent("change_map");
            AnalyticsUtils.sendEvent("change_map_" + trackConfig.map);
            const newGameConfig = new HoverboardGameConfig();
            newGameConfig.location = location;
            newGameConfig.mode = mode;
            newGameConfig.track = track;
            common.menu.changeGameConfig(new RoomData(currentRoomData), newGameConfig);
        };

        const modeButtons = new Map<GameMode, OptionButton>([
            [GameMode.Race, root.getWidgetByID("race-mode-button") as OptionButton],
            [GameMode.Tag, root.getWidgetByID("tag-mode-button") as OptionButton],
            [GameMode.Roam, root.getWidgetByID("roam-mode-button") as OptionButton],
        ]);

        for (const button of modeButtons.values()) {
            button.on("click", onConfigChange);
        }

        const locationRow = root.getWidgetByID("location-row") as Row;
        for (const location of Array.from(VALID_CONFIGURATIONS.keys()).sort()) {
            const locationButton = new LocationButton(location, this.location);
            locationButton.on("click", onConfigChange);

            locationRow.add(locationButton);
        }

        this.location.watch(() => {
            for (const button of modeButtons.values()) button.clickable = false;

            let hasSameModeAsCurrent = false;
            let firstClickableMode: GameMode | null = null;
            if (this.location.value !== null) {
                const locConfig = VALID_CONFIGURATIONS.get(this.location.value);
                if (locConfig) {
                    for (const mode of locConfig.modes.keys()) {
                        const button = modeButtons.get(mode)!;
                        button.clickable = true;

                        if (firstClickableMode == null) {
                            firstClickableMode = mode;
                        }

                        if (mode == this.mode.value) {
                            hasSameModeAsCurrent = true;
                        }
                    }
                }
            }

            if (!hasSameModeAsCurrent) {
                this.mode.value = firstClickableMode;
            }
        }, true);

        this.track.watch((_source, group) => {
            if (group === "net-sync") return;

            const location = currentGameConfig.location;
            const locConfig = VALID_CONFIGURATIONS.get(location)!;
            const mode = currentGameConfig.mode!;
            const modeConfig = locConfig.modes.get(mode)!;
            const track = this.track.value!;
            const trackConfig = modeConfig.tracks[track]!;

            AnalyticsUtils.sendEvent("change_map");
            AnalyticsUtils.sendEvent("change_map_" + trackConfig.map);
            const newGameConfig = new HoverboardGameConfig();
            newGameConfig.location = location;
            newGameConfig.mode = mode;
            newGameConfig.track = track;
            common.menu.changeGameConfig(new RoomData(currentRoomData), newGameConfig);
        });

        this.location.value = currentGameConfig.location;
        this.mode.value = currentGameConfig.mode;
        this.track.value = currentGameConfig.track;

        const kBook = root.getWidgetByID("kiosk-book") as Book;
        this.kioskPage.watch(() => {
            kBook.changePage(this.kioskPage.value);

            if (this.kioskPage.value > 2) {
                this.readCurrentStyle();
            }
            // Close tutorial if open
            if (this.kioskPage.value !== 2 && common.kioskController.tutorialActive) common.kioskController.setTutorialActive(false);

            // Fade between menu and balcony music
            console.log('PK', this.previousKioskPageNumber, this.kioskPage.value);
            if (this.previousKioskPageNumber == 4) {
                const balconyMusicAudio = common.audioManager.getAudio(AudioID.BALCONY_MUSIC);
                balconyMusicAudio!.fade(0.0, balconyMusicAudio!.getDefaultVolume(), 0.8);
                const shopMusicAudio = common.audioManager.getAudio(AudioID.SHOP_MUSIC);
                shopMusicAudio!.fade(shopMusicAudio!.getVolume(), 0.0, 0.8);
            } else if (this.kioskPage.value == 4) {
                const balconyMusicAudio = common.audioManager.getAudio(AudioID.BALCONY_MUSIC);
                balconyMusicAudio!.fade(balconyMusicAudio!.getVolume(), 0.0, 0.8);
                const shopMusicAudio = common.audioManager.getAudio(AudioID.SHOP_MUSIC);
                shopMusicAudio!.fade(0.0, shopMusicAudio!.getDefaultVolume(), 0.8);
            }

            // Check if top page should change
            if ((this.previousKioskPageNumber > 2 && this.kioskPage.value > 2) || (this.previousKioskPageNumber < 3 && this.kioskPage.value < 3)) return this.previousKioskPageNumber = this.kioskPage.value;

            if (this.kioskPage.value > 2) {
                this.readCurrentStyle();
                common.kioskController.setHoloDisplayExternalReactivation(() => {
                    common.kioskUpperUI.changeMode(UpperUIMode.CustomisationPreview);
                    common.kioskController.setConfigAvatarActive(true);
                    common.kioskController.setConfigBoardActive(true);
                });
            } else {
                if (this.previousKioskPageNumber > 2) {
                    common.kioskController.setHoloDisplayExternalReactivation(() => {
                        this.readCurrentStyle();
                        common.kioskUpperUI.changeMode(UpperUIMode.Leaderboard);
                        common.kioskController.setConfigAvatarActive(false);
                        common.kioskController.setConfigBoardActive(false);
                    });
                }
            }

            this.previousKioskPageNumber = this.kioskPage.value;
        }, true);

        const cBook = root.getWidgetByID("custom-book") as Book;
        this.customPage.watch(() => cBook.changePage(this.customPage.value), true);
        const sBook = root.getWidgetByID("shop-book") as Book;
        this.shopPage.watch(() => sBook.changePage(this.shopPage.value), true);
        const siBook = root.getWidgetByID("shop-or-item-book") as Book;
        this.showItemPage.watch(() => siBook.changePage(this.showItemPage.value !== "" ? 1 : 0));

        const mpBook = root.getWidgetByID("multiplayer-book") as Book;
        common.roomProxy.watch((rp) => {
            const state = rp.value;
            mpBook.changePage(state);
        }, true);

        root.getWidgetByID("hostRoomButton").on("click", () => {
            mpBook.changePage(MultiplayerBookSpecialPage.HostNumpad);
        });

        root.getWidgetByID("joinRoomButton").on("click", () => {
            mpBook.changePage(MultiplayerBookSpecialPage.JoinNumpad);
        });

        root.getWidgetByID("cancelHostButton").on("click", () => {
            mpBook.changePage(MultiplayerBookSpecialPage.Start);
        });

        root.getWidgetByID("cancelJoinButton").on("click", () => {
            mpBook.changePage(MultiplayerBookSpecialPage.Start);
        });

        root.getWidgetByID("itemBackButton").on("click", () => {
            this.readCurrentStyle();
            siBook.changePage(0);
        });

        root.getWidgetByID("purchaseItemButton").on("click", () => {
            // TODO: Open confirmation page and move function call there
            siBook.changePage(2);
        });

        root.getWidgetByID("itemPurchaseCancelButton").on("click", () => {
            this.readCurrentStyle();
            siBook.changePage(0);
        });

        root.getWidgetByID("itemPostPurchaseCancelButton").on("click", () => {
            this.readCurrentStyle();
            siBook.changePage(0);
        });

        root.getWidgetByID("itemPostPurchaseEquipButton").on("click", () => {
            siBook.changePage(0);
            const item = this.shop!.getItemFromSlug(this.showItemPage.value);
            switch (item!.item_class) {
                case CatalogCategories.Hoverboard:
                    this.hoverboard.value = item!.slug;
                    break;
                case CatalogCategories.Suit:
                    this.suit.value = item!.slug;
                    break;
                case CatalogCategories.Hair:
                    this.headwear.value = item!.slug;
                    break;
                case CatalogCategories.Helmet:
                    this.headwear.value = item!.slug;
                    break;
                default:
                    break;
            }
            this.writeCurrentStyle();
        });

        const doRoomJoinButton = root.getWidgetByID("doRoomJoinButton") as DecoratedButton;
        const updateClickable = () => {
            doRoomJoinButton.clickable = this.roomIDVar.valid && this.roomIDVar.validValue !== null;
        };
        updateClickable();
        this.roomIDVar.watch(updateClickable);

        this.toTrackButton = root.getWidgetByID("toTrackButton") as DecoratedButton;
        this.toTrackButton.child.text = `Start ${currentGameConfig.fancyMode}`.toUpperCase();
        this.toTrackButtonMP = root.getWidgetByID("toTrackButtonMP") as DecoratedButton;
        this.toTrackButtonMP.child.text = `Start ${currentGameConfig.fancyMode}`.toUpperCase();

        this.npcsAmountInput = root.getWidgetByID("npcsAmountInput") as StepperInput;
        this.lapsAmountInput = root.getWidgetByID("lapsAmountInput") as StepperInput;
        this.tagDurationInput = root.getWidgetByID("tagDurationInput") as TimeStepperInput;
        this.npcsDifficultyInput = root.getWidgetByID("npcsDifficultyInput") as StepperInput;

        const hoverboardRow = root.getWidgetByID('hoverboard-row') as Row;
        const suitsRow = root.getWidgetByID('suits-row') as Row;
        const hairsRow = root.getWidgetByID('hairs-row') as Row;
        const helmetsRow = root.getWidgetByID('helmets-row') as Row;

        this.hoverboardVariantsInventoryUpdatedEmitter.add((hoverboards: ReadonlyArray<KioskContent>) => {
            for (const hoverboard of hoverboards) {
                hoverboardRow.add(new CustomisationButton(hoverboard.name, hoverboard.value, this.hoverboard, hoverboard.imageUri));
            }
        });

        this.suitVariantsInventoryUpdatedEmitter.add((suits: ReadonlyArray<KioskContent>) => {
            for (const suit of suits) {
                suitsRow.add(new CustomisationButton(suit.name, suit.value, this.suit, suit.imageUri));
            }
        });

        this.hairVariantsInventoryUpdatedEmitter.add((hairs: ReadonlyArray<KioskContent>) => {
            for (const hair of hairs) {
                hairsRow.add(new CustomisationButton(hair.name, hair.value, this.headwear, hair.imageUri));
            }
        });

        this.helmetVariantsInventoryUpdatedEmitter.add((helmets: ReadonlyArray<KioskContent>) => {
            for (const helmet of helmets) {
                helmetsRow.add(new CustomisationButton(helmet.name, helmet.value, this.headwear, helmet.imageUri));
            }
        });

        const shopPageHoverboard = root.getWidgetByID("shop-page-hoverboard") as KioskShopPage;

        this.hoverboardVariantsCatalogUpdatedEmitter.add((hoverboards: Array<CatalogItem>) => {
            shopPageHoverboard.replaceShopData(hoverboards);
        });

        const shopPageSuits = root.getWidgetByID("shop-page-suits") as KioskShopPage;
        this.suitVariantsCatalogUpdatedEmitter.add((suits: Array<CatalogItem>) => {
            shopPageSuits.replaceShopData(suits);
        });

        const shopPageHairs = root.getWidgetByID("shop-page-hairs") as KioskShopPage;
        this.hairVariantsCatalogUpdatedEmitter.add((hairs: Array<CatalogItem>) => {
            shopPageHairs.replaceShopData(hairs);
        });

        const shopPageHelmets = root.getWidgetByID("shop-page-helmets") as KioskShopPage;
        this.helmetVariantsCatalogUpdatedEmitter.add((helmet: Array<CatalogItem>) => {
            shopPageHelmets.replaceShopData(helmet);
        });

        this.shop = new Shop();
        this.iapContentController.initialize();

        const postPurchasePageLabel = root.getWidgetByID("itemPostPurchaseLabel") as Label;
        root.getWidgetByID("purchaseItemConfirmButton").on("click", () => {
            // TODO: Change label to "Purchasing...", disable buttons
            this.shop?.purchaseItem(this.showItemPage.value).then(() => {
                const item = this.shop?.getItemFromSlug(this.showItemPage.value);
                const itemName = item!.name;
                postPurchasePageLabel.text = `You have purchased ${itemName}!\nDo you want to equip it?`;
                siBook.changePage(3);
            }).catch(() => {
                console.log('Failed');
            });
        });

        // Set item page labels
        const itemPageLabel = root.getWidgetByID("itemPageLabel") as Label;
        const itemBuyConfirmLabel = root.getWidgetByID("itemBuyConfirmLabel") as Label;

        this.showItemPage.watch(() => {
            if (!this.showItemPage.value) return;

            const item = this.shop?.getItemFromSlug(this.showItemPage.value);
            const itemName = item!.name;
            const itemPrice = item!.price;

            switch (item!.item_class) {
                case CatalogCategories.Hoverboard:
                    this.hoverboard.value = item!.slug;
                    break;
                case CatalogCategories.Suit:
                    this.suit.value = item!.slug;
                    break;
                case CatalogCategories.Hair:
                    this.headwear.value = item!.slug;
                    break;
                case CatalogCategories.Helmet:
                    this.headwear.value = item!.slug;
                    break;
                default:
                    break;
            }

            itemPageLabel.text = `You are about to buy ${itemName}.`;
            itemBuyConfirmLabel.text = `Are you sure you want to purchase ${itemName} for ${itemPrice}?`;
        });
    }

    protected override beforeWidgetUpdate(_root: WLVirtualKeyboardRoot, _dt: number): boolean | void {
        this.toTrackButton.clickable = canJoinTrack();
        this.toTrackButtonMP.clickable = canJoinTrack();
        const allowChange = common.menu.getPlayersOnTrack(true) === 0;
        const allowChangeNPCs = allowChange && currentGameConfig.canHaveNPCs;
        this.npcsAmountInput.clickable = allowChangeNPCs;
        this.lapsAmountInput.enabled = currentGameConfig.mode != GameMode.Tag;
        this.lapsAmountInput.clickable = allowChange && currentGameConfig.mode == GameMode.Race;
        this.tagDurationInput.enabled = currentGameConfig.mode == GameMode.Tag;
        this.tagDurationInput.clickable = allowChange;
        this.npcsDifficultyInput.clickable = allowChangeNPCs;
        this.track.setValue(currentGameConfig.track, "net-sync");
    }

    protected override getXMLContent(): string {
        return xmlContent;
    }

    override onActivate(): void {
        super.onActivate();
    }

    override onDeactivate(): void {
        super.onDeactivate();
    }

    private readCurrentStyle() {
        this.gender.value = currentPlayerData.avatarType;
        this.hoverboard.value = currentPlayerData.hoverboardVariant;
        this.skinColor.value = currentPlayerData.skinColor;
        this.suit.value = currentPlayerData.suitVariant;
        this.headwear.value = currentPlayerData.headwearVariant;
    }

    private writeCurrentStyle() {
        if (currentPlayerData.avatarType !== this.gender.value) {
            currentPlayerData.avatarType = this.gender.value;
            common.avatarSelector.setAvatarType(currentPlayerData.avatarType, currentPlayerData.avatar, true);
            AnalyticsUtils.sendEventOnce("change_avatar_gender");
            AnalyticsUtils.sendEventOnce("change_avatar_gender_" + currentPlayerData.avatarType);
        }

        if (currentPlayerData.skinColor !== this.skinColor.value) {
            currentPlayerData.skinColor = this.skinColor.value;
            common.avatarSelector.setAvatarSkinColor(currentPlayerData.skinColor, currentPlayerData.avatar, true);
            AnalyticsUtils.sendEventOnce("change_skin_color");
            AnalyticsUtils.sendEventOnce("change_skin_color_" + currentPlayerData.skinColor);
        }

        if (currentPlayerData.hoverboardVariant !== this.hoverboard.value) {
            currentPlayerData.hoverboardVariant = this.hoverboard.value;
            common.hoverboardSelector.setHoverboard(currentPlayerData.hoverboardVariant, common.hoverboard.hoverboardMeshObject, false, true);
            AnalyticsUtils.sendEventOnce("change_hoverboard_skin");
            AnalyticsUtils.sendEventOnce("change_hoverboard_skin_" + currentPlayerData.hoverboardVariant);
        }

        if (currentPlayerData.suitVariant !== this.suit.value) {
            currentPlayerData.suitVariant = this.suit.value;
            common.avatarSelector.setAvatarSuit(currentPlayerData.suitVariant, currentPlayerData.avatar, true);
            AnalyticsUtils.sendEventOnce("change_suit_color");
            AnalyticsUtils.sendEventOnce("change_suit_color_" + currentPlayerData.suitVariant);
        }

        if (currentPlayerData.headwearVariant !== this.headwear.value) {
            currentPlayerData.headwearVariant = this.headwear.value;
            common.avatarSelector.setAvatarHeadwear(currentPlayerData.headwearVariant, currentPlayerData.avatar, true);
            AnalyticsUtils.sendEventOnce("change_headwear");
            AnalyticsUtils.sendEventOnce("change_headwear_" + currentPlayerData.headwearVariant);
        }

        currentPlayerData.savePlayerData();
    }
}

import { BrowserUtils, Globals, XRUtils } from "wle-pp";
import { NPCDifficulty } from "../hoverboard/components/npc-controller-component.js";

export function canJoinTrack(): boolean {
    return !BrowserUtils.isMobile() && (Globals.isDebugEnabled() || XRUtils.isSessionActive());
}

export function getNPCDifficultyFromIndex(index: number): string {
    switch (index) {
        case 0:
            return NPCDifficulty.VeryEasy;
        case 1:
            return NPCDifficulty.Easy;
        case 2:
            return NPCDifficulty.Medium;
        case 3:
            return NPCDifficulty.Hard;
        default:
            return NPCDifficulty.VeryHard;
    }
}

export function getRacePlacementSuffix(placement: number): string {
    switch (placement) {
        case 1:
            return "st";
        case 2:
            return "nd";
        case 3:
            return "rd";
        default:
            return "th";
    }
}

export function getRacePlacementSuffixTextOffset(placement: number): number {
    switch (placement) {
        case 1:
            return -0.012;
        case 2:
            return 0.033;
        case 3:
            return 0.028;
        case 4:
            return 0.028;
        case 5:
            return 0.028;
        default:
            return 0.037;
    }
}
export const PERMISSIONS_KEY = "permissionsAccepted";
export const PERMISSIONS_DEFAULT = false;

export const USER_ACCEPTED_MICROPHONE_QUERY_KEY = "userAcceptedMicrophoneQuery";
export const USER_ACCEPTED_MICROPHONE_QUERY_DEFAULT = true;

export const LAST_SESSION_GAME_CONFIG_KEY = "lastSessionGameConfig";
export const LAST_SESSION_GAME_CONFIG_DEFAULT = null;

export const LAST_SESSION_GAME_ONLINE_CONFIG_KEY = "lastSessionGameOnlineConfig";
export const LAST_SESSION_GAME_ONLINE_CONFIG_DEFAULT = null;

export const QUIET_MODE_PREF_KEY = "quietModeEnabled";
export const QUIET_MODE_PREF_DEFAULT = false;

export const P2P_PREF_KEY = "p2pEnabled";
export const P2P_PREF_DEFAULT = true;

export const MS_PREF_KEY = "mediasoupEnabled";
export const MS_PREF_DEFAULT = true;